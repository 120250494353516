import { Component } from "react";
import { withTranslation } from "react-i18next";
import '../../style/blog.css';
import moment from "moment";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Col, Row } from "react-bootstrap";
import CardArticolo from "./cardArticolo";
import linkedin from '../../images/Linkedin.svg';
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";


class DettaglioArticolo
 extends Component{
    constructor(props){
        super(props);
        this.state={
          suggeriti:[],
        }
   }
  
  handleClick = () => {
    window.open(this.props.articolo.user.link);
  };

  componentDidMount() {
      var apiBaseUrl = Constanti.url_Backend+"getArticoliSuggeriti";
       var payload = {
        "id_articolo": this.props.articolo.id,
        "id_categoria": this.props.articolo.categoria_articolo?.id
      }
      var self = this;
      axios.post(apiBaseUrl,payload).then(function (response) {
      if (response.data.data != null){
         var location = response.data.data;
         self.setState({suggeriti : location })
      }else{
        console.log("non ci articoli");
      }
      
    })
    .catch(function (error) {
    console.log(error);
    
    });
    }
  



    render(){
      const {suggeriti} = this.state;
        const {t,i18n,articolo} = this.props;
         return (
            <>
            {console.log(articolo)}
         <section className="section wf-section">
    <div className="container-articolo">
      <div className="hero_content">
        <div className="blog-text-wrapper hero">
          <div className="content_wrapper flex justify-center">
            <div className="category-label">{i18n.language === 'it'? articolo?.categoria_articolo?.categoria : articolo?.categoria_articolo?.categoria_en}</div>
            <div className="data hero">{moment(articolo?.created_at).format('DD MMM, YYYY')}</div>
          </div>
          <h2 className="titleCat">{i18n.language === 'it'? articolo?.titolo : articolo?.titolo_en}</h2>
          <p className="p description big">{i18n.language === 'it'? articolo?.sottotitolo : articolo?.sottotitolo_en}</p>
        </div>
        <div className="blog-thumb hero" ><img src={articolo?.thumbnail} className="blog-thumb-detail" ></img></div>
      </div>
      <div className="bodywrapper">
        <div className="bodycontent">
         <ReactMarkdown>{i18n.language === 'it'? articolo?.testo : articolo?.testo_en}</ReactMarkdown>
         <div className="author-banner">
            <div className="_2-col align-center tablet-horizontal">
              <div className="author-wrapper">
                <div className="author-img big" style={{ backgroundImage: `url(${articolo.user.avatar})` }}></div>
                <div className="author bold white">{articolo.user.name}</div>
              </div>
              <a onClick={this.handleClick} className="w-inline-block"><img src={linkedin} loading="lazy" alt=""/></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="section-interessati wf-section">
    <div className="container-articolo">
      <div className="content_wrapper flex wrap">
        <h3 className="h3 nomarginbottom">{t('potInter')}</h3>
        <a href="#" className="button-secondary mobile-hidden w-button">{t('vedi.tutti')}</a>
      </div>
      <div className="content_wrapper flex align-top margintop32">
      {this.state.suggeriti?.map((option) => 
      <div class="blog-card-wrapper">
          <div class="blog-card-image" style={{ backgroundImage: `url(${option.thumbnail})` }}></div>
          <div class="blog-card-content">
            <div class="category-label">{i18n.language === 'it'? option?.categoria_articolo?.categoria : option?.categoria_articolo?.categoria_en}</div>
            <h4 class="h4">{i18n.language === 'it'? option?.titolo : option?.titolo_en}</h4>
            <p class="p description">{i18n.language === 'it'? option?.testo.substring(0,200)+'...' : option?.testo_en.substring(0,200)+'...'}</p>
            <div class="_2-col align-center tablet-horizontal">
              <div class="author-wrapper">
                <div class="author-img" style={{ backgroundImage: `url(${option.user.avatar})` }}></div>
                <div class="author bold">{option?.user.name}</div>
              </div>
              <div class="data">{moment(option?.created_at).format('DD MMM, YYYY')}</div>
            </div>
          </div>
        </div>
      )}
      </div>
      
      <a href="#" className="button-secondary mobile-visible w-button">{t('vedi.tutti')}</a>
    </div>
  </div>       
               
  </>
    )
}
}


    export default withTranslation()(DettaglioArticolo); 