import { Component } from "react";
import { withTranslation } from "react-i18next";
import '../../style/blog.css';
import NewsletterHome from "../homePage/newsletterHome";
import Footer from "../molecule/footer";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import HeaderLink from "./headerLink";
import CardArticolo from "./cardArticolo";
import moment from "moment";
import { Link } from "react-router-dom";

class Blog
 extends Component{
    constructor(props){
        super(props);
   
        this.state = {
         categorie:[],
         articoli:[],
         art:null,
        }
    }
    componentDidMount = () =>  {
        var apiBaseUrl = Constanti.url_Backend+'getAllCategorieArticoli';
        var self = this;
        axios.get(apiBaseUrl).then(function (response) {
       
        if (response.data.data != null){
           var categorie = response.data.data;
           self.setState({ categorie : categorie })
        }else{
          console.log("non ci sono location");
        }
        self.setState({load:false});
      })
      .catch(function (error) {
      console.log(error);
      self.setState({load:false});
      });
      var apiBaseUrl2 = Constanti.url_Backend+'getAllArticoli';
        axios.get(apiBaseUrl2).then(function (response) {
       
        if (response.data.data != null){
           var articoli = response.data.data;
           var art = articoli[0];
           self.setState({ articoli : articoli,art : art })
        }else{
          console.log("non ci sono location");
        }
        self.setState({load:false});
      })
      .catch(function (error) {
      console.log(error);
      self.setState({load:false});
      });
    }
    


    render(){
        const {art,articoli} = this.state;
        var articolib = articoli.slice(1);
        const {i18n} = this.props;
          return (
               <>
               <div className="contenitoreBlog">
               <div className="flex-content-between">
               
                <div className="content-box-blog">
                <Link to={"/blog/articolo/"+art?.id} key={art?.id}  state={{articolo:art}} className="linkArt">
                    <div className="category-label">{i18n.language === 'it'? art?.categoria_articolo.categoria : art?.categoria_articolo.categoria_en}</div>
                     <h2 className="titoloPagina">{i18n.language === 'it'? art?.titolo : art?.titolo}</h2>
                     <p className="p description big">{i18n.language === 'it'? art?.testo.substring(0,200)+'...' : art?.testo_en.substring(0,200)+'...'}</p>
                     <div className="divider"></div>
                     <div className="d-flex justify-content-between alignCenter">
                     <div className="author-wrapper">
                        <div className="author-img big"><img src={art?.user.avatar} alt={art?.user.avatar} className="author-img"></img></div>
                        <div className="author bold">{art?.user.name}</div>
                    </div>
                    <div className="data">{moment(art?.created_at).format('DD MMM, YYYY')}</div>
                  </div>
                  </Link>
                </div>
                <div className="content-box-blog">
                <div className="blog-thumb"><img src={art?.thumbnail} alt={art?.user.avatar} className="blog-thumb-detail"></img></div>
                </div>
                
               </div>
               <div className="section overflow-hidden wf-section">
               <HeaderLink categorie={this.state.categorie} selected="0"></HeaderLink>
      </div>
      <div className="content_wrapper flex align-top margintop32">
      {articolib?.map((articolo,index) => 
         
        <CardArticolo articolo={articolo} key={index}></CardArticolo>
         
      
      )}
    
       
        
        </div>
       </div>
        <NewsletterHome></NewsletterHome>
        <Footer></Footer>
        
               
  </>
    )
}
}


    export default withTranslation()(Blog); 