import moment from "moment";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import ModalAccessibilita from "./modalAccessibilita";


class TerzoStep
 extends Component{
 
    constructor(props){
    super(props);
    this.state = {
        prenotazione:null,
        tour:null,
        popup:false,
    }
   }

   closeModalChange = () => {
    this.setState({popup: false });
  }
    
    componentDidMount() {
        this.setState({prenotazione:JSON.parse(sessionStorage.getItem('prenotazione'))});  
        this.setState({tour:JSON.parse(sessionStorage.getItem('obj'))}); 
      
    }

    componentWillMount() {
    if (this.props.user != null && (this.props.user.accessibilita == null || this.props.user.accessibilita.length ==0)){ 
      
        setTimeout(() => {
            this.setState({ popup : true })
         }, 5000); 
        }
    }
    
 render(){
    const {prenotazione,tour,popup} = this.state;
    const {t,i18n} = this.props;
    return (
        <>
         <div className="wizard">
        <div className="contenuto">
         <div className="titoloBoxPrenotazioni marginBottom40">
        {t('testo.prenotazione')}
         </div>

         <span className="testoPrezzo">      {t('testo.prenotazione2')} </span> <Link to="/profilo/viaggi" className="linkViaggi">“{t('viaggi')}”</Link>.
       
         <Row className="marginTop60">
         <Col xs={4}>
           <img src={tour?.galleryImg[0].url} className="imgItinerario"></img>
         </Col>
         <Col xs={8}>
         <div className="titleItinerario" style={{marginTop:"10px !important"}}>{i18n.language === 'it' ? tour?.titolo :tour?.titolo_en}</div>
         <div className="subtitleAccessibilit">
         {prenotazione?.multy_days_trips_id == null ? (
            <Row>
         <label className="labelWizardRiepilogo">{moment(prenotazione?.startDate).format('DD MMM, YYYY')}</label>
         </Row>
         ):(
          <>
            <Row>
          <label className="labelWizardRiepilogo">{moment(prenotazione?.startDate).format('DD MMM, YYYY')} - {moment(prenotazione?.endDate).format('DD MMM, YYYY')}</label>
          </Row>
          <Row>
          <label className="labelWizardRiepilogo">{prenotazione?.n_camera>1 ? prenotazione?.n_camera+" " + t('camere') : prenotazione?.n_camera+" " + t('camera')}</label>
          </Row>
          <Row>
          <label className="labelWizardRiepilogo">{prenotazione?.n_camere_acc>1 ? prenotazione?.n_camere_acc+ " "+ t('camere.acc'): prenotazione?.n_camere_acc+ " "+ t('camera.acc')}</label>
          </Row>
          </>
         )}
         <label className="labelWizardRiepilogo">{prenotazione?.numPers} {t('viaggiatori')} ({prenotazione?.numDis} {t('viaggiatori.disabili')}) </label>
         </div>
         </Col>
         </Row>
        
        <div className="d-flex justify-content-between marginTop48">
         <span className="testoPrezzo">€{tour?.costo} x {prenotazione?.numBambini1117+prenotazione?.numBambini410+prenotazione?.numAdulti}</span>
         <span className="testoPrezzo">€ {tour !== undefined ? tour?.costo*prenotazione?.numBambini1117+prenotazione?.numBambini410+prenotazione?.numAdulti : ""}</span>
         
         </div>
         <hr className="solid"></hr>
         <div className="d-flex justify-content-between">
         <span className="totale"> {t("totale")}</span>
         <span className="totale fontWeight700">€ {tour !== undefined ? tour?.costo*prenotazione?.numBambini1117+prenotazione?.numBambini410+prenotazione?.numAdulti : ""}</span>
         
         </div>
         <div>
         <ReactMarkdown className="testoPrezzo">{t("testo.pagamento.step2")}</ReactMarkdown>
         </div>

        </div>
        </div>
        {popup &&
         <ModalAccessibilita show={true}  close={this.closeModalChange} ></ModalAccessibilita>
        }
       
</>
    )
}
}
export default  withTranslation()(TerzoStep); 
