import moment from "moment";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import CardItinerarioPrenotazione from "../molecule/card-itinerarioPrenotazione";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";


class SecondoStep
 extends Component{
 
    constructor(props){
    super(props);
    this.state = {
        prenotazione:null,
        tour:null,
        note:null
    }
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    }

    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name] : value });
     
  }

  submit (){
            var apiBaseUrl = Constanti.url_Backend+"richiestaPrenotazioneWeb";
            var payload= {
                "user_websites_id": this.props.user.id,
                "multy_days_trips_id" : this.state.prenotazione.isMultyDays? this.state.tour.id : null,
                "tours_id": this.state.prenotazione.isMultyDays? null: this.state.tour.id, 
                "start_date": this.state.prenotazione.startDate,
                "end_date" : this.state.prenotazione.endDate,
                "tipologia": this.state.prenotazione.isMultyDays? 'multi days trip':'tour',
                "n_adulti": this.state.prenotazione.numAdulti,
                "n_adulti_disabili" :this.state.prenotazione.numAdultiDis,
                "numBambini03": this.state.prenotazione.numBambini03,
                "numBambini410": this.state.prenotazione.numBambini410,
                "numBambini1117": this.state.prenotazione.numBambini1117,
                "n_bambini_disabili": this.state.prenotazione.numBambiniDis,
                "numCamere": this.state.prenotazione.numCamere,
                "numCamereAcc": this.state.prenotazione.numCamereAcc,
                "tipoHotel": this.state.prenotazione.tipoHotel,
                "prezzo_stimato":this.state.tour?.costo*(this.state.prenotazione.numBambini1117+this.state.prenotazione.numBambini410+this.state.prenotazione.numAdulti),
                "note": this.state.note
            }
            console.log(payload);
            var self = this;
            axios.post(apiBaseUrl, payload).then(function (response) {
              var userLogin= response.data.data;
              self.props.funzione();
            });
             
          }
         
       
    
    componentDidMount() {
        this.setState({prenotazione:JSON.parse(sessionStorage.getItem('prenotazione'))});  
        this.setState({tour:JSON.parse(sessionStorage.getItem('obj'))});  
      

      
    }
    
 render(){
    const {prenotazione,tour,note} = this.state;
    const {t,i18n,user} = this.props;
    return (
        <>
         <div className="wizard wizard100">
        <div className="contenuto">
         <div className="titoloBoxPrenotazioni marginBottom32">
            {t("title.wizard.riepilogo")}
         </div>
       
         {!prenotazione?.isMultyDays ? (
            <Row>
         <label className="labelWizardRiepilogo">{moment(prenotazione?.startDate).format('DD-MM-YYYY')}</label>
         </Row>
         ):(
            <Row>
          <label className="labelWizardRiepilogo">{moment(prenotazione?.startDate).format('DD-MM-YYYY')} - {moment(prenotazione?.endDate).format('DD-MM-YYYY')}</label>
          <label className="labelWizardRiepilogo">Hotel {prenotazione?.tipoHotel ==1 ? "3 " + t("stelle") : prenotazione?.tipoHotel ==2 ? "4 " + t("stelle") : "5 " +t("stelle")}</label>
          <label className="labelWizardRiepilogo">{prenotazione?.numCamere} {prenotazione?.numCamere>1 ? t('camere'): t('camera')}</label>
          <label className="labelWizardRiepilogo">{prenotazione?.numCamereAcc} {prenotazione?.numCamereAcc>1 ? t('camere.acc'): t('camera.acc')}</label>
          </Row>
         )}

         <Row>
         <Col xs={4}>
           <img src={tour?.galleryImg[0].url} className="imgItinerario"></img>
         </Col>
         <Col xs={8}>
         <div className="titleItinerario" style={{marginTop:"10px !important"}}>{i18n.language === 'it' ? tour?.titolo :tour?.titolo_en}</div>
         <div className="subtitleAccessibilit">{i18n.language === 'it'? tour?.descrizione.substring(0,200) : tour?.descrizione_en.substring(0,200)}...</div>
         <div className="d-flex justify-content-between bottomCard marginTop24">
              <p className="card-text-price">{t("da")} <b>{tour?.costo}€</b>  p.p.</p>
              <p className="card-text-price">{tour?.durata} </p>
              </div>
         </Col>
         </Row>
         <hr className="solid"></hr>
        <Row>
        <div className="titoloBoxPrenotazioni marginBottom32">
            {t("itinerario")}
         </div>
        </Row>
        {prenotazione?.isMultyDays ? (
        <div >
        {tour != undefined && 
        <>
        <CardItinerarioPrenotazione tour={tour} key={tour} numero={1}></CardItinerarioPrenotazione>
        <CardItinerarioPrenotazione tour={tour} key={tour} numero={2}></CardItinerarioPrenotazione>
        </>
        }
        {tour?.titolo_3 != undefined &&
                    <CardItinerarioPrenotazione tour={tour} key={tour} numero={3}></CardItinerarioPrenotazione>
        }
        {tour?.titolo_4 != undefined &&
        <CardItinerarioPrenotazione tour={tour} key={tour} numero={4}></CardItinerarioPrenotazione>
        }
        {tour?.titolo_5 != undefined &&
        <CardItinerarioPrenotazione tour={tour} key={tour} numero={5}></CardItinerarioPrenotazione>
        }
        {tour?.titolo_6 != undefined &&
        <CardItinerarioPrenotazione tour={tour} key={tour} numero={6}></CardItinerarioPrenotazione>
        }
        </div>
        ):(
            <div >
            <ReactMarkdown>{i18n.language === 'it'? tour?.itinerario : this.props.tour.itinerario_en}</ReactMarkdown>
            </div>
        )}

        
        <hr className="solid"></hr>
        <Row>
        <div className="titoloBoxPrenotazioni marginBottom32">
            {t("dettaglio.prezzo")}
         </div>
        </Row>
        <div className="d-flex justify-content-between">
         <span className="testoPrezzo">€{tour?.costo} x {prenotazione?.numBambini1117+prenotazione?.numBambini410+prenotazione?.numAdulti}</span>
         <span className="testoPrezzo">€ {tour != undefined ? tour?.costo*(prenotazione?.numBambini1117+prenotazione?.numBambini410+prenotazione?.numAdulti) : ""}</span>
         
         </div>
         <hr className="solid"></hr>
         <div className="d-flex justify-content-between">
         <span className="totale"> {t("totale")}</span>
         <span className="totale fontWeight700">€ {tour != undefined ? tour?.costo*(prenotazione?.numBambini1117+prenotazione?.numBambini410+prenotazione?.numAdulti)  : ""}</span>
         
         </div>
         <div>
         <ReactMarkdown className="testoPrezzo">{t("testo.pagamento.step2")}</ReactMarkdown>
         </div>
         <div>
         <label className="labelBoxPrenotazioni marginTop56">{t('note')}</label>
         <textarea maxLength="500" className="textAreaPrenotazione"  rows="4" cols="50"  name="note" placeholder={note} id="name" value={note}  onChange={this.handleChange}></textarea>
         </div>

        </div>
        </div>
        <div className="btn-component">
          <input type="button" value={'Invia Richiesta'} className="buttonWizard" onClick={this.submit} />
        </div>
       
</>
    )
}
}
export default  withTranslation()(SecondoStep); 
