import React, { Component } from "react";
import '../../style/home.css';
import * as Constanti from '../../Utils/constants.js';
import axios from 'axios';
import { Col, Row } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import CardDestination from "./../molecule/destination-card.js";
import { Link } from "react-router-dom";

class DestinationHome extends Component{
    constructor(props){
    
        super(props);
        this.state = {
          listaLocation: null,
          modalShow:false,
          load:false
      }
    //  this.setModalShow = this.setModalShow.bind(this);
    }
    
    
    
    componentDidMount() {
        this.setState({load:true});
        var apiBaseUrl = Constanti.url_Backend+'getLocation';
        var self = this;
        axios.get(apiBaseUrl).then(function (response) {
       
        if (response.data.data != null){
           var location = response.data.data;
           self.setState({ listaLocation : location })
        }else{
          console.log("non ci sono location");
        }
        self.setState({load:false});
      })
      .catch(function (error) {
      console.log(error);
      self.setState({load:false});
      });
        
     
    }
 
  render(){
    const { listaLocation} = this.state;
    const { t,i18n } = this.props;
    return (
        <>
         <div className="destinationHome">
         <div className="d-flex justify-content-between">
         <span className="titleDestination">{t("destinazioni.label")}</span>
         <Link to="/destinazioni">
         <button className="right-button">{t("all.label")}</button>
         </Link>
         </div>
         <div className="listDestination-home" >
         {this.state.listaLocation != null && this.state.listaLocation.map(el => (
          <CardDestination destination={el}  key={el.id} ></CardDestination>
         ))}
         </div>
         </div>
         
</>
    )
}
}
export default withTranslation()(DestinationHome); 
