import React, { Component } from "react";
import '../../style/home.css';
import { Col, Row } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

class CardDestination extends Component{
    constructor(props){
    
        super(props);
    }
    
  
  
  render(){
    const { t,i18n } = this.props;
    return (
      <>
       <Link key={this.props.destination.id} to={'/destinazioni/'+this.props.destination.id}>
         <div className={"card "+ (this.props.colonne==6  ? 'detailCard' : '')} >
            <div className="centered">
            <img className="card-img-top" src={this.props.destination.immagine} alt="Card image cap"/>
            <div className="container-cardDestination">
           
            <label className="title-card-home">{i18n.language === 'it'? this.props.destination.titolo: this.props.destination.titolo_en}</label>
            </div>
                        
          </div> 
          </div> 
          </Link>
    
       
</>
    )
}
}
export default withTranslation()(CardDestination); 
