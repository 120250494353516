import { Component } from "react";
import { withTranslation } from "react-i18next";
import '../../style/blog.css';
import { useLocation, useParams } from "react-router";
import DettaglioArticolo from "./dettaglioArticolo";
import Footer from "../molecule/footer";

const Articolo = () => {
    let location  = useLocation();
    console.log(location);
    let articolo = location.state.articolo;
    
 
    
   
  
 return (
      <>
       <div className="contenitoreBlog" style={{marginTop:"0px"}}>
        <DettaglioArticolo articolo={articolo}></DettaglioArticolo>
        </div>
        <Footer></Footer>
        </>
  );
}

export default Articolo;