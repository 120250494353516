import React, { Component } from "react";
import * as Constanti from '../../Utils/constants.js';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../style/destination.css';
import { Col, Modal, Row,Button } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import cal from '../../images/caltrip.png';
import multy from '../../images/multidays-icon.png';
import visiva from '../../images/disabilita-visiva.png';
import cognitiva from '../../images/cognitiva.png';
import uditiva from '../../images/uditiva.png';
import motoria from '../../images/motoria.png';
import close from '../../images/ximage.png';
import selectArrow from '../../images/arrowDown.png';
import filters from '../../images/filters.png'
import empty from '../../images/Icon-Emptystate.png'
import axios from "axios";
import TourCard from "./tourCard.js";

class CorpoDestinazioni
 extends Component{
    constructor(props){
    super(props);
    
    this.state = {
      categorie: [],
      categorieSelected:[],
      dropdownID:null,
      show:false,
      tours:[], 
      filteredTours:[],
      multidays:[],
      disUditivaPress:false,
      disVisivaPress:false,
      disMotoriaPress:false,
      discognitivaPress:false,
      filteredDisabilita:[],
      filteredDisabilitaMobile:[],
      min:0,
      max:0,
      showTrip:true,
      showCategory:false,
      showFilter:false,
     }
     this.myRef = React.createRef();
     this.handleShow = this.handleShow.bind(this);
     this.handleClose = this.handleClose.bind(this);
     this.handleCloseSave = this.handleCloseSave.bind(this);
     this.handleChange = this.handleChange.bind(this);
     this.handleChangeTrip = this.handleChangeTrip.bind(this);
     this.handleChangeCheck = this.handleChangeCheck.bind(this);
     this.handleChangeCategory = this.handleChangeCategory.bind(this);
     this.wrapperRef = React.createRef();
     this.handleClickOutside = this.handleClickOutside.bind(this);
     this.wrapperRef2 = React.createRef();
    }

    
    handleChangeCategory(val){
      const found = this.state.categorieSelected.find(obj => {
        return obj === val;
      });
      if (found){
        let item = this.state.categorieSelected.filter((tag) => tag !== val);
        this.setState({categorieSelected : item});
     
      }else{
        var c = this.state.categorieSelected;
        c.push(val);
        this.setState({categorieSelected : c});
      }
    
    }  
    
    handleChangeTrip = (stato) =>{
      this.setState({showTrip: stato});
      if (stato === true){
        this.setState({ filteredTours : this.state.tours })
      }else{
        this.setState({ filteredTours : this.state.multidays })
      }
    }

    handleCloseSaveFilter = () =>{
      console.log(this.state.filteredDisabilitaMobile);
      if (this.state.filteredDisabilitaMobile.length >0) {
       this.setState({filteredDisabilita : this.state.filteredDisabilitaMobile });
       this.filtroCompleto(this.state.filteredDisabilitaMobile,null);
      }else{
        this.filtroCompleto();
      }
     
      this.setState({showFilter: false});
    }

    handleCloseFilter= () =>{
      this.setState({categorieSelected:[],disUditivaPress:false, disVisivaPress:false,showFilter: false, disMotoriaPress:false, discognitivaPress:false,filteredDisabilita:[],filteredDisabilitaMobile:[], min:0,
        max:0});
      this.setState({showFilter: false});
    }

    handleResetFilter= () =>{
      this.setState({categorieSelected:[],disUditivaPress:false, disVisivaPress:false,showFilter: false, disMotoriaPress:false, discognitivaPress:false,filteredDisabilita:[],filteredDisabilitaMobile:[], min:0,
        max:0});
        this.filtroCompleto(null);
    }


    checkIsFilter(){
      if (this.state.categorieSelected.length >0 || this.state.filteredDisabilitaMobile.length >0 || this.state.min >0 || this.state.max > 0){
        return true;
      }
      return false;
    }

    handleClose = () =>{
      this.setState({min:0,max:0});
      this.setState({show: false});
    }

    handleCloseCategory = () =>{
      this.setState({categorieSelected:[]})
      this.setState({showCategory: false});
      this.filtroCompleto(null, []);
    }
    handleCloseCategoryModal = () =>{
      this.setState({showCategory: false});

    }

    handleCloseMinMaxModal = () =>{
      this.setState({show: false});

    }

    handleCloseSaveCategory = () =>{
      this.filtroCompleto();
      this.setState({showCategory: false});
    }

    handleCloseSave = () =>{
   /*   if (this.state.max !== 0){
        this.setState({ filteredTours : this.state.tours.filter(data => parseInt(data.costo) <= this.state.max && parseInt(data.costo) >= this.state.min) });   
      }*/
      this.filtroCompleto();
      this.setState({show: false});
    }

    handleShow = () =>{
      //console.log(this.myRef.current.offsetLeft);
     // console.log(this.myRef.current.offsetTop);
    //  document.documentElement.style.setProperty('--top-modal', this.myRef.current.offsetTop+40 );
    //  document.documentElement.style.setProperty('--left-modal', this.myRef.current.offsetLeft );
      this.setState({show: true});
      this.setState({showCategory: false});
    }

    handleShowCategory=() =>{
      this.setState({showCategory: true});
      this.setState({show: false});
    }


    openModalFilter=()=>{
      this.setState({showFilter: true});
    }

    handleDisabilita(tipo){
      if (tipo==='visiva'){
        this.setState({disVisivaPress: !this.state.disVisivaPress});
      }else if (tipo ==='uditiva'){
        this.setState({disUditivaPress: !this.state.disUditivaPress});
        
      }else if (tipo ==='cognitiva'){
        this.setState({discognitivaPress: !this.state.discognitivaPress});
        
      }else if (tipo ==='motoria'){
        this.setState({disMotoriaPress: !this.state.disMotoriaPress});
      }
      var array = [...this.state.filteredDisabilita];
      var index = this.handleCheck(tipo);
         if (index === -1 ){
          array.push(tipo);
           /*this.setState({
            filteredDisabilita: update(this.state.filteredDisabilita, {
             $push: [
              tipo
             ]
           })
           })*/
       
         }else{
          array.splice(index,1);
           /*this.setState({
            filteredDisabilita: update(this.state.filteredDisabilita, {
             $splice: [
               [index,1]
             ]
           })
         })*/

         }
         this.setState({
          filteredDisabilita:array});
         this.filtroCompleto(array,null);
        
       
    }

    handleDisabilitaMobile(tipo){
      var array = [...this.state.filteredDisabilitaMobile];
      var index = this.handleCheck(tipo);
         if (index === -1 ){
          array.push(tipo);
       
         }else{
          array.splice(index,1);
         }
         this.setState({
          filteredDisabilitaMobile:array});
    }

    filtroCompleto= (array,arrayCategory) =>{
      if (array == null)
      array = [...this.state.filteredDisabilita];

      console.log(array);
      var arrayFiltrato =  this.state.showTrip ?
      [...this.state.tours]: [...this.state.multidays];
      if (array.findIndex(obj => obj === 'visiva') !==-1)
      arrayFiltrato = arrayFiltrato.filter(data => data.isVisiva === 1 );
      if (array.findIndex(obj => obj === 'cognitiva') !==-1)
      arrayFiltrato = arrayFiltrato.filter(data => data.isCognitiva === 1 );
      if (array.findIndex(obj => obj === 'motoria') !==-1)
      arrayFiltrato = arrayFiltrato.filter(data => data.isMotoria === 1 );
      if (array.findIndex(obj => obj === 'uditiva') !==-1)
      arrayFiltrato = arrayFiltrato.filter(data => data.isUditiva === 1 );
      if (arrayCategory == null){
      if (this.state.categorieSelected != null && this.state.categorieSelected.length >0){
        this.state.categorieSelected.map((categoria) =>{ 
        arrayFiltrato = arrayFiltrato.filter(data => data.categoria_tour_id === categoria);
        });
      }
    }else if (arrayCategory.length >0){
      arrayCategory.map((categoria) =>{ 
        arrayFiltrato = arrayFiltrato.filter(data => data.categoria_tour_id === categoria);
        });
    }
      if (parseInt(this.state.max) !== 0){
        arrayFiltrato = arrayFiltrato.filter(data => parseInt(data.costo) <= parseInt(this.state.max) && parseInt(data.costo) >= this.state.min);   
      }
     this.setState({ filteredTours : arrayFiltrato});
    }

    handleCheck  = val => {
      return this.state.filteredDisabilita.findIndex(obj => obj === val);
    
    }

    componentDidMount = () =>  {
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("mousedown", this.handleClickOutside2);
      sessionStorage.removeItem('prenotazione');
      sessionStorage.removeItem('obj');
    //  this.setState({load:true});
      var apiBaseUrl = Constanti.url_Backend+'getAllCategorieTour';
      var self = this;
      axios.get(apiBaseUrl).then(function (response) {
     
      if (response.data.data != null){
         var categorie = response.data.data;
         self.setState({ categorie : categorie })
      }else{
        console.log("non ci sono location");
      }
      self.setState({load:false});
    })
    .catch(function (error) {
    console.log(error);
    self.setState({load:false});
    });

   if (this.props.destinazione){
      apiBaseUrl = Constanti.url_Backend+'getToursByLocation';
      var payload = {
        "id_destinazione": this.props.destinazione.id 
      }
      
      axios.post(apiBaseUrl,payload).then(function (response) {
     
      if (response.data.data != null){
         var tours = response.data.data;
         console.log(tours);
         self.setState({ tours : tours })
         self.setState({ filteredTours : tours })
      }else{
        console.log("non ci sono tours");
      }
      self.setState({load:false});
    })
    .catch(function (error) {
    console.log(error);
    self.setState({load:false});
    });
     apiBaseUrl = Constanti.url_Backend+'getMultyByLocation';
     payload = {
      "id_destinazione": this.props.destinazione.id 
    }
    
    axios.post(apiBaseUrl,payload).then(function (response) {
   
      if (response.data.data != null){
        var multy = response.data.data;
        self.setState({ multidays : multy })
       
      }else{
        console.log("non ci sono tours");
      }
      self.setState({load:false});
    })
    .catch(function (error) {
    console.log(error);
    self.setState({load:false});
    });
  }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutside2);
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name] : value });
    }

  handleChangeCheck(e){
    const { name } = e.target;
    if (e.target.checked){
     var c = this.state.categorieSelected;
     c.push(name);
     this.setState({categorieSelected : c});
    }else{
      let item = this.state.categorieSelected.filter((tag) => tag !== name);
   this.setState({categorieSelected : item});
    }
  
  }  
 

  updateSelectCategorie(event) {
    console.log(event.target.value);
    this.setState({dropdownID : event.target.value})
    this.filtroCompleto(null,event.target.value);
   /*
    if (event.target.value !== ""){
      if (this.state.showTrip){
       this.setState({ filteredTours : this.state.tours.filter(data => data.categoria_tour_id == event.target.value) });
      }else{
        this.setState({ filteredTours : this.state.multidays.filter(data => data.categoria_tour_id == event.target.value) });
      }
    
    } */ 
    //aggiorno la lista
  }
  
  handleClickOutside(event) {
    if (this.wrapperRef && this.wrapperRef.current != null && !this.wrapperRef.current.contains(event.target)) {
      this.setState({showCategory: false});
    }
    if (this.wrapperRef2 && this.wrapperRef2.current != null && !this.wrapperRef2.current.contains(event.target)) {
      this.setState({show: false});
    }
  }
 

  render(){
    const {show,min,max,showTrip,showCategory,categorieSelected,showFilter,filteredDisabilitaMobile } = this.state;
    const {t,i18n} = this.props;
    return (
        <>
        <div className="contenitoreCorpo marginBottom160">
        <div className="roWbuttonTour">
        <div className="button-row destinationDetail">
        <button onClick={(e) => this.handleChangeTrip(true)} className={"trip-button " + (showTrip ? "" : "deactivate")}><img src={cal} width="24" height="24" alt="" className="imgButton"/>{t("tours.label")}</button>
        <button onClick={(e) => this.handleChangeTrip(false)} className={"trip-button " + (!showTrip ? "" : "deactivate")}><img src={multy} width="24" height="24" alt="" className="imgButton"/>{t("multy.label")}</button>
        </div>
        </div>
        
        <div className="destinationDetail"> 
        <Row>
      <div className="titleCorpo marginTop80">
        {showTrip ? t("tours.label") : t("multy.label")}
        </div>
        </Row>
        <Row>
        <div className="subtitleCorpo marginTop16">
        {showTrip ? t("corpoDestinazioni.label") : t("corpoDestinazioniMulty.label")}
        </div>
        </Row>
        <div className="filterMobile">
        <button onClick={this.openModalFilter} className={"buttonFilter "+(this.checkIsFilter() ? "activate":"")} style={{width:"100%"}}>{t("filtri")}<img src={filters} width="20" alt="" height="20" className="imgFilter"/></button>
        </div>
        <div className="filterWeb">
        <div  className="display-row marginTop24 positionRelative">
        {showTrip &&
        <button onClick={this.handleShowCategory} className={"selectCorpo " + (categorieSelected.length > 0 ? "activate": "")}  ref={this.myRef}>  {t("categoria")}<img src={selectArrow} alt="" height="5px"  className="imgButton"/></button>
        }
        {showCategory &&
        <div className="modalCorpoDestinazione modalCategorie" ref={this.wrapperRef}>
        <Row className="alighRight">
            <button  onClick={this.handleCloseCategoryModal} className="buttonModalClose text-right"><img className="prev-slick-arrow" alt="Close" src={close}></img></button>
        </Row>
        {this.state.categorie?.map((option) => 
          <div className="marginBottom8">
          <input type="checkbox" key={option.id} defaultChecked={categorieSelected.find((element)=>{return element == option.id})}    onChange={this.handleChangeCheck} className="w-checkbox-input checkbox" name={option.id}/>
          <span className="checkbox-label w-form-label" > {i18n.language === 'it'? option.categoriaTours : option.categoriaTours_en }</span>
          </div>
          )}
          <hr className="solid"></hr>
          <div className="marginTop16 d-flex justify-content-between">
          <Button className="buttonCancellaModalTour" onClick={this.handleCloseCategory}>
          {t("reset")}
          </Button>
          <Button className="buttonSalvaModal" onClick={this.handleCloseSaveCategory }>
            {t("applica")}
          </Button>
        </div>
        </div>
        }
         <button onClick={this.handleShow} className={"buttonPrezzo " + (min !== 0 || max !== 0 ? "activate": "")}  ref={this.myRef}>  {t("prezzo")}<img alt="" src={selectArrow} height="5px"  className="imgButton"/></button>
        {show &&
        <div className="modalCorpoDestinazione" ref={this.wrapperRef2}>
        <Row className="alighRight">
            <button  onClick={this.handleCloseMinMaxModal} className="buttonModalClose text-right"><img className="prev-slick-arrow" alt="Close" src={close}></img></button>
        </Row>
        <Row >
            <Col xs={6}>
            <label className="labelClassDestinazione marginLeft8">Min</label>
            <input className="input-modal-destination" style={{width:'112px',height:'48px'}} placeholder="0€" type="number" name="min"  min="0" value={min}  onChange={this.handleChange}/>
            </Col>
            <Col xs={6}>
            <label className="labelClassDestinazione marginLeft8">Max</label>
            <input className="input-modal-destination" style={{width:'112px',height:'48px'}} placeholder="100€" type="number" name="max"  min="0" value={max}  onChange={this.handleChange}/>  
            </Col>
          </Row>
          <hr className="solid"></hr>
          <div className="marginTop16 d-flex justify-content-between">
          <Button className="buttonCancellaModalTour" onClick={this.handleClose}>
          {t("reset")}
          </Button>
          <Button className="buttonSalvaModal" onClick={this.handleCloseSave }>
            {t("applica")}
          </Button>
        </div>
        </div>
        }
         <div className="vl"></div>
         <button className={"buttonDisabilita " + (this.state.disVisivaPress? "activate": "")} onClick={(e)=>this.handleDisabilita('visiva')}><img alt={t("dvisiva")} src={visiva} height="24px"  className="imgButton"/>{t("dvisiva")}</button>
         <button className={"buttonDisabilita " + (this.state.discognitivaPress? "activate": "")} onClick={(e)=>this.handleDisabilita('cognitiva')}><img alt={t("dcognitiva")} src={cognitiva} height="24px" width= "23px"  className="imgButton"/>{t("dcognitiva")}</button>
         <button className={"buttonDisabilita " + (this.state.disUditivaPress? "activate": "")} onClick={(e)=>this.handleDisabilita('uditiva')}><img alt={t("duditiva")} src={uditiva} height="24px"  className="imgButton"/>{t("duditiva")}</button>
         <button className={"buttonDisabilita " + (this.state.disMotoriaPress? "activate": "")} onClick={(e)=>this.handleDisabilita('motoria')}><img  alt={t("dmotoria")} src={motoria} height="24px"  className="imgButton"/>{t("dmotoria")}</button>
        </div>
        </div>
        <Modal  show={showFilter} className="modalFilter"
        onHide={this.handleCloseFilter} style={{paddingLeft:"6px"}}>
        <Modal.Body>
        <Row >
        <Col xs={9}><div className="text-center filterClass">{t('filtri')}</div></Col>
        <Col xs={2}><button  onClick={this.handleCloseFilter} className="buttonSlider text-right"><img className="prev-slick-arrow" alt="" src={close}></img></button></Col>
        </Row>
        <hr className="solid"></hr>
        <div className="titleModalTour">{t('categorie')}</div>
        <div  className="mobileFlexWrap">
        {this.state.categorie?.map((option) => 
            <div key={"Filter-"+option.id} style={{marginTop:"8px"}}>
            <button className={"buttonCategoryModal "+ (categorieSelected.find((element)=>{return element === option.id}) ? "activate":"")} onClick={(e)=>this.handleChangeCategory(option.id)}> {i18n.language === 'it'? option.categoriaTours : option.categoriaTours_en }</button>
            </div>
            )}
        </div>
       
        <hr className="solid"></hr>
       
        <div className="titleModalTour">{t('prezzo')}</div>
        <Row >
            <Col xs={6} >
            <label className="labelClassDestinazione marginLeft8">Min</label>
            <input className="input-modal-destination" style={{width:'98%',height:'48px'}} placeholder="0€" type="number" name="min"  min="0" value={min}  onChange={this.handleChange}/>
            </Col>
            <Col xs={6}>
            <label className="labelClassDestinazione marginLeft8">Max</label>
            <input className="input-modal-destination" style={{width:'98%',height:'48px'}} placeholder="100€" type="number" name="max"  min="0" value={max}  onChange={this.handleChange}/>  
            </Col>
          </Row>
          <hr className="solid"></hr>
          <div className="titleModalTour">{t('disabilita')}</div>
          <div className="mobileFlexWrap">
        
          <button className={"buttonMobile " + (filteredDisabilitaMobile.find((element)=>{return element === 'visiva'}) ? "activate":"")} onClick={(e)=>this.handleDisabilitaMobile('visiva')}><img src={visiva} height="24px" alt=""  className="imgButton"/>{t("dvisiva")}</button>
        
        
         <button className={"buttonMobile "+ (filteredDisabilitaMobile.find((element)=>{return element === 'cognitiva'}) ? "activate":"")} onClick={(e)=>this.handleDisabilitaMobile('cognitiva')}><img src={cognitiva} height="24px" alt="" width= "23px"  className="imgButton"/>{t("dcognitiva")}</button>
        
        
         <button className={"buttonMobile "+ (filteredDisabilitaMobile.find((element)=>{return element === 'uditiva'}) ? "activate":"")} onClick={(e)=>this.handleDisabilitaMobile('uditiva')}><img src={uditiva} height="24px" alt="" className="imgButton"/>{t("duditiva")}</button>
        
        
         <button className={"buttonMobile "+ (filteredDisabilitaMobile.find((element)=>{return element === 'motoria'}) ? "activate":"")} onClick={(e)=>this.handleDisabilitaMobile('motoria')}><img src={motoria} height="24px" alt="" className="imgButton"/>{t("dmotoria")}</button>
        
          </div>
           <hr className="solid"></hr>
          <div className="marginTop16 d-flex justify-content-between">
          <Button className="buttonCancellaModalTour" onClick={this.handleResetFilter}>
          {t("resetFilter")}
          </Button>
          <Button className="buttonSalvaModalTour" onClick={this.handleCloseSaveFilter }>
            {t("saveFilter")}
          </Button>
        </div>
        <div style={{width:"100%",height:"80px"}}></div>
        </Modal.Body>
        </Modal>
        
        <div className="marginTop40 grid-tour">
        {this.state.filteredTours?.map((option) => 
          <TourCard tour={option} key={option.id} isTour={showTrip}></TourCard>
        )}
        </div>
        {this.state.filteredTours.length == 0 &&
          <div className="emptyState d-flex justify-content-center flex-column align-items-center"> <img src={empty} width={40} className="marginBottom24"></img> <span>{t('text.empty')} {showTrip ? "Tours":"MultiDays Trips"} {t('text.empty2')}</span>  </div>
        }
        </div>
        
        </div>
          
</>
    )
}
}
export default  withTranslation()(CorpoDestinazioni); 
