import React, { Component } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../style/home.css';
import '../../style/destination.css';
import Footer from "../molecule/footer";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { withTranslation } from 'react-i18next';
import CardDestination from "./../molecule/destination-card.js";





 

class Destinazioni extends Component{
    constructor(props){
    
        super(props);
        this.state = {
          listaLocation: null,
          modalShow:false,
          load:false
      }
  
    }
    componentDidMount() {
        this.setState({load:true});
        var apiBaseUrl = Constanti.url_Backend+'getAllLocation';
        var self = this;
        axios.get(apiBaseUrl).then(function (response) {
       
        if (response.data.data != null){
           var location = response.data.data;
           self.setState({ listaLocation : location })
        }else{
          console.log("non ci sono location");
        }
        self.setState({load:false});
      })
      .catch(function (error) {
      console.log(error);
      self.setState({load:false});
      });
        
     
    }

  render(){
    const { listaLocation} = this.state;
    const { t} = this.props;
    return (
        <>
    <>
         <div className="destination">
         <div className="container">
         <div className="centered">
         <div className="headDestination">
         <div className="destinationTitlepage">
         {t("destinazioni.label")}
         </div>
         <div className="destinationTitle">
         {t("destinationTitle.label")}
         </div>
         <div className="destinationSubTitle">
         {t("destinationSubTitle.label")}
         </div>
         </div>
         </div>
         </div>
       <div className="listDestination" style={{marginTop:'100px'}}>
         {listaLocation != null && listaLocation.map(el => (
            
          <CardDestination destination={el} colonne="6" key={el.id}></CardDestination>
          
         ))}
         </div>
         </div>
         
</>
        
            <Footer></Footer>
   
</>

    )
}
}
export default withTranslation()(Destinazioni); 
