import { Component } from "react";
import { withTranslation } from "react-i18next";
import '../../style/blog.css';
import moment from "moment";
import { Link } from "react-router-dom";

class CardArticolo
 extends Component{
    constructor(props){
        super(props);
   }
  
    


    render(){
        const {i18n,articolo} = this.props;
         return (
            <>
         
         <div className="blog-card-wrapper">
         <Link to={"/blog/articolo/"+articolo.id}  state={{articolo}} className="linkArt">
          <div ><img src={articolo.thumbnail} className="blog-card-image"></img></div>
          <div className="blog-card-content">
            <div className="category-label">{i18n.language === 'it'? articolo.categoria_articolo.categoria : articolo.categoria_articolo.categoria_en}</div>
            <h4 className="h4Title">{i18n.language === 'it'? articolo.titolo : articolo.titolo_en}</h4>
            <p className="p description">{i18n.language === 'it'? articolo.testo.substring(0, 200)+"..." : articolo.testo_en.substring(0, 200)+"..."}</p>
            <div className="d-flex justify-content-between">
              <div className="author-wrapper">
                <div className="author-img"><img src={articolo.user.avatar} className="author-img"></img></div>
                <div className="author bold">{articolo.user.name}</div>
              </div>
              <div className="data">{moment(articolo.created_at).format('DD MMM, YYYY')}</div>
            </div>
          </div>
          </Link>
        </div>
        
               
  </>
    )
}
}


    export default withTranslation()(CardArticolo); 