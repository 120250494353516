import React, { Component } from "react";
import '../../style/modal.css';
import { Col, Modal, Row,Button } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import imgRegistrati from '../../images/registratiModal.png';
import close from '../../images/ximage.png';
import showPwdImg from '../../images/show-password.svg';
import hidePwdImg from '../../images/hide-password.svg';
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router";
import { UserContext } from "../context/UserContext";
import context from "react-bootstrap/esm/AccordionContext";
import imgLogin from '../../images/accedi-img.jpg';
import ReactMarkdown from "react-markdown";





class ModalAccessibilita
 extends Component{
  static contextType = UserContext;
    constructor(props){
    super(props);

    this.state = {
      show:this.props.show,
    }
  this.handleClose =this.handleClose.bind(this);
  this.showWizard = this.showWizard.bind(this);
    }

   

    showWizard(){
      this.setState({show:false});
      this.props.close();
      this.props.navigate('/wizardAccessibilita');
        
    }
  
  
    handleClose(){
     this.setState({show:false});
     this.props.close();
    }

  render(){
    const {show} = this.state;
    const {t,i18n } = this.props;
    return (
        <>
        <Modal  show={show} className="modalRegistrati modalAccessibilitaWizard" style={{maxWidth:"650px"}}
        onHide={this.handleClose}>
        <Modal.Body>
           <Row>
           <Col xs={12} className="contenitoreTestiAccess">
            <div className="titleModalAccessibilita">{t('titoloModalAccessibilita')}</div>
            <div className="testoModalAccessibilita marginTop30"><ReactMarkdown>{t('modalaccessibilita1')}</ReactMarkdown></div>
            <div className="testoModalAccessibilita marginTop30"><ReactMarkdown>{t('modalaccessibilita2')}</ReactMarkdown></div>
            <button className="btn-prenotazione marginTop30" onClick={this.showWizard}> {t('modalaccessibilitaButton')}</button>
            <div className="testoModalAccessibilita marginTop10">{t('modalaccessibilitaprivacy')}<button type="button" className="btn btn-link privacy" onClick={this.zard}  style={{color:"#653279"}}>Privacy Policy</button></div>
            <button type="button" className="btn btn-link privacy underlineNone marginBottom40" onClick={this.handleClose} style={{color:"#653279"}}>{t('piutardi')}</button>
           </Col>
           </Row>
       
        </Modal.Body>
        </Modal>
        </>
    )
}
}
export default  withTranslation()((props) => (
  <ModalAccessibilita
      {...props}
      params={useParams()}
      navigate={useNavigate()}
  />
)); 
