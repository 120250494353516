import React, { Component } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../style/home.css';
import '../../style/destination.css';
import Footer from "../molecule/footer";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { useParams } from "react-router";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import CorpoMulty from "./corpoMulty";
import HeadTour from "../tours/headTour";



class MultyDetail
 extends Component{
    constructor(props){
    
        super(props);
        this.state = {
          tour: null,
          show: false,
          }
      
       this.changeButton = this.changeButton.bind(this);
  
    }

    changeButton(){
      this.setState({show : !this.state.show });
      
    }

    componentDidMount() {

      if (this.props.params.id !== 'null'){
        this.setState({load:true});
        var apiBaseUrl = Constanti.url_Backend+"getMultyByID";
         var payload = {
          "id_prodotto": this.props.params.id
        }
        var self = this;
        axios.post(apiBaseUrl,payload).then(function (response) {
        if (response.data.data != null){
           var location = response.data.data[0];
           self.setState({ tour : location })
        }else{
          console.log("non ci sono Multy per questo id");
        }
        self.setState({load:false});
      })
      .catch(function (error) {
      console.log(error);
      self.setState({load:false});
      });
      }
    }
    
  

  render(){
    const { tour,show} = this.state;
    var images = [];
    if (tour != null && tour.galleryImg !== undefined && !tour.galleryImg !== null ){
    tour.galleryImg.map(function(item){
     var img = {
       "src": item.url
     }
     images.push(img);
    });
  }
    
   
    
    return (
        <>
   
    <div className="tourDetail">
         {this.state.tour !== undefined && !this.state.tour !== null &&
          <div>
            <HeadTour listImg={tour?.galleryImg} onShowLightbox={this.changeButton} key={tour?.title}  ></HeadTour>
            <CorpoMulty tour={tour} key={tour?.id} ></CorpoMulty>
          </div>
         }
         </div>
          <Lightbox
        open={show}
        close={this.changeButton}
        slides={images}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
      />
   
   
            <Footer></Footer>
   
</>

    )
}
}
export default  (props) => (
  <MultyDetail
      {...props}
      params={useParams()}
  />
); 
