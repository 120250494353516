import React, { Component } from "react";
import '../../style/home.css';
import { withTranslation } from 'react-i18next';
import fesr from '../../images/FESR-logo.png';
import eu from '../../images/UE-logo.png';
import rep from '../../images/REP ITA-logo.png';
import reg from '../../images/logo_regioneCAMP.png';
import apa from '../../images/logo_apa.png';
import alberghi from '../../images/federalberghi 1.png';
import fiavet from '../../images/logo-fiavet.png';
import ABBAC from '../../images/Logo-ABBAC.png';
import bmt from '../../images/bmt.png';
//import poc from '../../images/poc2020-logo.png';

class Partner extends Component{
  constructor(props){
    super(props);
  
    this.state = {
    
      
};
  
    
    
  };

  

  render(){
    const { t} = this.props;
    return (
        <>
         <div className="partnerHome">
         <div className="titleOfferta fontSize24">{t('textPartner')}</div>
         <div className="subtitle-card-home text-center" style={{marginTop:"16px",maxWidth:'786px',marginLeft:"auto",marginRight:"auto",fontSize:"16px"}}>{t("subtitlePartner")}</div>
         <div className="d-flex align-items-center marginTop40 justify-content-between mobile">
         <img className="imgPartner" alt="" src={fesr}/>
         <img className="imgPartner" alt="" src={eu}/>
         <img className="imgPartner" alt="" src={rep}/>
         <img className="imgPartner" alt="" src={reg}/>
         
         </div>
        </div>
        <div className="partnerHome">
         <div className="titleOfferta fontSize24">{t('partnership')}</div>
         <div className="d-flex align-items-center marginTop40 justify-content-between mobile">
         <img className="imgPartner_2" alt="" src={fiavet}/>
         <img className="imgPartner_2" alt="" src={bmt}/>
         <img className="imgPartner_2" alt="" src={ABBAC}/>
         <img className="imgPartner_2" alt="" src={alberghi}/>
         <img className="imgPartner_2" alt="" src={apa}/>
         
         </div>
        </div>
        
</>
    )
}
}
export default withTranslation()(Partner); 
