import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { UserContext } from "../context/UserContext.js";
import arrowBack from "../../images/backArrow.png";
import arrowRight from "../../images/arrow-right-white.png";
import fineIco from "../../images/fineIco.png";
import { WithContext as ReactTags } from 'react-tag-input';
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import Select from 'react-select';


class Step
 extends Component{ 
    static contextType = UserContext;
    constructor(props){
      super(props);
      this.state = {
         value:this.props.step.rispostaUser!= null ? this.props.step.rispostaUser : "",
         required:false,
         options : [
          { value: 'Napoli', label: 'Napoli' },
          { value: 'Pompei', label: 'Pompei' },
          { value: 'Costiera Amalfitana', label:  'Costiera Amalfitana' },
          { value: 'Capri', label: 'Capri' },
          { value: 'Campi Flegrei', label: 'Campi Flegrei' },
          { value: 'Avellino', label: 'Avellino' },
          { value: 'Caserta', label:  'Caserta' },
          { value: 'Salerno', label: 'Salerno' },
          { value: 'Isole Campane', label: 'Isole Campane' },
          { value: 'Altre destinazioni', label: 'Altre destinazioni' },
        ],
        
       //  suggestions:[{id:'Napoli',text:'Napoli'},{id:'avellino',text:'avellino'},{id:'capri',text:'capri'},{id:'ischia',text:'ischia'},{id:'pompei',text:'pompei'},{id:'salerno',text:'salerno'}],
         tags:[],
         commenti:""
      }
        this.submit = this.submit.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheck = this.handleChangeCheck.bind(this);
       this.indietro = this.indietro.bind(this);
    }

    onValueChange(event) {
      this.setState({
        value: event.target.value
      });
    }
    handleChangeCheck(e){
      const { name } = e.target;
      if (e.target.checked){
        if (this.state.value != null){
        this.setState({
          value: this.state.value + " "+name
        });
      } else{
        this.setState({
          value: " "+name
        });
      }
      }else{
        this.setState({
          value: this.state.value.replace(" "+name,"")
        });
      }
    }

    submit (){
      if (this.props.step.id === this.props.lunghezza && this.state.commenti != null && this.state.tags.length >0){
      this.setState({required:null});
     // this.props.funzione(this.state.value);
       var stringData = "";
       for (var i=0; i < this.state.tags.length; i++) {
        stringData += this.state.tags[i].value+",";
       }
      var apiBaseUrl = Constanti.url_Backend+"modificaTagsComment";
      var payload= {
          "user_id": JSON.parse(sessionStorage.getItem('user')).id,
          "tags": stringData.slice(0, -1),
          "commenti":this.state.commenti
        }
   
      console.log(payload);
      var self = this;
      axios.post(apiBaseUrl, payload).then(function (response) {
       
        var userLogin= response.data.data;
        if (userLogin !== 'KO'){
          self.props.funzione(self.state.value);
         
      //   self.props.update(userLogin);
      
         
        }
     
      });
     
      }else if (this.props.step.id < this.props.lunghezza && this.state.value != null){
        this.props.funzione(this.state.value);
      }else{
        this.setState({required: true});
      }
      }
    
      handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name] : value });
       
    }
    
      handleDelete = i => {
        let item = this.state.tags.filter((tag, index) => index !== i);
        this.setState({tags:item});
        // setTags();
       };
       
       handleAddition = tag => {
         var isPresent = this.state.suggestions.find((element)=>{
           return element.text == tag.text;
         });
         if (isPresent){
         this.setState({tags:[...this.state.tags, tag]});
         }
       };
       
       handleDrag = (tag, currPos, newPos) => {
         const newTags = this.state.tags.slice();
       
         newTags.splice(currPos, 1);
         newTags.splice(newPos, 0, tag);
       
         // re-render
         this.setState({tags:newTags});
       };
       
       handleTagClick = index => {
         console.log('The tag at index ' + index + ' was clicked');
       };

    indietro (){
      this.props.funzioneBack();
   }
   handleChangeSelect = (tags) => {
    this.setState({ tags });
  }
   
   componentWillReceiveProps(nextProps) {
    // This will erase any local state updates!
    // Do not do this.
    this.setState({ value: nextProps.step.rispostaUser ,required:null});
  }
   
   

  render(){
    const {commenti,options,tags} = this.state;
    const {t,i18n} = this.props;
    return (
        <>
         <div className="wizard">
        <div className="contenuto">
        {this.props.step.id === this.props.lunghezza &&
          <>
          <div className="titoloWizardAccessibilita">
         {t('commenti.pef')}
         </div>
         <div className="subtitleWizardAccessibilita marginTop10">{t('subtitleWizardAcc')}</div>
         <Row >
        <div className="domanda marginTop10">{t('destinazioni.future')}</div>
                          <div className="content_wrapper flex">
                          <Select
                          isMulti
                          value={tags}
                          name="colors"
                          options={options}
                          onChange={this.handleChangeSelect}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                     
                          </div>
                          </Row>
                          <Row >
                       <div className="domanda marginTop30">{t('feedback')}</div>
                          <div className="content_wrapper flex">
                          <textarea maxLength="500" className="textAreaAccess"  rows="4" cols="50"  name="commenti" placeholder={commenti} id="name" value={commenti}  onChange={this.handleChange}></textarea>
                          </div>
                          </Row>             
                          </>             
        }
        {this.props.step.id < this.props.lunghezza &&
        <>
         <div className="titoloWizardAccessibilita">
         {t('titleWizardAcc')}
         </div>
         <div className="subtitleWizardAccessibilita marginTop10">{t('subtitleWizardAcc')}</div>
         <Row>
         <Row xs={12}>
         <label className="domandaWizard marginTop40">{i18n.language === 'it'? this.props.step.domanda : this.props.step.domanda_en}</label>
         </Row>
         {this.props.step.id !== 2 &&
         <div className="flex-column">
          {this.props.step.risposte.map((option,index) => 
          <div><input type="radio" className="radioWizard"  onChange={this.onValueChange} value={index}  checked={this.state.value == index} />{option}</div>  
          )}
        </div>
        }
        {this.props.step.id === 2 &&
         <div className="flex-column">
          {this.props.step.risposte.map((option,index) => 
          <div><input type="checkbox" key={option.id} defaultChecked={this.state.value != null ? this.state.value.includes(option): false}    onChange={this.handleChangeCheck} className="w-checkbox-input checkbox" name={option}/>{option}</div>  
          )}
        </div>
        }
         </Row>
         </>
        }
         {this.state.required &&
          <label className="domandaWizard marginTop10" style={{color:"red"}}>{t('requiredWizardAcc')}</label>
        }      
        
        <div className="btn-component" style={{display:"flex",justifyContent: "space-between"}}>
        {this.props.step.id > 1 &&
          <button  className="buttonWizard undoButton btn-step" onClick={this.indietro} ><img src={arrowBack} alt="indietro" height={12} width={12} style={{marginRight:"13px"}}/>Indietro</button>
        }
         {this.props.step.id < this.props.lunghezza &&
          <button  className="buttonWizard btn-step" onClick={this.submit} >Avanti<img src={arrowRight} alt="avanti" height={12} width={12} style={{marginLeft:"13px"}}/></button>
         }
          {this.props.step.id === this.props.lunghezza &&
          <button className="buttonWizard btn-step" onClick={this.submit} >Fine<img src={fineIco} alt="fine" height={12} width={12} style={{marginLeft:"13px"}}/></button>
        }
        </div>
        </div>
       
        </div>
       
</>
    )
}
}

export default  withTranslation()((props) => (
  <Step
      {...props}
      params={useParams()}
      navigate={useNavigate()}
  />
)); 