import React, { Component } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../style/destination.css';
import Footer from "../molecule/footer";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { Row } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import CardDestination from "./../molecule/destination-card.js";


class HeadDestinazioni
 extends Component{
    constructor(props){
    super(props);
      
  
    }
  
  render(){
    const {i18n } = this.props;
    return (
        <>
  <section className="hero_section destinazione wf-section" style={{backgroundImage: ` linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%), url(${this.props.location?.immagine})` }}>
        <div className="destinationDetail">
           <div className="flexDestination">
            <div className="title-head" style={{textAlign:"center"}}>{i18n.language === 'it'? this.props.location?.titolo: this.props.location?.titolo_en}</div>
            
            </div>
        </div>
        </section>


      
         
</>
    )
}
}
export default  withTranslation()(HeadDestinazioni); 
