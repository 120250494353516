import { Component, useReducer } from "react";
import { withTranslation } from "react-i18next";
import '../../style/profilo.css';
import { useLocation, useNavigate, useParams } from "react-router";
import 'react-tabs/style/react-tabs.css';
import { use } from "i18next";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert/dist/components/SweetAlert";
import arrow from "../../images/arrow-right.png"
import { UserContext } from "../context/UserContext";



class Account extends Component{
  static contextType = UserContext;
  constructor(props){
  super(props);
  this.state = {
    user:null,
    emailAccount:'',
    passwordAccount:'**********',
    oldPassword:'',
    newPassword:'',
    errorOldPwd : '',
    confirmPassword:'',
    errorPwd:'',
    errorConfirm:'',
    isReadOnly:true,
    submitted:false,
    haveError:false,
    alert:false,
  }
  this.handleModify = this.handleModify.bind(this);
  this.hanldeSave = this.hanldeSave.bind(this);
  this.handleChange = this.handleChange.bind(this);
  this.validateInput = this.validateInput.bind(this);
  this.onCancelDelete = this.onCancelDelete.bind(this);
  this.handleCancelAccount = this.handleCancelAccount.bind(this);
  this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  hanldeSave(){
    if (this.state.errorOldPwd ==='' && this.state.errorConfirm ==='' && this.state.errorPwd ==='' ){
    this.setState({submitted:true});
    var apiBaseUrl = Constanti.url_Backend+"modificaPassword";
    var payload= {
        "id": this.state.user.id,
        "oldPassword" : this.state.oldPassword,
        "newPassword": this.state.newPassword
    }
    var self = this;
    axios.post(apiBaseUrl, payload).then(function (response) {
     
      var userLogin= response.data.data;
      if (userLogin !== 'KO'){
      sessionStorage.setItem('user',JSON.stringify(userLogin));
      self.setState({isReadOnly: true,haveError: false});
      }else{
        self.setState({haveError: true});
        
      }
    });
     
  }

   
  }

  handleModify(){
    this.setState({isReadOnly: false});
  }

  handleChange(e) {
    const { name, value } = e.target;
   if (name ==='newsletter'){
    var apiBaseUrl = Constanti.url_Backend+"newsLetter";
    var payload= {
        "user_id": this.state.user.id,
        "newsletter":e.target.checked
    }
    var self = this;
    axios.post(apiBaseUrl, payload).then(function (response) {
      var userLogin= response.data.data;
      if (userLogin !== 'KO' && userLogin !== undefined){
        sessionStorage.setItem('user',JSON.stringify(userLogin))
        self.setState({user: userLogin });
      }
    }).catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload 
      };
    });
  }else{
    this.setState({ [name] : value });
  }
}



onCancelDelete(){
  this.setState({
      alert: false
  });
}

handleOpenModal(){
  this.setState({
    alert: true
});
}

handleCancelAccount(){
  var apiBaseUrl = Constanti.url_Backend+"eliminaUtente";
  var payload= {
      "id": this.state.user.id,
  }
  var self = this;
  axios.post(apiBaseUrl, payload).then(function (response) {
   
    var userLogin= response.data.data;
    if (userLogin !== 'OK'){
    sessionStorage.removeItem('user');
    this.props.navigate('/');
    this.props.navigate(0);
    sessionStorage.clear();
    this.context.setUser(null);
    }else{
      self.setState({haveError: true});
      
    }
  });
}

validateInput = e => {
  let { name, value } = e.target;
 
 
   switch (name) {
    case "oldPassword":
      if (!value) {
        this.setState({errorOldPwd : "Please enter Password."});
      } else {
        this.setState({errorOldPwd : this.state.confirmPassword ? "" : this.state.confirmPassword});
      }
      break;

      case "newPassword":
        if (!value) {
          this.setState({errorPwd : "Please enter Password."});
        } else if (this.state.confirmPassword && value !== this.state.confirmPassword) {
          this.setState({errorConfirm : "Password and Confirm Password does not match."});
        } else if (this.state.newPassword.length <8) {
          this.setState({errorPwd : "Password and Confirm Password does not match."});
        } else {
          this.setState({errorConfirm : this.state.confirmPassword ? "" : this.state.confirmPassword});
        }
        break;
 
      case "confirmPassword":
        if (!value) {
          this.setState({errorConfirm : "Please enter Confirm Password."});
          
        } else if (this.state.newPassword && value !== this.state.newPassword) {
          this.setState({errorConfirm : "Password and Confirm Password does not match."});
          
        }else{
          this.setState({errorConfirm : this.state.confirmPassword ? "" : this.state.confirmPassword});
        }
        break;
 
      default:
        break;
    }
 };
  
 componentWillMount() {
  var u = JSON.parse(sessionStorage.getItem('user'));
  
if (u != null){
  this.setState({ emailAccount: u.email,user:u});
}
}


  render(){ 
    const {isReadOnly,submitted,haveError,passwordAccount,emailAccount,newPassword,confirmPassword,errorPwd,errorConfirm,oldPassword,errorOldPwd,alert} = this.state;
    const {t,i18n} = this.props;
    return (
      <>
         {alert?
        <SweetAlert
            custom
            showCancel
            showCloseButton
            confirmBtnText={t('continua')}
            cancelBtnText={t('undo')}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="secondary"
            title={t('popupname')}
            onConfirm={this.handleCancelAccount}
            onCancel={this.onCancelDelete}
            >{t('deleteAccount')}</SweetAlert>
            : <span></span>
            }
            <div data-w-tab="Account" className="w-tab-pane w--tab-active">
                  <h2 className="h2 nomargintop">{t('account')}</h2>
                  <div className="form-area-personale margin48 w-form">
                    <form id="account-form" name="email-form" data-name="Email Form" method="get" className="form-section">
                      <h5 className="h5 margin24">{t('accesso')}</h5>
                      <div className="input-field-wrapper wrap"><label className="field-label _100">Email</label><input type="email" className="inputfield-plain grow w-input" maxLength="256"  name="emailAccount" value={emailAccount} data-name="Email" placeholder="miaemail@gmail.com" id="Email"/>
                      </div>
                      {isReadOnly ? ( 
                      <div className="input-field-wrapper wrap"><label className="field-label _100">Password</label><input type="password" className="inputfield-plain grow w-input" maxLength="256" name="passwordAccount" value={passwordAccount} data-name="Password" placeholder="*********" id="Password"/>
                        <a href="#" className="button-tertiary w-button" onClick={this.handleModify}>{t('modifica')}</a>
                      </div>
                      ):(
                        <>  
                        <div className="input-field-wrapper wrap">
                        <a href="#" className="button-tertiary w-button" onClick={this.handleModify}>{t('undo')}</a>
                      </div>                      
                        <div className="input-field-wrapper wrap"><label className="field-label _100">{t('attualePassword')}</label><input  className="inputfield-plain grow w-input" maxLength="256" value={oldPassword} name="oldPassword"  onChange={this.handleChange}  placeholder="*********" id="Password_old"/>
                        {errorOldPwd && <div className="input-field-wrapper wrap"><span className='err'>{errorOldPwd}</span></div>} 
                      </div>
                      <div className="input-field-wrapper wrap"><label className="field-label _100">{t('newPassword')}</label><input className="inputfield-plain grow w-input" maxLength="256" name="newPassword" value={newPassword} onBlur={this.validateInput} onChange={this.handleChange}  placeholder="*********" id="Password_new"/>
                      {errorPwd && <div className="input-field-wrapper wrap"><span className='err'>{errorPwd}</span></div>}  
                      </div> 
                         <div className="input-field-wrapper wrap"><label className="field-label _100">{t('confPassword')}</label><input  className="inputfield-plain grow w-input" maxLength="256" name="confirmPassword" value={confirmPassword} onBlur={this.validateInput} onChange={this.handleChange}  placeholder="****************" id="Password_conf"/>
                         {errorConfirm && <div className="input-field-wrapper wrap"><span className='err'>{errorConfirm}</span></div>}
                      </div>
                      {!isReadOnly &&
                    <button type="button" className="buttonProfile" onClick={this.hanldeSave}>{t('salva')}</button>
                    }
                    {submitted && !haveError &&
                    <div className="w-form-done">
                    <div>{t('submission.ok')}</div>
                    </div>
                    }
                    {submitted && haveError &&
                    <div className="w-form-fail">
                    <div>{t('submission.ko')}</div>
                    </div>
                    }
                        </>
                      )
                    }
                    </form>
                  </div>
                  <div className="form-area-personale margin48 w-form">
                  
                      <h5 className="h5 margin24">{t('sottoscrizioni')}</h5><label className="w-checkbox checkbox-field">
                        <div className="field-label _100">{t('newsletter')}</div><input type="checkbox"   defaultChecked={this.state.user.newsletter} id="checkbox" name="newsletter" onChange={this.handleChange} data-name="Checkbox" className="w-checkbox-input checkbox"/><span className="checkbox-label w-form-label" >{t('testoNewsletter')}</span>
                      </label>
                  
                  </div>
                  <div className="form-area-personale">
                    <h5 className="h5 margin24">{t('gestione')}</h5>
                    <div className="elimina-account-wrapper w-inline-block cursorPointer" onClick={this.handleOpenModal}>
                      <div>{t('deleteAccount')}</div>
                      <div><img src={arrow} loading="lazy" width="24" alt=""/></div>
                    </div>
                  </div>
                </div>
                
        </>
  );
}
}
export default withTranslation()((props) => (
  <Account
      {...props}
      params={useParams()}
      navigate={useNavigate()}
  />
)); 