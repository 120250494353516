import React, { Component } from "react";
import ReactMarkdown from 'react-markdown';
import * as Constanti from '../../Utils/constants.js';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../style/tour.css';
import { Col, Modal, Row,Button } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import clock from '../../images/clock.png';
import globo from '../../images/globe.png';
import visiva from '../../images/disabilità_visiva.png';
import cognitiva from '../../images/cognitiva.png'
import uditiva from '../../images/uditiva.png'
import motoria from '../../images/motoria.png'
import hotel from '../../images/hotelIcon.png'
import arrow from '../../images/arrow.png'
import BoxPrenotazioni from "../tours/boxPrenotazioni.js";
import CardItinerario from "../molecule/card-itinerario.js";
import close from '../../images/ximage.png';
import Pdf from '../../documents/condizioni.pdf';

class CorpoMulty
 extends Component{
    constructor(props){
    super(props);
    this.state = {
        numeroGiorni:0,
        showMobileModalMulty:false,
    }
    }
    showModalChange = () =>{
        this.setState({showMobileModalMulty:true}); 
     }
 
     handleCloseFilter= () =>{
         this.setState({showMobileModalMulty: false});
       }
     

    componentDidMount() {
      var count = 2;
      if (this.props.tour != undefined){
      if (this.props.tour.titolo_3 != undefined)
      count++;
    if (this.props.tour.titolo_4 != undefined)
    count++;
   
    if (this.props.tour.titolo_5 != undefined)
        count++;
    
    if (this.props.tour.titolo_6 != undefined)
        count++;
    }
    this.setState({numeroGiorni: count})
    
      }
    
    
  render(){
    const {numeroGiorni,showMobileModalMulty} = this.state;
    const {t,i18n,destinazione } = this.props;
    return (
        <>
        {this.props.tour != undefined && this.props.tour != null &&
            <div className="contenitoreCorpoTour">
        <div className="tour-displayFlex">
        <div className="testoTour">
																																														 
            <div className="titoloTour">
            {i18n.language === 'it'? this.props.tour.titolo : this.props.tour.titolo_en}
            </div>
            
            <div className="durataTour marginTop30">
             <img src={clock} width="20" height="20" className="marginRight8"></img> <span className="subtitleTour">{i18n.language === 'it'? this.props.tour.durata : this.props.tour.durata_en}</span> 
            </div>
            <div className="lingueTour marginTop30">
            <img src={globo} width="20" height="20" className="marginRight8"></img> <span className="subtitleTour">{i18n.language === 'it'? this.props.tour.lingua : this.props.tour.lingua_en}</span>
            </div>
            <Row className="descrizionetour">
            <ReactMarkdown>{i18n.language === 'it'? this.props.tour.descrizione : this.props.tour.descrizione_en}</ReactMarkdown>
            </Row>
            <Row className="rowAccessibilit">
                <div className="titleAccessibilit">{t("accessibilita")}</div>
                <div className="subtitleAccessibilit">{t("subaccessibilita")}</div>
                {this.props.tour.isVisiva == 1 &&
                <Col xs={6} className="marginTop10">
                <img src={visiva} height="24px"  className="imgButton"/>{t("dvisiva")}
                </Col>
                }
                {this.props.tour.isUditiva == 1 &&
                <Col xs={6} className="marginTop10">
                <img src={uditiva} height="24px" width= "23px"  className="imgButton"/>{t("duditiva")}
                </Col>
                }
                {this.props.tour.isMotoria == 1 &&
                <Col xs={6} className="marginTop10">
                <img src={motoria} height="24px" width= "23px"  className="imgButton"/>{t("motoria")}
                </Col>
                }
                {this.props.tour.isCognitiva == 1 &&
                <Col xs={6} className="marginTop10">
                <img src={cognitiva} height="24px" width= "23px"  className="imgButton"/>{t("dcognitiva")}
                </Col>
                }

            </Row>
            <Row className="rowAccessibilit">
                <div className="titleAccessibilit">{t("itinerario")}</div>
                <CardItinerario tour={this.props.tour} key={this.props.tour} numero={1}></CardItinerario>
                <CardItinerario tour={this.props.tour} key={this.props.tour} numero={2}></CardItinerario>
                {this.props.tour.titolo_3 != undefined &&
                    <CardItinerario tour={this.props.tour} key={this.props.tour} numero={3}></CardItinerario>
                }
                {this.props.tour.titolo_4 != undefined &&
                <CardItinerario tour={this.props.tour} key={this.props.tour} numero={4}></CardItinerario>
                }
                {this.props.tour.titolo_5 != undefined &&
                <CardItinerario tour={this.props.tour} key={this.props.tour} numero={5}></CardItinerario>
                }
                {this.props.tour.titolo_6 != undefined &&
                <CardItinerario tour={this.props.tour} key={this.props.tour} numero={6}></CardItinerario>
                }
                {this.props.tour.titolo_7 != undefined &&
                <CardItinerario tour={this.props.tour} key={this.props.tour} numero={7}></CardItinerario>
                }
            </Row>
            <Row className="rowAccessibilit">
                <div className="titleAccessibilit">{t("disponibilita")}</div>
                <div className="subtitleAccessibilit">{i18n.language === 'it'? this.props.tour.disponibilita : this.props.tour.disponibilita_en}</div>
            </Row>
            <Row className="rowAccessibilit">
                <div className="titleAccessibilit">{t("serviziInclusi")}</div>
                <div className="subtitleAccessibilit">{i18n.language === 'it'? this.props.tour.servizi_inclusi : this.props.tour.servizi_inclusi_en}</div>
            </Row>
            <Row className="rowAccessibilit">
                <div className="titleAccessibilit">{t("serviziNonInclusi")}</div>
                <div className="subtitleAccessibilit">{i18n.language === 'it'? this.props.tour.servizi_non_inclusi : this.props.tour.servizi_non_inclusi_en}</div>
            </Row>
            <hr className="solid"></hr>
            <Row className="rowAccessibilit">
                <div className="titleAccessibilit">{t("terminiCancellazione")}</div>
                <div className="subtitleAccessibilit">{t('termCondition')}</div>
                <div className="button-row">
                <a href = {Pdf} target = "_blank"><button className="moreInfo-button">{t("moreInfo.label")}<img src={arrow}></img></button></a>
                </div>
            </Row>

        </div>
        <Col xs={1}></Col>
        <div className="prenotazioniTour">
            <BoxPrenotazioni prezzo={this.props.tour.costo} key={this.props.tour.id} giorni={numeroGiorni} oggetto={this.props.tour}></BoxPrenotazioni>
        </div>
	   </div>
	</div>          
		}
        <Modal  show={showMobileModalMulty} className="modalFilter"
        onHide={this.handleCloseFilter} style={{paddingLeft:"6px",marginTop:"16px"}}>
        <Modal.Body>
        <Row className="alighRight">
            <button  onClick={this.handleCloseFilter} className="buttonSlider text-right"><img className="prev-slick-arrow" src={close}></img></button>
        </Row>
        <BoxPrenotazioni prezzo={this.props.tour?.costo} key={this.props.tour?.id} giorni={numeroGiorni} oggetto={this.props.tour}></BoxPrenotazioni>
        <div style={{width:"100%",height:"80px"}}></div>
        </Modal.Body>
        </Modal>

        <div className="prenotazioniTourMobile">
        <div className="d-flex justify-content-between bottomCard">
        <div className="prezzoBoxPrenotazioni">
         <div className="riga">
          <span className="spanPrice">{t("da")}</span> <span class="cifra"> € {this.props.tour?.costo}</span>
        </div>
          <div className="subtitlePrezzo">  
            {t("subtitlePrezzo")}
            </div>
            
         </div>
        <div  className="buttonContainerMobile">
        <button className="btn-prenotazione" onClick={this.showModalChange}> {t("richiediPrenotazione")}</button>
        </div>
        </div>
            
        </div>  
</>
    )
}
}
export default  withTranslation()(CorpoMulty); 
