import React, { Component } from "react";
import '../../style/home.css';
import { withTranslation } from 'react-i18next';
import { Col, Row } from "react-bootstrap";
import logo from '../../images/logoBianco.png';
import fb from '../../images/Facebook.png';
import linkedin from '../../images/Linkedin.png';
import google from '../../images/Instagram.png';
import twitter from '../../images/Twitter.png';
import { Link } from "react-router-dom";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import Pdf from '../../documents/condizioni.pdf';

class Footer extends Component{
  constructor(props){
    super(props);
  
    this.state = {
      email:"",
      showError:false,
      showOK:false
      
};
    this.handleChange = this.handleChange.bind(this);
    this.sendNewsletter = this.sendNewsletter.bind(this);
    
    
  };

  sendNewsletter(){
    if (this.state.email !== ""){
      var apiBaseUrl = Constanti.url_Backend+"newsLetterSite";
      var payload= {
          "email": this.state.email
      }
      var self = this;
      axios.post(apiBaseUrl, payload).then(function (response) {
        var result= response.data.data;
        if (result === 'KO'){
          self.setState({showError:true});
          self.setState({showOK:false});
          }else{
            self.setState({showOK:true});
            self.setState({showError:false});
          }
       
       });
    }
  }
   
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name] : value });
    this.setState({
     alert: null
 });
}

  render(){
    const {email,showError,showOK} = this.state;
    const { t,i18n } = this.props;
    return (
        <>
         <div className="footer">
        <div className="containerFooter">
        <Row style={{ height: '32px'}}>
        </Row>
        <div className="flexColum marginTop30 justify-content-between footerMobile">
      
        <div>
        <img src={logo} alt='cosy' width="151" height="45" className="logoFooter"/>
        </div>
        <div className="flexColum justify-content-between footerMobileLink" >
        <div className="marginRight60">
        <div className="subtitle-footer"><Link to="/destinazioni"  onClick={() => this.handleClick(4)}> {t("destinazioni.label")}</Link></div>
        <div className="subtitle-footer"><Link to="/virtualTour"  onClick={() => this.handleClick(4)}>{t("virtualtour.label")}</Link></div>
        <div className="subtitle-footer"><Link to="/chiSiamo"  onClick={() => this.handleClick(4)}>{t("chisiamo.label")}</Link></div>    
        </div>
        <div>
        <div className="voidrow">void</div>
        <div className="subtitle-footer"><Link to="/blog" user={this.state.utente} onClick={() => this.handleClick(4)}>{t("blog.label")}</Link></div>
        <div className="subtitle-footer"><Link to="/contatti" user={this.state.utente} onClick={() => this.handleClick(4)}>{t("contatti.label")}</Link></div>
       
        </div>
        </div>
        <div>
        <div className="text-newsletter-footer">{t("footer.newsletter")}</div>
          <div className="subtitle-newsletter-footer">{t("footer.newsletter.subtitle")}</div>
          <input id="MERGE0" className="form-control-detail input-footer" style={{width:'320px',height:'48px'}} placeholder="  La tua email" type="text" name="email" value={email}  onChange={this.handleChange}/>
          <button id="mc-embedded-subscribe" type="submit" className="btn-block btn-newsletter" onClick = {this.sendNewsletter}> {t("iscriviti")}  </button>
          {showError &&
            <div className="subtitle-newsletter-footer">{t('newsletterErrorSend')}</div>
          }
          {showOK  &&
            <div className="subtitle-newsletter-footer">{t('newsletterOKSend')}</div>
          }
        </div>
         </div>

         <div className="marginTop30 footerTablet">
      
      <div>
      <img src={logo} alt='cosy' width="151" height="45" className="logoFooter"/>
      </div>
      <div className="flexRow marginTop30">
      <div className="flexColum justify-content-between footerMobileLink" >
      <div className="marginRight60">
      <div className="subtitle-footer"><Link to="/destinazioni"  onClick={() => this.handleClick(4)}> {t("destinazioni.label")}</Link></div>
      <div className="subtitle-footer"><Link to="/virtualTour"  onClick={() => this.handleClick(4)}>{t("virtualtour.label")}</Link></div>
      <div className="subtitle-footer"><Link to="/chiSiamo"  onClick={() => this.handleClick(4)}>{t("chisiamo.label")}</Link></div>    
      </div>
      </div>
      <div>
      <div className="voidrow">void</div>
      <div className="subtitle-footer"><Link to="/blog" user={this.state.utente} onClick={() => this.handleClick(4)}>{t("blog.label")}</Link></div>
      <div className="subtitle-footer"><Link to="/contatti" user={this.state.utente} onClick={() => this.handleClick(4)}>{t("contatti.label")}</Link></div>
    
      
      </div>
      <div>
      <div className="text-newsletter-footer">{t("footer.newsletter")}</div>
        <div className="subtitle-newsletter-footer">{t("footer.newsletter.subtitle")}</div>
        <input className="form-control-detail input-footer" style={{width:'320px',height:'48px'}} placeholder="  La tua email" type="text" name="email" value={email}  onChange={this.handleChange}/>
        <button type="submit" className="btn-block btn-newsletter" onClick = {this.sendNewsletter}> {t("iscriviti")}  </button>
        {showError &&
          <div className="subtitle-newsletter-footer">{t('newsletterErrorSend')}</div>
        }
        {showOK  &&
          <div className="subtitle-newsletter-footer">{t('newsletterOKSend')}</div>
        }
      </div>
      </div>
</div>

         <Row style={{ height: '32px'}}>
        </Row>
        <div className="separator"></div>
        <div className="flexColum marginTop30 footerMobile">
        
        <div className="testo-subfooter testo-coopiright ">Copyright 2023 Cosyforyou</div>
        <div className="flexColum justify-content-between footerMobile linkFooterWidth" >
        <div className="testo-subfooter marginRight32"><Link to="/destinazioni" user={this.state.utente} onClick={() => this.handleClick(4)}>Privacy Policy</Link></div>
        <div className="testo-subfooter marginRight32"><a href = {Pdf} target = "_blank">Terms & Conditions</a></div>
        <div className="testo-subfooter"><Link to="/destinazioni" user={this.state.utente} onClick={() => this.handleClick(4)}>Cookie Policy</Link></div>
        </div>
        <div className="linkFooterSocialWidth">
        <Link to="/destinazioni" style={{display:'none'}} user={this.state.utente} onClick={() => this.handleClick(4)}><img src={twitter} alt='cosy'  className="logoFooterIcon"/></Link>
        <a href="https://www.instagram.com/cosyfair/" ><img src={linkedin} alt='cosy' className="logoFooterIcon"/></a>
        <Link to="/destinazioni" style={{display:'none'}} user={this.state.utente} onClick={() => this.handleClick(4)}><img src={google} alt='cosy'  className="logoFooterIcon"/></Link>
        <a href="https://www.facebook.com/cosyfair/" ><img src={fb} alt='cosy'  className="logoFooterIcon"/></a>
        </div>
       </div>
       <div className="marginTop30 footerTablet">
        
       <div className="flexRow">
        <div className="flexRow justify-content-between linkFooterWidth" >
        <div className="testo-subfooter marginRight32"><Link to="/destinazioni" user={this.state.utente} onClick={() => this.handleClick(4)}>Privacy Policy</Link></div>
        <div className="testo-subfooter marginRight32"><a href = {Pdf} target = "_blank">Terms & Conditions</a></div>
        <div className="testo-subfooter"><Link to="/destinazioni" user={this.state.utente} onClick={() => this.handleClick(4)}>Cookie Policy</Link></div>
        </div>
        <div className="linkFooterSocialWidth">
        <Link style={{display:'none'}} to="/destinazioni" user={this.state.utente} onClick={() => this.handleClick(4)}><img src={twitter} alt='cosy'  className="logoFooterIcon"/></Link>
        <a href="https://www.instagram.com/cosyfair/" ><img src={linkedin} alt='cosy' className="logoFooterIcon"/></a>
        <Link style={{display:'none'}} to="/destinazioni" user={this.state.utente} onClick={() => this.handleClick(4)}><img src={google} alt='cosy'  className="logoFooterIcon"/></Link>
      
        </div>
        </div>  <a href="https://www.facebook.com/cosyfair/" ><img src={fb} alt='cosy'  className="logoFooterIcon"/></a>
        <div className="testo-subfooter testo-coopiright ">Copyright 2023 Cosyforyou</div>
        </div>
       
       
      


        </div>
        </div>
        
</>
    )
}
}
export default withTranslation()(Footer); 
