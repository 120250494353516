import { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import '../../style/blog.css';

class HeaderLink
 extends Component{
    constructor(props){
        super(props);
   }
    
    render(){
        const {t,i18n,categorie,selected} = this.props;
         return (
            <>
         <div className="container">
                  <div className="content_wrapper flex wrap">
                      <h3 className="artRecent">{t('articoli.recenti')}</h3>
                      <div className="categories-wrapper">
                   <Link to="/blog" className={"category-link w-inline-block " + (selected == 0 ? "selectedCategory":"")}><div>{t('tutti')}</div></Link>   
                   {categorie?.map((option) => 
                    <Link to={"/blog/category/"+option.id} key={option.id} className={"category-link w-inline-block " + (selected == option.id  ? "selectedCategory":"")}><div>{i18n.language === 'it'? option.categoria:option.categoria_en}</div></Link>
                       
                    )}
                  
                
                    </div>
                </div>
                </div>
        
               
  </>
    )
}
}


    export default withTranslation()(HeaderLink); 