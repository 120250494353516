import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import CardItinerarioPrenotazione from "../molecule/card-itinerarioPrenotazione";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
import arrow from "../../images/arrow-right.png"
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert/dist/components/SweetAlert";
import ButtonMailto from "../molecule/buttonMailto.js";
import close from '../../images/ximage.png';

class DettaglioViaggio
 extends Component{
 
    constructor(props){
    super(props);
    this.state = {
      viaggio:null, 
      tour:null,
      alertViaggio:false,
      commenti:"",
    }
   this.showModal= this.showModal.bind(this);
   this.handleCancelViaggio = this.handleCancelViaggio.bind(this);
   this.onCancelDelete= this.onCancelDelete.bind(this);
   this.handleChange = this.handleChange.bind(this);
    }


  showModal(){
    this.setState({
      alertViaggio: true
  });
    }
 
    componentWillMount() {
      
      console.log(this.props.location);
      if (this.props.location.state.viaggio != null){
        this.setState({viaggio:this.props.location.state.viaggio});  
        if (this.props.location.state.viaggio.multy_days_trips_id == null){
          this.setState({tour:this.props.location.state.viaggio.tour});    
        }else{
          this.setState({tour:this.props.location.state.viaggio.multy_days});
        }
       }
   }

   handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name] : value });
   
}

   onCancelDelete(){
    this.setState({
      alertViaggio: false
    });
  }

  handleCancelViaggio(){
    var apiBaseUrl = Constanti.url_Backend+"richiestaCancellazioneViaggio";
    var payload= {
        "id": this.state.viaggio.id,
    }
    var self = this;
    axios.post(apiBaseUrl, payload).then(function (response) {
     
      var prenotazione= response.data.data;
    
      self.props.navigate('/profilo/viaggi');
    });
  }
  
 
    
 render(){
    const {tour,viaggio,alertViaggio,commenti} = this.state;
    const {t,i18n,user} = this.props;
    return (
        <>
         {alertViaggio &&
        <Modal  show={alertViaggio} className="modalCancellazione" onHide={this.onCancelDelete}>
        <Modal.Body>
        <Row className="alighRight">
            <button  onClick={this.onCancelDelete} className="buttonSlider text-right"><img className="prev-slick-arrow" alt="Close" src={close}></img></button>
            </Row>
           <div className="corpoModalCancellazione">
              <div className="marginBottom8 d-flex flex-column">
              <span className="titoloModalCancellazione">{t('cancellaViaggio')}</span>
              <span className="subtitleModalCancellazione">{t('motivoCancellazione')}</span>   
              <textarea maxLength="500" className="textAreaAccess"  rows="4" cols="50" name="commenti" value={commenti} onChange={this.handleChange}></textarea>
          </div>
          <div className="marginTop16 d-flex justify-content-end">
          <Button className="buttonAnnullaModal marginRight16" onClick={this.onCancelDelete}>
          {t("undo")}
          </Button>
          <Button className="buttonSalvaModal" onClick={this.handleCancelViaggio }>
            {t("help2")}
          </Button>
          </div>
        </div>
       
        </Modal.Body>
        </Modal>
         }
        <div className="contenitorePaginaDettaglio flexColum marginBottom160">
         <div className="contentRiepilogo">
        <div className="contenuto">
        <label className="labelWizardRiepilogo">{t('numeroprenotazione') + " "+ viaggio?.id}</label>
         <div className="titoloBoxPrenotazioni marginBottom40">
            {t("title.wizard.riepilogo")}
         </div>
       
         {viaggio?.multy_days_trips_id == null ? (
            <Row>
         <label className="labelWizardRiepilogo">{moment(viaggio?.start_date).format('DD MMM, YYYY')}</label>
         <label className="labelWizardRiepilogo">{viaggio?.n_adulti+viaggio?.numBambini03+viaggio?.numBambini410+viaggio?.numBambini1117} {t('viaggiatori')} {viaggio?.n_adulti_disabili>0 || viaggio?.n_bambini_disabili >0 ? '('+(viaggio?.n_adulti_disabili+viaggio?.n_bambini_disabili)+' '+t('viaggiatori.disabili')+')'  : ''}</label>
         </Row>
         ):(
            <Row>
          <label className="labelWizardRiepilogo">{moment(viaggio?.start_date).format('DD MMM, YYYY')} - {moment(viaggio?.end_date).format('DD MMM, YYYY')}</label>
          <label className="labelWizardRiepilogo">{viaggio?.n_adulti+viaggio?.numBambini03+viaggio?.numBambini410+viaggio?.numBambini1117} {t('viaggiatori')} {viaggio?.n_adulti_disabili>0 || viaggio?.n_bambini_disabili >0 ? '('+(viaggio?.n_adulti_disabili+viaggio?.n_bambini_disabili)+' '+t('viaggiatori.disabili')+')'  : ''}</label>
          <label className="labelWizardRiepilogo">{viaggio?.n_camera>1 ? viaggio?.n_camera+" " + t('camere') : viaggio?.n_camera+" " + t('camera')}</label>
          <label className="labelWizardRiepilogo">{viaggio?.n_camere_acc>1 ? viaggio?.n_camere_acc+ " "+ t('camere.acc'): viaggio?.n_camere_acc+ " "+ t('camera.acc')}</label>
          </Row>
         )}

         <Row>
         <Col xs={4}>
           <img src={viaggio?.gallery[0].url} className="imgItinerario imgDettaglioViaggio"></img>
         </Col>
         <Col xs={8}>
         <div className="titleItinerario" style={{marginTop:"10px !important"}}>{i18n.language === 'it' ? tour?.titolo :tour?.titolo_en}</div>
         <div className="subtitleAccessibilit">{i18n.language === 'it'? tour?.descrizione.substring(0,200) : tour?.descrizione_en.substring(0,200)}...</div>
         <div className="d-flex justify-content-between bottomCard marginTop24">
              <p className="card-text-price">{t("da")} <b>{tour?.costo}€</b>  p.p.</p>
              <p className="card-text-price">{tour?.durata} </p>
              </div>
         </Col>
         </Row>
         <hr className="solid"></hr>
        <Row>
        <div className="titoloBoxPrenotazioni marginBottom40">
            {t("informazioni.viaggio")}
         </div>
        </Row>
        {(viaggio.note == null || viaggio.note =='') &&  viaggio?.multy_days_trips_id &&
        <div >
        {viaggio != undefined && 
        <>
        <CardItinerarioPrenotazione tour={viaggio.multy_days} key={viaggio.multy_days} numero={1}></CardItinerarioPrenotazione>
        <CardItinerarioPrenotazione tour={viaggio.multy_days} key={viaggio.multy_days} numero={2}></CardItinerarioPrenotazione>
        </>
        }
        {viaggio.multy_days?.titolo_3 != undefined &&
                    <CardItinerarioPrenotazione tour={viaggio.multy_days} key={viaggio.multy_days} numero={3}></CardItinerarioPrenotazione>
        }
        {viaggio.multy_days?.titolo_4 != undefined &&
        <CardItinerarioPrenotazione tour={viaggio.multy_days} key={viaggio.multy_days} numero={4}></CardItinerarioPrenotazione>
        }
        {viaggio.multy_days?.titolo_5 != undefined &&
        <CardItinerarioPrenotazione tour={viaggio.multy_days} key={viaggio.multy_days} numero={5}></CardItinerarioPrenotazione>
        }
        {viaggio.multy_days?.titolo_6 != undefined &&
        <CardItinerarioPrenotazione tour={viaggio.multy_days} key={viaggio.multy_days} numero={6}></CardItinerarioPrenotazione>
        }
        </div>
        }
        {((viaggio.note == null || viaggio.note =='') && viaggio?.multy_days_trips_id== null && 
            <div >
            <ReactMarkdown>{i18n.language === 'it'? viaggio.tour.itinerario : viaggio.tour.itinerario_en}</ReactMarkdown>
            </div>
        )}
        {((viaggio.note != null || viaggio.note =='') &&
        <div className="itinerary-wrapper">
        <ReactMarkdown>{i18n.language === 'it'? viaggio.note: viaggio.note_en}</ReactMarkdown>
        </div>
        )}
        <hr className="solid"></hr>
        <Row>
        <div className="titoloBoxPrenotazioni marginBottom24 itinerary-wrapper">
            {t("dettaglio.prezzo")}
         </div>
        </Row>
         <div className="d-flex justify-content-between">
         
         {viaggio.stato_prenotazione_id ==2 &&
          <>
          <span className="totale"> {t("totale.stimato")}</span>
         <span className="totale fontWeight700">€ {viaggio.prezzo_stimato}</span>
         </>
        } 
        {viaggio.stato_prenotazione_id !=2 &&
          <>
         <span className="totale"> {t("totale")}</span>
         <span className="totale fontWeight700">€ {viaggio.costo}</span>
         </>
        } 
      
         </div>
        </div>
        </div>
        {viaggio.stato_prenotazione_id != 5 &&
        <div className="side-box contenuto">
        <div className="text24 bold marginBottom20">{t('helpdetail')}</div>
        <div className="d-flex justify-content-between">
        <div className="text16 bold ">{t('help1')}</div>
        <ButtonMailto className="text-link" label={<img src={arrow} loading="lazy" width="24" alt=""/>} mailto="mailto:info@campaniaforall.com" ><img src={arrow} loading="lazy" width="24" alt=""/></ButtonMailto>
        </div>
        <hr className="solid"></hr>
        <div className="d-flex justify-content-between cursorPointer" onClick={this.showModal}>
        <div className="text16 bold ">{t('help2')}</div>
        <div><img src={arrow} loading="lazy" width="24" alt=""/></div>
        </div>
        </div>
        }
        </div>
        
    
</>
    )
}
}

export default  withTranslation()((props) => (
  <DettaglioViaggio
      {...props}
      params={useParams()}
      navigate={useNavigate()}
      location={useLocation()}
  />
)); 