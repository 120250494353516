import React, { Component } from "react";
import '../../style/modal.css';
import { withTranslation } from 'react-i18next';
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { UserContext } from "../context/UserContext";


class ResetPwd
 extends Component{
  static contextType = UserContext;
    constructor(props){
    super(props);

    this.state = {
        id_user:null,
        pwd: "",
        conferma:"",
        showError:false,
        showOk:false,
        submittedRegister:false
    }
 
  this.handleChange = this.handleChange.bind(this);
  this.sendNewPassword = this.sendNewPassword.bind(this);
  
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name] : value });
       
    }
    sendNewPassword(){
      this.setState({submittedRegister:true});
      if (this.state.pwd !== "" && this.state.conferma !== "" && this.state.conferma === this.state.pwd ){
        var apiBaseUrl = Constanti.url_Backend+"resetPwdWeb";
        var payload= {
            "user_id": this.state.id_user,
            "password" : this.state.pwd
        }
        console.log(payload);
        var self = this;
        axios.post(apiBaseUrl, payload).then(function (response) {
          var result= response.data.data;
          if (result === 'KO'){
            self.setState({showError:true});
            }
            else{
              self.setState({showOk:true});
            }
         
         });
      }
    
   
    }

    componentDidMount() {
      const searchParams = new URLSearchParams(document.location.search);
      this.setState({id_user:searchParams.get('id')});
    }
  
    
  render(){
        const {pwd,conferma,showError,submittedRegister,showOk} = this.state;
    const {t} = this.props;
    return (
        
        <div className="containerReset">
          <div className="paddingBottom10">
            <div className="bloccoForm" >
            <div className="marginTop10 marginBottom20 titoloModalAccedi flex-column-center"><span className="title-modal"> {t("resetPwd")}</span></div>
            <div className="marginTop10 marginBottom20 subtitleResetPwd flex-column-center"><span className="subtitleResetPwd"> {t("subresetPwd")}</span></div>
            <div className="inputModalDiv">
            <label className="labelModalRegistrazione">{t("password")}</label>
            <input onChange={this.handleChange}  className="box-prenotazione-input input-modal" name="pwd" value={pwd} placeholder="Inserisci la password"></input>
            {submittedRegister && pwd==='' &&
                            <div className="help-block">{t("passwordRequired")}</div>
            }
            {submittedRegister && pwd.length < 8 &&
              <div className="help-block">{t("lenghtPasswordRequired")}</div>
            }
            <label className="labelModalRegistrazione">{t("confPassword")}</label>
            <input onChange={this.handleChange}  className="box-prenotazione-input input-modal" name="conferma" value={conferma} placeholder="Re-inserisci la password"></input>
            {submittedRegister && pwd !== conferma &&
              <div className="help-block">{t("confirmNotGood")}</div>
            }
            <button className="btn-login" onClick={this.sendNewPassword}> {t("salva")}</button>
            {showError &&
              <div className="help-block">{t("errorServer")}</div>
            }
            {showOk &&
              <div className="help-block">{t("messageConfirmed")}</div>
            }
            </div>
            </div>
            
            </div>
            </div>
  
    )
}
}
export default  withTranslation()(
  ResetPwd
); 
