import React, { Component } from "react";
import '../../style/modal.css';
import { Col, Modal, Row,Button } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import imgRegistrati from '../../images/registratiModal.png';
import close from '../../images/ximage.png';
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router";
import { UserContext } from "../context/UserContext";
import { WithContext as ReactTags } from 'react-tag-input';
import Select from 'react-select';

class ModalSingolaAccessibilita
 extends Component{
  static contextType = UserContext;
    constructor(props){
    super(props);

    this.state = {
      show:this.props.show,
      steps:[
        { id:1,key: 'primoStep',  isDone: true,domanda:"Che tipo di viaggiatore sei?",risposte:['Viaggiatore con disabilità','Accompagnatore di viaggiatore con disabilità'],rispostaUser:null },
        { id:2,key: 'secondoStep', isDone: false,domanda:"Che tipo di disabilità hai o ha il viaggatore che accompagni?",risposte:['Motoria','Sensoriale-Udito','Sensoriale - Vista','Psichico/Intellettiva'],rispostaUser:null},
        { id:3,key: 'terzoStep',  isDone: false,domanda:"Il viaggiatore disabile necessita di un supporto per muoversi? se si quale?",risposte:['Sedia a rotelle','Motorino elettrico','Bastoni','Altro','No'],rispostaUser:null },
        { id:4,key: 'quartoStep',  isDone: false,domanda:"E' di un aiuto aggiuntivo per accompagnare il viaggiatore disabile?",risposte:['Si','No'],rispostaUser:null },
        { id:5,key: 'quintoStep', isDone: false,domanda:"Tu o il viaggiatore che accompagni avete necessità alimentari particolari?",risposte:['Vegetariano/Vegano','Celiaco','Allergia','Diabete','No'],rispostaUser:null },
        { id:6,key: 'sestoStep',  isDone: false,domanda:"Hai necessità di supporti aggiuntivi?",risposte:['Supporti per il bagno','Altro (Animali che provvedano all’assistenza, Respiratore/bombola dell’ossigeno,Protesi, Sedia a rotelle, Sedia motorizzata, Scooter, Bretelle, Stampelle, Deambulatore)','Cintura da sedia a rotelle','Sollevatore','Nessun dispositivo di supporto'],rispostaUser:null },
        { id:7,key: 'settimoStep',  isDone: false,domanda:"Che tipo di viaggiatore sei?",risposte:['Viaggiatore con disabilità','Viaggiatore con limitata mobilità','Accompagnatore di viaggiatore con disabilità'],rispostaUser:null },
      ],
        activeStep:null,
        required:false,
        value:null,
        value_id:null,
        options : [
          { value: 'Napoli', label: 'Napoli' },
          { value: 'Pompei', label: 'Pompei' },
          { value: 'Costiera Amalfitana', label:  'Costiera Amalfitana' },
          { value: 'Capri', label: 'Capri' },
          { value: 'Campi Flegrei', label: 'Campi Flegrei' },
          { value: 'Avellino', label: 'Avellino' },
          { value: 'Caserta', label:  'Caserta' },
          { value: 'Salerno', label: 'Salerno' },
          { value: 'Isole Campane', label: 'Isole Campane' },
          { value: 'Altre destinazioni', label: 'Altre destinazioni' },
        ],
        tags:[],
        user:null,
        feedback:false,
        commenti:''
    }
  this.handleClose =this.handleClose.bind(this);
  this.submit = this.submit.bind(this);
  this.onValueChange = this.onValueChange.bind(this);
  this.saveTag = this.saveTag.bind(this);
  this.saveComment = this.saveComment.bind(this);
  this.handleChange=this.handleChange.bind(this);
  this.handleChangeCheck = this.handleChangeCheck.bind(this);
  }

  onValueChange(event) {
    this.setState({
      value: this.state.activeStep.risposte[event.target.value],value_id:event.target.value
    });
  }

  handleChangeCheck(e){
    const { name } = e.target;
    if (e.target.checked){
      if (this.state.value != null){
      this.setState({
        value: this.state.value + " "+name
      });
    } else{
      this.setState({
        value: " "+name
      });
    }
    }else{
      this.setState({
        value: this.state.value.replace(" "+name,"")
      });
    }
  }

  saveComment(){
    var apiBaseUrl = Constanti.url_Backend+"salvaCommenti";
    var payload= {
        "user_id": this.state.user.id,
        "commenti": this.state.commenti
      }
 
    console.log(payload);
    var self = this;
    axios.post(apiBaseUrl, payload).then(function (response) {
     
      var userLogin= response.data.data;
      if (userLogin !== 'KO'){
       sessionStorage.setItem('user',JSON.stringify(userLogin))
       self.props.update(userLogin);
      }
      self.handleClose();
    });
  }

  saveTag(){
    const stringData = this.state.tags.reduce((result, item) => {
      return `${result}${item.value}, `
    }, "")
    var apiBaseUrl = Constanti.url_Backend+"modificaTags";
    var payload= {
        "user_id": this.state.user.id,
        "tags": stringData.slice(0, -2)
      }
 
    console.log(payload);
    var self = this;
    axios.post(apiBaseUrl, payload).then(function (response) {
     
      var userLogin= response.data.data;
      if (userLogin !== 'KO'){
       sessionStorage.setItem('user',JSON.stringify(userLogin))
       self.props.update(userLogin);
      }
      self.handleClose();
    });
   
  }

  submit (){
    if (this.state.value != null){
     //chiamo il backend e chiudo
     var apiBaseUrl = Constanti.url_Backend+"modificaAccessibilitaUserById";
     var payload= {
         "user_id": this.state.user.id,
         "step_id": this.state.activeStep.id,
         "risposta": this.state.activeStep.id==2 ? this.state.value : this.state.activeStep.risposte[this.state.value_id],
         "risposta_id":this.state.activeStep.id==2 ? null : this.state.value_id,
         
       }
  
     console.log(payload);
     var self = this;
     axios.post(apiBaseUrl, payload).then(function (response) {
      
       var userLogin= response.data.data;
       if (userLogin !== 'KO' && userLogin != undefined){
        sessionStorage.setItem('user',JSON.stringify(userLogin))
        self.props.update(userLogin);
       }else{
    
         
       }
     });
     
     this.handleClose();
    }else{
      this.setState({required: true});
    }
    }

    recuperaAccessibilita(user,acc){
      var risposta = user?.accessibilita?.filter((accessibilita) => accessibilita.n_domanda == acc.id);
      if (risposta !== undefined)
      return risposta[0]?.testo_risposta;
      return "";
    }

    recuperaOption (tags){
      var option =[];
      this.state.options.map((st) =>{
        tags.map((t)=>{
          if (t == st.value){
            option.push(st);
           
          }
        });
      });
      return option;
    }

   componentDidMount= ()=>{
    this.setState({user:JSON.parse(sessionStorage.getItem('user'))})
    var u = JSON.parse(sessionStorage.getItem('user'));
    if (this.props.domanda !== 'destinazioni' && this.props.domanda!=='feedback'){
      const index = this.state.steps.findIndex(x => x.id === this.props.domanda.id);
      this.setState({activeStep:this.state.steps[index]});
      this.setState({value:this.recuperaAccessibilita(JSON.parse(sessionStorage.getItem('user')),this.state.steps[index])});
     
    }else if (this.props.domanda==='feedback'){
      this.setState({feedback:true,commenti:u.commenti});
    }else {
   //   this.setState({tags:this.recuperaOption(u.tags.split(','))});
    }
   }   


   handleDelete = i => {
    let item = this.state.tags.filter((tag, index) => index !== i);
    this.setState({tags:item});
    // setTags();
   };
   
   handleAddition = tag => {
     var isPresent = this.state.suggestions.find((element)=>{
       return element.text == tag.text;
     });
     if (isPresent){
     this.setState({tags:[...this.state.tags, tag]});
     }
   };
   
   handleDrag = (tag, currPos, newPos) => {
     const newTags = this.state.tags.slice();
   
     newTags.splice(currPos, 1);
     newTags.splice(newPos, 0, tag);
   
     // re-render
     this.setState({tags:newTags});
   };
   
   handleTagClick = index => {
     console.log('The tag at index ' + index + ' was clicked');
   };
   
   handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name] : value });
   
}

handleChangeSelect = (tags) => {
  this.setState({ tags });
}
  
    handleClose(){
     this.setState({show:false});
     this.props.close();
    }

  render(){
    const {show,activeStep,feedback,commenti,options,tags} = this.state;
    const {t,i18n } = this.props;
    return (
        <>
        <Modal  show={show} className="modalRegistrati modalAccessibilita" onHide={this.handleClose}>
        <Modal.Body>
        <Row className="alighRight">
            <button  onClick={this.handleClose} className="buttonSlider"><img className="prev-slick-arrow" src={close}></img></button>
        </Row>
        {activeStep != null &&
        <>
       
        <Row className="marginModal">
        <Col xs={12}>
         <label className="domandaWizard marginTop40">{i18n.language === 'it'? activeStep.domanda : activeStep.domanda_en}</label>
         </Col>
         {activeStep?.id != 2 &&
         <div className="flex-column">
         {activeStep?.risposte?.map((option,index) => 
          <div><input type="radio" className="radioWizard"  onChange={this.onValueChange} value={index}  checked={this.state.value == option} />{option}</div>  
          )}
         </div>
        }
         {activeStep?.id == 2 &&
         <div className="flex-column">
          {activeStep?.risposte.map((option,index) => 
          <div><input type="checkbox" key={option.id} defaultChecked={this.state.value != null ? this.state.value.includes(option): false}    onChange={this.handleChangeCheck} className="w-checkbox-input checkbox" name={option}/>{option}</div>  
          )}
        </div>
        }
         </Row>
         {this.state.required &&
          <label className="domandaWizard marginTop20" style={{color:"red"}}>{t('requiredWizardAcc')}</label>
        } 
        <input type="button" value={'Salva'} className="buttonWizard marginTop20" onClick={this.submit} style={{width: "calc(40% - 48px)"}}/>
       </>
        }
      {activeStep == null && !feedback &&
       <>
       <Row className="marginModal">
        <div className="domanda">{t('destinazioni.future')}</div>
                          <div className="content_wrapper flex">
                          <Select
                          isMulti
                          value={tags}
                          name="colors"
                          options={options}
                          onChange={this.handleChangeSelect}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                          </div>
                          </Row>             
                          <input type="button" value={'Salva'} className="buttonWizard" onClick={this.saveTag} style={{width: "calc(40% - 48px)",marginLeft:"40px"}}/>
       </>
      } 
      {activeStep == null && feedback &&
        <>
       <Row className="marginModal">
        <div className="domanda">{t('feedback')}</div>
                          <div className="content_wrapper flex">
                          <textarea maxLength="500" className="textAreaAccess"  rows="4" cols="50" name="commenti" value={commenti} onChange={this.handleChange}></textarea>
                          </div>
                          </Row>             
                          <input type="button" value={'Salva'} className="buttonWizard" onClick={this.saveComment} style={{width: "calc(40% - 48px)",marginLeft:"40px"}}/>
       </>
      }
        </Modal.Body>
        </Modal>
        </>
    )
}
}
export default  withTranslation()((props) => (
  <ModalSingolaAccessibilita
      {...props}
      params={useParams()}
      navigate={useNavigate()}
  />
)); 
