import { Component } from "react";
import '../../style/blog.css';
import NewsletterHome from "../homePage/newsletterHome";
import Footer from "../molecule/footer";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import HeaderLink from "./headerLink";
import { useParams } from "react-router";
import CardArticolo from "./cardArticolo";
import { withTranslation } from "react-i18next";

class BlogCategory
 extends Component{
    constructor(props){
        super(props);
   
        this.state = {
         categorie:[],
         articoli:[],
         categoriaSelezionata:null
        
        }
    }

   
    componentDidUpdate(prevProps){
      
     if(this.props.params!== prevProps.params){ 
      this.setState({categoriaSelezionata: this.state.categorie.find(x => x.id == this.props.params.id)});
      
      if (this.props.params.id !== 'null'){
        var apiBaseUrl = Constanti.url_Backend+'getAllArticoliByCategory';
        var self = this;
        var payload={
          idCategoria : this.props.params.id
        }
        axios.post(apiBaseUrl,payload).then(function (response) {
       
        if (response.data.data != null){
           var articoli = response.data.data;
           self.setState({ articoli : articoli })
        }else{
          console.log("non ci sono location");
        }
        self.setState({load:false});
      })
      .catch(function (error) {
      console.log(error);
      self.setState({load:false});
      });
    }
  }
  }

 
    componentDidMount = () =>  {
        var apiBaseUrl = Constanti.url_Backend+'getAllCategorieArticoli';
        var self = this;
        axios.get(apiBaseUrl).then(function (response) {
       
        if (response.data.data != null){
           var categorie = response.data.data;
           self.setState({ categorie : categorie })
           self.setState({categoriaSelezionata: categorie.find(x => x.id == self.props.params.id)});
        }else{
          console.log("non ci sono location");
        }
        self.setState({load:false});
        
      })
      .catch(function (error) {
      console.log(error);
      self.setState({load:false});
      });
      if (this.props.params.id !== 'null'){
         apiBaseUrl = Constanti.url_Backend+'getAllArticoliByCategory';
         self = this;
        var payload={
          idCategoria : this.props.params.id
        }
        axios.post(apiBaseUrl,payload).then(function (response) {
       
        if (response.data.data != null){
           var articoli = response.data.data;
           self.setState({ articoli : articoli })
        }else{
          console.log("non ci sono location");
        }
        self.setState({load:false});
      })
      .catch(function (error) {
      console.log(error);
      self.setState({load:false});
      });

   
      }
    }
    


    render(){
      const {categoriaSelezionata} = this.state;
      const { i18n} = this.props;
           return (
               <>
                <section className="hero_section blog-category wf-section">
                  <div className="container" style={{height: "100%"}}>
                    <div className="content_wrapper flex vertical">
                      <h2 className="titleCat white">{i18n.language === 'it'? categoriaSelezionata?.categoria:  categoriaSelezionata?.categoria_en}</h2>
                    </div>
                  </div>
                </section>
               <div className="contenitoreBlog" style={{marginTop: "0px"}}>
              
              
               
               <div className="section overflow-hidden wf-section">
              <HeaderLink categorie={this.state.categorie} selected={this.props.params.id} ></HeaderLink>
      </div>
      <div className="content_wrapper flex align-top margintop32">
      {this.state.articoli?.map((articolo) => 
    
        <CardArticolo articolo={articolo} key={articolo.id}></CardArticolo>
    
        )}
    
        </div>
        </div>
        <NewsletterHome></NewsletterHome>
        <Footer></Footer>
        
               
  </>
    )
}
}
export default  withTranslation()((props) => (
  <BlogCategory
      {...props}
      params={useParams()}
  />
)); 

   