import React, { Component, useContext, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import '../../style/prenotazione.css';
import { UserContext } from "../context/UserContext";
import Step from "./step";
import image from "../../images/wizard.png";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";







class WizardAccessibilita
 extends Component{
  constructor(props){
    super(props);
    
  this.state = {
    steps:[
      { id:1,key: 'primoStep',  isDone: true,domanda:"Che tipo di viaggiatore sei?",risposte:['Viaggiatore con disabilità','Accompagnatore di viaggiatore con disabilità'],rispostaUser:null },
      { id:2,key: 'secondoStep', isDone: false,domanda:"Che tipo di disabilità hai o ha il viaggatore che accompagni?",risposte:['Motoria','Sensoriale-Udito','Sensoriale - Vista','Psichico/Intellettiva'],rispostaUser:null},
      { id:3,key: 'terzoStep',  isDone: false,domanda:"Il viaggiatore disabile necessita di un supporto per muoversi? se si quale?",risposte:['Sedia a rotelle','Motorino elettrico','Bastoni','Altro','No'],rispostaUser:null },
      { id:4,key: 'quartoStep',  isDone: false,domanda:"E' di un aiuto aggiuntivo per accompagnare il viaggiatore disabile?",risposte:['Si','No'],rispostaUser:null },
      { id:5,key: 'quintoStep', isDone: false,domanda:"Tu o il viaggiatore che accompagni avete necessità alimentari particolari?",risposte:['Vegetariano/Vegano','Celiaco','Allergia','Diabete','No'],rispostaUser:null },
      { id:6,key: 'sestoStep',  isDone: false,domanda:"Hai necessità di supporti aggiuntivi?",risposte:['Supporti per il bagno','Altro (Animali che provvedano all’assistenza, Respiratore/bombola dell’ossigeno,Protesi, Sedia a rotelle, Sedia motorizzata, Scooter, Bretelle, Stampelle, Deambulatore)','Cintura da sedia a rotelle','Sollevatore','Nessun dispositivo di supporto'],rispostaUser:null },
      { id:7,key: 'settimoStep',  isDone: false,domanda:"",risposte:['',''],rispostaUser:null },
    ],
    activeStep:{},
    show:false
}

  }



   handleNext = (value) => {
   
    
    const index = this.state.steps.findIndex(x => x.key === this.state.activeStep.key);
    let items = [...this.state.steps];
    items[index].rispostaUser = value == '' ? 0 : value;
    items.map((x,j) => {
      if (x.key === this.state.activeStep.key) x.isDone = true;
      return x;
    });
    if (this.state.steps[this.state.steps.length - 1].key === this.state.activeStep.key) {
      var u = JSON.parse(sessionStorage.getItem('user'));
     var arrayPay = [];
      this.state.steps.map((st) =>{
        if (st.id != this.state.steps.length){
        var pay = {
         "n_domanda": st.id,
         "n_risposta":isNaN(st.rispostaUser) ? null : st.rispostaUser,
        "testo_domanda":st.domanda,
        "testo_risposta":st.risposte[st.rispostaUser] !== undefined ? st.risposte[st.rispostaUser]:st.rispostaUser
        }
        arrayPay.push(pay);
      }
        
     }  )
      var apiBaseUrl = Constanti.url_Backend+"datiAccessibilita";
      var payload= {
          "user_id": u.id,
          "steps":arrayPay
        }
   
      console.log(payload);
      var self = this;
      axios.post(apiBaseUrl, payload).then(function (response) {
       
        var userLogin= response.data.data;
        if (userLogin !== 'KO'){
          sessionStorage.setItem('user',JSON.stringify(userLogin));
          self.setState({ show:true});
          self.props.navigate('/profilo/accessibilita') ;
        }else{
     
          
        }
      });
      return;
    }
    this.setState({activeStep: this.state.steps[index + 1]});
    
  }

   handleStep = (i) =>{
    console.log(i);
    const index = this.state.steps.findIndex(x => x.key === this.state.activeStep.key);
    if (index > i){
    let items = [...this.state.steps];
    items.map((x,j) => {
      if (j <= i ){
        x.isDone = true;
      }else{
        x.isDone = false;
      }
       return x;
     })
     this.setState({steps:items});
     this.setState({activeStep: this.state.steps[i]});
    }
  }

  handleBack = () => {
    const index = this.state.steps.findIndex(x => x.key === this.state.activeStep.key);
    let items = [...this.state.steps];
    items.map((x,j) => {
      if (x.key === this.state.activeStep.key) x.isDone = true;
      return x;
    });
    this.setState({activeStep: this.state.steps[index - 1]});
    
  }
  


  componentWillMount = ()=>{
    
    this.setState({activeStep: this.state.steps[0]});
  }

 
  
  render(){
    const {t,i18n } = this.props;
    var u = JSON.parse(sessionStorage.getItem('user'));
  return (
    <>
    <div className="wizardPrenotazione">
    <div className="_2-col">
    <div className="flex-child-half _40">
      <div className="box-carousel">
        <div className="steps">
          <ul className="nav">
            {this.state.steps.map((step, i) => {
              return <Col key={i} xs ={1}>
              <li>
                <div className={'vsolid ' + (step.isDone ? 'done ' : '') + (this.state.activeStep.key === step.key  ? 'done ' : '')} ></div>
               
              </li>
              </Col>
            })}
          </ul>
        </div>
        <div className="step-component">
           <Step key={this.state.activeStep.id} user={u} step={this.state.activeStep} lunghezza={this.state.steps.length}  funzione={this.handleNext} funzioneBack={this.handleBack}></Step>
        </div>
      </div>
    </div>
    <div className="flex-child-half _40 imageWizard" style={{ backgroundImage: `url(${image})` }}></div>
    </div>
    </div>
    <Modal  show={this.state.show} className="modalRegistrati" style={{maxWidth:"650px"}}
        onHide={this.handleClose}>
        <Modal.Body>
           <Row>
           <Col xs={12} className="contenitoreTestiAccess">
            <div className="titleModalAccessibilita">{t('titoloModalAccessibilita')}</div>
            <div className="testoModalAccessibilita marginTop30">Hai completato il tuo profilo accessibilità. 
Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque.</div>
            
          <Link to="/">
              <button className="btn-prenotazione marginTop30" onClick={this.registrazione}> {t('modalaccessibilitaButton')}</button>
          </Link>
           </Col>
           </Row>
       
        </Modal.Body>
        </Modal>   
        </>
  );
}
}
export default  withTranslation()((props) => (
  <WizardAccessibilita
      {...props}
      params={useParams()}
      navigate={useNavigate()}
  />
)); 

