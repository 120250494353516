import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../style/tour.css';
import { Col, Row} from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectEta from "./SelectEta.js";
import Moment from 'moment';
import { addDays } from 'date-fns';
import ModalLogin from "../ModalLogin/modalLogin.js";
import { Navigate } from "react-router";
import { UserContext } from "../context/UserContext.js";


class BoxPrenotazioni
 extends Component{
  static contextType = UserContext;
    constructor(props){
    super(props);
    this.state = {
        numPers:1,
        numDis:0,
        numAdulti:1,
        numBambini03:0,
        numBambini410:0,
        numBambini1117:0,
        numBambiniDis:0,
        numAdultiDis:0,
        numCamere:0,
        numCamereAcc:0,
        tipoHotel:'',
        startDate:"",
        endDate:"",
        submitted:null,
        boxPerson:false,
        showModal:false,
        obj:null,
        showPrenotazione:false,
        boxPersonDisabili:false,
        errorPerson:false,
    }
        this.handleChange = this.handleChange.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.handleChangeShowBox = this.handleChangeShowBox.bind(this);
      this.incrementNumBambini = this.incrementNumBambini.bind(this);
      this.incrementNumAdulti = this.incrementNumAdulti.bind(this);
      this.decrementNumBambini = this.decrementNumBambini.bind(this);
      this.decrementNumAdulti = this.decrementNumAdulti.bind(this);
      this.showModalChange = this.showModalChange.bind(this);
      this.closePerson=this.closePerson.bind(this);
      this.handleChangeShowBoxDis = this.handleChangeShowBoxDis.bind(this);
      this.decrementNumBambiniDis = this.decrementNumBambiniDis.bind(this);
      this.incrementNumBambiniDis = this.incrementNumBambiniDis.bind(this);
      this.decrementNumAdultiDis = this.decrementNumAdultiDis.bind(this);
      this.incrementNumAdultiDis = this.incrementNumAdultiDis.bind(this);
      this.closePersonDisabili=this.closePersonDisabili.bind(this);
      this.incrementNumBambini410=this.incrementNumBambini410.bind(this);
      this.incrementNumBambini1117=this.incrementNumBambini1117.bind(this);
      this.decrementNumBambini410=this.decrementNumBambini410.bind(this);
      this.decrementNumBambini1117=this.decrementNumBambini1117.bind(this);
    }

    incrementNumBambiniDis(){
      if (this.state.numBambiniDis < this.state.numBambini03+this.state.numBambini410+this.state.numBambini1117){
      this.setState({numBambiniDis : this.state.numBambiniDis+1 });
      }
    }
    incrementNumAdultiDis(){
      if (this.state.numAdultiDis < this.state.numAdulti){
      this.setState({numAdultiDis : this.state.numAdultiDis+1 });
      }
      }
    decrementNumBambiniDis(){
      this.setState({numBambiniDis : this.state.numBambiniDis-1 });
    }
    decrementNumAdultiDis(){
      this.setState({numAdultiDis : this.state.numAdultiDis-1 });
    }
    

    handleChangeShowBoxDis(){
      this.setState({boxPersonDisabili : !this.state.boxPersonDisabili ,boxPerson:false});
    }

    handleChangeShowBox(){
      this.setState({boxPerson : !this.state.boxPerson ,boxPersonDisabili:false});
    }


    closePersonDisabili(){
      if (this.state.numBambiniDis+this.state.numAdultiDis >=0 && this.state.numBambiniDis+this.state.numAdultiDis <= this.state.numBambini03+this.state.numBambini410+this.state.numBambini1117+this.state.numAdulti ){
         this.setState({boxPersonDisabili : !this.state.boxPersonDisabili,numDis : this.state.numAdultiDis+this.state.numBambiniDis });
      }else{
         this.setState({errorPerson:true});
      }
    }

    showModalChange(){
      if (this.state.numPers >0 && this.state.startDate != null ){
      //FIXME: in questo punto bisogna fare i controlli sul form e mettere i dati in sessione.
      var richiestaPrenotazione={
        numPers:this.state.numPers,
        numDis:this.state.numDis,
        numAdulti:this.state.numAdulti,
        numBambini03:this.state.numBambini03,
        numBambini410:this.state.numBambini410,
        numBambini1117:this.state.numBambini1117,
        startDate:this.state.startDate,
        endDate:this.state.endDate,
        isMultyDays:this.props.giorni !== undefined ? true:false,
        numBambiniDis:this.state.numBambiniDis,
        numAdultiDis:this.state.numAdultiDis,
        errorPerson:false,
        numCamere:this.state.numCamere,
        numCamereAcc:this.state.numCamereAcc,
        tipoHotel:this.state.tipoHotel,
      }
      sessionStorage.setItem('prenotazione',JSON.stringify(richiestaPrenotazione));
      if (this.context.user != null){
        this.setState({showPrenotazione: true});
      }else{
        this.setState({showModal : true });
      }
     }
    }

    closeModalChange = () => {
      this.setState({showModal : false });
    }
    
 setStartDate(date){
  this.setState({startDate : date });
}

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name] : value });
       
    }

    incrementNumBambini(){
      this.setState({numBambini03 : this.state.numBambini03+1 });
    }
    incrementNumAdulti(){
      this.setState({numAdulti : this.state.numAdulti+1 });
    }
    decrementNumBambini(){
      this.setState({numBambini03 : this.state.numBambini03-1 });
    }
    decrementNumAdulti(){
      this.setState({numAdulti : this.state.numAdulti-1 });
    }

    incrementNumBambini410(){
      this.setState({numBambini410 : this.state.numBambini410+1 });
    }
    decrementNumBambini410(){
      this.setState({numBambini410 : this.state.numBambini410-1 });
    }
    incrementNumBambini1117(){
      this.setState({numBambini1117 : this.state.numBambini1117+1 });
    }
    decrementNumBambini1117(){
      this.setState({numBambini1117 : this.state.numBambini1117-1 });
    }
    

    
    closePerson(){
      this.setState({boxPerson : false,numPers : this.state.numAdulti+this.state.numBambini03+this.state.numBambini410+this.state.numBambini1117 });
    }

    createElements(n){
      var elements = [];
      for(var i =0; i < n; i++){
        elements.push(<SelectEta>{i}</SelectEta>);
      }
      return elements;
  }
  
  
  componentDidMount() {
    sessionStorage.setItem('obj',JSON.stringify(this.props.oggetto));
  }
    
    
    


  render(){
  const onChange = (dates) => {
      const [start, end] = dates;
      var endDate
      if (end === undefined || end == null){
        endDate = addDays(start,this.props.giorni-1);
      }
      
      this.setState({startDate : start,endDate: endDate});
   
    };
    var richiestaPrenotazione={
      numPers:this.state.numPers,
      numDis:this.state.numDis,
      numAdulti:this.state.numAdulti,
      numBambini03:this.state.numBambini03,
      numBambini410:this.state.numBambini410,
      numBambini1117:this.state.numBambini1117,
      startDate:this.state.startDate,
      endDate:this.state.endDate,
      numCamere:this.state.numCamere,
      numCamereAcc:this.state.numCamereAcc,
      tipoHotel:this.state.tipoHotel,
      isMultyDays:this.props.giorni !== undefined ? true:false,
      numBambiniDis:this.state.numBambiniDis,
      numAdultiDis:this.state.numAdultiDis,
      errorPerson:false,
     }
    var data = {
      user: this.state.user,
      tour: JSON.parse(sessionStorage.getItem('obj')),
      prenotazione: richiestaPrenotazione
    }
    if (this.state.showPrenotazione){
      return <Navigate to='/prenotazione' state={data} ></Navigate>
    }

    const {boxPerson,startDate,submitted,numAdulti,numBambini03,numBambini410,numBambini1117,endDate,boxPersonDisabili,numAdultiDis,numBambiniDis,errorPerson} = this.state;
    const {t,i18n,prezzo,giorni} = this.props;
    return (
        <>
        <div className="boxPrenotazioni">
        <div className="contenuto">
         <div className="titoloBoxPrenotazioni">
            {t("titoloBoxPrenotazione")}
         </div>
         <div className="subtitleBoxPrenotazioni">
            {t("subtitleBoxPrenotazioni")}
         </div>
         <div className="prezzoBoxPrenotazioni">
         <div className="riga">
          <span className="spanPrice">{t("da")}</span>  € {prezzo}
        </div>
          <div className="subtitlePrezzo">  
            {t("subtitlePrezzo")}
            </div>
            
         </div>
         <hr className="solid"></hr>
         <label className="labelBoxPrenotazioni">{t('data')}</label>
    {giorni === undefined ? (
       
         <DatePicker className="box-prenotazione-input" dateFormat="dd/MM/yyyy" selected={startDate} name="startDate"  value={startDate}  onChange={(date) => this.setStartDate(date)} 
      placeholderText={i18n.language === 'it'?  'Seleziona': 'Select'}  startDate={startDate} minDate={Moment().toDate()}
         />
    ) : (
<DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      placeholderText={i18n.language === 'it'?  'Seleziona': 'Select'}
      className="box-prenotazione-input" minDate={Moment().toDate()}
    />
    )}
        {submitted && !startDate &&
             <div className="help-block">{t('d.init.required')}</div>
         }
    
        
         <label className="labelBoxPrenotazioni">{t('n.persone')}</label>
         <input onClick={this.handleChangeShowBox} readOnly={true}  className="box-prenotazione-input" name="numPers" value={this.state.numPers}>
        
         </input>
         {boxPerson &&
          <div className="boxPersone">
          <Row className="marginTop30">
            <Col xs={6}>
              <div className="titleBoxPersone">{t('adulti')}</div>
              <div className="subtitleBoxPersone">{t('anni')}</div>
            </Col>
            <Col xs={6}>
            <div className="buttonViaggiatori">
              <button className={"buttonBoxPerson "+ (numAdulti===0 ? 'deactivate':'')}  onClick={this.decrementNumAdulti} disabled={numAdulti===0}>-</button>
              <label className="labelBoxPerson">{numAdulti}</label>
              <button className="buttonBoxPerson" onClick={this.incrementNumAdulti}>+</button>
              </div>
            </Col>
          </Row>
          <Row className="marginTop30">
            <Col xs={6}>
              <div className="titleBoxPersone">{t('bambini')}</div>
              <div className="subtitleBoxPersone">{t('anniB')}</div>
            </Col>
            <Col xs={6}>
            <div className="buttonViaggiatori">
            <button className={"buttonBoxPerson "+ (numBambini03===0 ? 'deactivate':'')} onClick={this.decrementNumBambini} disabled={numBambini03===0}>-</button>
            <label className="labelBoxPerson">{numBambini03}</label>
            <button className="buttonBoxPerson" onClick={this.incrementNumBambini}>+</button>
            </div>
            </Col>
          </Row>
          <Row className="marginTop30">
            <Col xs={6}>
              <div className="titleBoxPersone">{t('bambini')}</div>
              <div className="subtitleBoxPersone">{t('anni410')}</div>
            </Col>
            <Col xs={6}>
            <div className="buttonViaggiatori">
            <button className={"buttonBoxPerson "+ (numBambini410===0 ? 'deactivate':'')} onClick={this.decrementNumBambini410} disabled={numBambini410===0}>-</button>
            <label className="labelBoxPerson">{numBambini410}</label>
            <button className="buttonBoxPerson" onClick={this.incrementNumBambini410}>+</button>
            </div>
            </Col>
          </Row>
          <Row className="marginTop30">
            <Col xs={6}>
              <div className="titleBoxPersone">{t('bambini')}</div>
              <div className="subtitleBoxPersone">{t('anni1117')}</div>
            </Col>
            <Col xs={6}>
            <div className="buttonViaggiatori">
            <button className={"buttonBoxPerson "+ (numBambini1117===0 ? 'deactivate':'')} onClick={this.decrementNumBambini1117} disabled={numBambini1117===0}>-</button>
            <label className="labelBoxPerson">{numBambini1117}</label>
            <button className="buttonBoxPerson" onClick={this.incrementNumBambini1117}>+</button>
            </div>
            </Col>
          </Row>
    

     
          <button className="btn-boxPerson marginTop30" onClick={this.closePerson}> {t("salva")}</button>
          </div>
         }
         <hr className="solid"></hr>
         <div className="personeDisabilitBox">  
            {t("personeDisabilitBox")}
            </div>
         <label className="labelBoxPrenotazioni">{t("n.persone")}</label>
         <input onClick={this.handleChangeShowBoxDis} readOnly={true} className="box-prenotazione-input" name="numDis" value={this.state.numDis}></input>
         {boxPersonDisabili &&
          <div className="boxPersone">
          <Row className="marginTop30">
            <Col xs={6}>
              <div className="titleBoxPersone">{t('adulti')}</div>
              <div className="subtitleBoxPersone">{t('anni')}</div>
            </Col>
            <Col xs={6}>
            <div className="buttonViaggiatori">
              <button className={"buttonBoxPerson "+ (numAdultiDis===0? 'deactivate':'')}  onClick={this.decrementNumAdultiDis} disabled={numAdultiDis===0}>-</button>
              <label className="labelBoxPerson">{numAdultiDis}</label>
              <button className={"buttonBoxPerson "+ (numAdultiDis===numAdulti? 'deactivate':''? 'deactivate':'')} onClick={this.incrementNumAdultiDis}>+</button>
              </div>
            </Col>
          </Row>
          <Row className="marginTop30">
            <Col xs={6}>
              <div className="titleBoxPersone">{t('bambini')}</div>
              <div className="subtitleBoxPersone">{t('anni017')}</div>
            </Col>
            <Col xs={6}>
            <div className="buttonViaggiatori">
            <button className={"buttonBoxPerson "+ (numBambiniDis===0? 'deactivate':''? 'deactivate':'')} onClick={this.decrementNumBambiniDis} disabled={numBambiniDis===0}>-</button>
            <label className="labelBoxPerson">{numBambiniDis}</label>
            <button className={"buttonBoxPerson "+ (numBambiniDis===numBambini03+numBambini410+numBambini1117? 'deactivate':''? 'deactivate':'')} onClick={this.incrementNumBambiniDis}>+</button>
            </div>
            </Col>
          </Row>
          {errorPerson &&
          <Row>
           <div >
          {t("errormatchPerson")}
          </div>
          </Row>
         }
          <button className="btn-boxPerson marginTop30" onClick={this.closePersonDisabili}> {t("salva")}</button>
          </div>
         }
        
         {giorni !== undefined &&
         <>
         <hr className="solid"></hr>
          <label className="labelBoxPrenotazioni">{t('t.hotel')}</label>
          <select  onChange={(e) => this.handleChange(e)} className="box-prenotazione-input" name="tipoHotel" value={this.state.tipoHotel}>
         <option value=''>{t("seleziona")}</option>
         <option value='1'>3 {t("stelle")}</option>
         <option value='2'>4 {t("stelle")}</option>
         <option value='3'>5 {t("stelle")}</option>
         </select>
         <label className="labelBoxPrenotazioni">{t('n.camere')}</label>
         <select  onChange={(e) => this.handleChange(e)} className="box-prenotazione-input" name="numCamere" value={this.state.numCamere}>
         <option value=''>{t("seleziona")}</option>
         <option value='1'>1</option>
         <option value='2'>2</option>
         <option value='3'>3</option>
         <option value='4'>4</option>
         <option value='5'>5</option>
         <option value='6'>6</option>
         <option value='7'>7</option>
         <option value='8'>8</option>
         <option value='9'>9</option>
         <option value='10'>10</option>
         </select>
         <label className="labelBoxPrenotazioni">{t('n.camere.accessibili')}</label>
         <select  onChange={(e) => this.handleChange(e)} className="box-prenotazione-input" name="numCamereAcc" value={this.state.numCamereAcc}>
         <option value=''>{t("seleziona")}</option>
         <option value='1'>1</option>
         <option value='2'>2</option>
         <option value='3'>3</option>
         <option value='4'>4</option>
         <option value='5'>5</option>
         <option value='6'>6</option>
         <option value='7'>7</option>
         <option value='8'>8</option>
         <option value='9'>9</option>
         <option value='10'>10</option>
         </select>
         </>
         }
        
         <button className="btn-prenotazione" onClick={this.showModalChange}> {t("richiediPrenotazione")}</button>
         </div>

        </div> 
        {this.state.showModal &&
        <ModalLogin show={this.state.showModal} key={this.props.tour} close={this.closeModalChange} prenotazione={richiestaPrenotazione}></ModalLogin>  
        }    
</>
    )
}
}
export default  withTranslation()(BoxPrenotazioni); 
