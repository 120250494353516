import React, { Component } from "react";
import '../../style/tour.css';
import { Col, Modal, Row,Button } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import hotel from '../../images/hotelIcon.png'
import arrow from '../../images/arrow.png'


class CardItinerarioPrenotazione
 extends Component{
    constructor(props){
    super(props);
    }
    
    
  render(){
    let titolo;
    if (this.props.numero === 1){
      titolo = this.props.i18n.language === 'it'? this.props.tour?.titolo_1 : this.props.tour?.titolo_1_en;
   }else if (this.props.numero === 2){
        titolo = this.props.i18n.language === 'it'? this.props.tour?.titolo_2 : this.props.tour?.titolo_2_en;
    }else if (this.props.numero === 3){
        titolo = this.props.i18n.language === 'it'? this.props.tour?.titolo_3 : this.props.tour?.titolo_3_en;
    }else if (this.props.numero === 4){
        titolo = this.props.i18n.language === 'it'? this.props.tour?.titolo_4 : this.props.tour?.titolo_4_en;
    }else if (this.props.numero === 5){
        titolo = this.props.i18n.language === 'it'? this.props.tour?.titolo_5 : this.props.tour?.titolo_5_en;
    }else if (this.props.numero === 6){
        titolo = this.props.i18n.language === 'it'? this.props.tour?.titolo_6 : this.props.tour?.titolo_6_en;
    }
    
    const {t,i18n } = this.props;
    return (
        <>
          <div className="itinerary-card">
            <div className="left-side">
              <div className="black-point"></div>
              <div className="linea-tratteggiata"></div>
            </div>
            <div className="right-side">
              <h3 className="heading-itinerary">{titolo}</h3>
              <div className="pernottamento-flex">
                <div className="icon-bed"><img src={hotel} width="24" alt=""/></div>
                <div className="text">{t("pernottamento")}</div>
              </div>
            </div>
          </div>
        </>
    )
}
}
export default  withTranslation()(CardItinerarioPrenotazione); 
