import React, { Component } from "react";
import * as Constanti from '../../Utils/constants.js';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../style/virtual.css';
import { Button, Col, Row } from "react-bootstrap";
import Footer from "../molecule/footer";
import axios from "axios";
import { withTranslation } from "react-i18next";
import play from '../../images/play.png';
import img360 from '../../images/360.png';
import close from '../../images/ximage.png';
//import Lightbox from "yet-another-react-lightbox";
//import "yet-another-react-lightbox/styles.css";
//import Video from "yet-another-react-lightbox/plugins/video";
//import "yet-another-react-lightbox/plugins/captions.css";
//import "yet-another-react-lightbox/plugins/thumbnails.css";
import selectArrow from '../../images/arrowDown.png';
import VideoJS from "./prova.js";



class VirtualTour
 extends Component{

 
    constructor(props){
     super(props);
    this.state = {
      "filteredVideo":[],
       "video":[],
       "show":false,
       "indice":false,
       "videoPlay":[],
       categorieSelected:[],
       showCategory:false,
       categorie: [],
       VIDEOJS_OPTIONS:null,
       is360:false,
      }
    
     this.changeButton = this.changeButton.bind(this);
     this.handleChangeCheck = this.handleChangeCheck.bind(this);
    }

    
    

    handleChangeCheck(e){
      const { name } = e.target;
      if (e.target.checked){
       var c = this.state.categorieSelected;
       c.push(name);
       this.setState({categorieSelected : c});
      }else{
        let item = this.state.categorieSelected.filter((tag) => tag !== name);
     this.setState({categorieSelected : item});
      }
    
    }  
  
    
    changeButton(list){
      this.setState({show : !this.state.show});
      this.setState({is360 : this.state.video[list].is_360 == 0 ? false:true});
      
    //  var images=[];

       this.state.VIDEOJS_OPTIONS = {
        controls: true,
        width: window.innerWidth>1000 ? 1000 : window.innerWidth>475 ?  750 : 350,
        sources: [
          {
            src: this.state.video[list].video,
            type: 'video/mp4'
          }
        ]
      };
      /*this.state.VIDEOJS_OPTIONS = {
        controls: true,
        width: 480,
        sources: [
          {
            src: this.state.video[list].video,
            type: 'video/mp4'
          }
        ]
      };*/

   /*   var img = {
        "autoPlay":true,
        type: "video",
        width: 1280,
        height: 720,
        poster: "/public/poster-image.jpg",
        sources: [
            {
                src: this.state.video[list].video,
                type: "video/mp4"
            }
        ]
      }
      images.push(img);
      this.setState({videoPlay : images});
    */
      
    }
    handleShowCategory=() =>{
      this.setState({showCategory: true});
    }
    handleCloseCategory = () =>{
      this.setState({showCategory: false});
    }
    handleCloseSaveCategory = () =>{
      var arrayFiltrato = this.state.video;
      this.state.categorieSelected.map((categoria) =>{ 
        arrayFiltrato = arrayFiltrato.filter(data => data.categoria_video_id == categoria);
        });
      this.setState({filteredVideo:arrayFiltrato});
      this.setState({showCategory: false});
    }

    componentDidMount() {
     var apiBaseUrl = Constanti.url_Backend+"getAllVideo";
        var self = this;
        axios.get(apiBaseUrl).then(function (response) {
        if (response.data.data != null){
           var videos = response.data.data;
           self.setState({ video : videos,filteredVideo:videos })
        }else{
          console.log("non ci video tour per questo id");
        }
        self.setState({load:false});
      })
      .catch(function (error) {
      console.log(error);
      self.setState({load:false});
      });

      apiBaseUrl = Constanti.url_Backend+'getAllCategoryVideo';
      
      axios.get(apiBaseUrl).then(function (response) {
     
      if (response.data.data != null){
         var categorie = response.data.data;
         self.setState({ categorie : categorie })
      }else{
        console.log("non ci sono location");
      }
      self.setState({load:false});
    })
    .catch(function (error) {
    console.log(error);
    self.setState({load:false});
    });
 }

 handleClose= () =>{
  this.setState({show : !this.state.show});
 }



   
    

  render(){
    const { filteredVideo,show,showCategory,categorieSelected} = this.state;
    const length = this.state.filteredVideo.length;
    const {t,i18n} = this.props;
    
   
    return (
        <>
    <div className="virtualHead">
    <div className="headDestination">
    <p className="text-center titlePage">{t('virtualtour.label')}</p>
    <h2 className="text-center titleBig">{t('virtualTitolo')}</h2>
    <h2 className="text-center titleBig">{t('virtualTitolo2')}</h2>
    <p className="text-center subtitleBig">{t('virtualSubtitle')}</p>
    </div>
    </div>
    

    <div className="containerVirtual">
   
    <Row className="justify-content-end marginBottom24 virtualMobile">
    <button onClick={this.handleShowCategory} className="selectCorpo"  ref={this.myRef}>  {t("categoria")}<img src={selectArrow} height="5px" alt=""  className="imgButton"/></button>
        {showCategory &&
        <div className="modalVirtual">
        
        {this.state.categorie?.map((option) => 
          <div className="">
          <input type="checkbox" key={option} defaultChecked={categorieSelected.find((element)=>{return element == option.id})}    onChange={this.handleChangeCheck} className="w-checkbox-input checkbox" name={option.id}/>
          <span className="checkbox-label w-form-label" > {i18n.language === 'it'? option.categoriaVideo : option.categoriaVideo_en }</span>
          </div>
          )}
          <hr className="solid"></hr>
          <div className="marginTop16 d-flex justify-content-between">
          <Button className="buttonCancellaModal" onClick={this.handleCloseCategory}>
          {t("cancella")}
          </Button>
          <Button className="buttonSalvaModal" onClick={this.handleCloseSaveCategory }>
            {t("salva")}
          </Button>
        </div>
        </div>
        }
    </Row>
   {(length ===1 || length === 2)   &&
    <Row className="marginBottom40 mobileVirtual" >
      <Col key={filteredVideo[0].titolo} xs={6} className="positionRelative paddingLeftRight0">
       {filteredVideo[0].is_360 &&
          <img alt="" src={img360} className="img360"></img>
       }
          <img src={filteredVideo[0].img} alt="" className="imgVideo"></img>
         
          <div className="container-cardvideo">
          <button onClick={e=>this.changeButton(0)} style={{border:"none"}}><img alt="" src={play} className="imgPlay"></img></button>
          <span className="titoloVideo"> {i18n.language === 'it'? filteredVideo[0].titolo : filteredVideo[0].titolo_en}</span>
          <span className="sottotitoloVideo">{i18n.language === 'it'? filteredVideo[0].sottotitolo : filteredVideo[0].sottotitolo_en}</span>
          </div>
      </Col>
      {length === 2 &&
      <Col xs={6} key={filteredVideo[1].titolo} className="positionRelative paddingLeftRight0">
      {filteredVideo[1].is_360 &&
          <img alt="" src={img360} className="img360"></img>
       }
          <img src={filteredVideo[1].img} alt="" className="imgVideo"></img>
         
          <div className="container-cardvideo">
          <button onClick={e=>this.changeButton(1)} style={{border:"none"}}><img alt="" src={play} className="imgPlay"></img></button>
          <span className="titoloVideo">{i18n.language === 'it'? filteredVideo[1].titolo : filteredVideo[1].titolo_en}</span>
          <span className="sottotitoloVideo">{i18n.language === 'it'? filteredVideo[1].sottotitolo : filteredVideo[1].sottotitolo_en}</span>
          </div>
      </Col>
    }
    </Row>
      
   }
   {length === 3 &&
   <>
    <Row className="marginBottom40 mobileVirtual">
      <Col xs={12} className="positionRelative virtual3">
       <img alt="" src={img360} className={filteredVideo[0].is_360 ? "active" : "none"}></img>
      
      <img src={filteredVideo[0].img} alt="" key={filteredVideo[0].id} className="imgVideo imgVideo3"></img>
      
      <div className="container-cardvideo tre">
      <button onClick={e=>this.changeButton(0)} style={{border:"none"}}><img src={play} alt="" className="imgPlay"></img></button>
          <span className="titoloVideo">{i18n.language === 'it'? filteredVideo[0].titolo : filteredVideo[0].titolo_en}</span>
          <span className="sottotitoloVideo">{i18n.language === 'it'? filteredVideo[0].sottotitolo : filteredVideo[0].sottotitolo_en}</span>
      </div>
      </Col>
    </Row>
    <Row className="marginBottom40 mobileVirtual">
      <Col xs={12} className="positionRelative">
      {filteredVideo[1].is_360 &&
          <img alt="" src={img360} className="img360"></img>
       }
      <img src={filteredVideo[1].img} alt="" className="imgVideo imgVideo3" key={filteredVideo[1].id}></img>
     
      <div className="container-cardvideo tre">
      <button onClick={e=>this.changeButton(1)} style={{border:"none"}}><img src={play} alt="" className="imgPlay"></img></button>
          <span className="titoloVideo">{i18n.language === 'it'? filteredVideo[1].titolo : filteredVideo[1].titolo_en}</span>
          <span className="sottotitoloVideo">{i18n.language === 'it'? filteredVideo[1].sottotitolo : filteredVideo[1].sottotitolo_en}</span>
      </div>
      </Col>
    </Row>
    <Row className="marginBottom40 mobileVirtual">
      <Col xs={12} className="positionRelative">
      {filteredVideo[2].is_360 &&
          <img alt="" src={img360} className="img360"></img>
       }
      <img src={filteredVideo[2].img} alt="" className="imgVideo imgVideo3" key={filteredVideo[2].id}></img>
    
      <div className="container-cardvideo tre">
      <button onClick={e=>this.changeButton(2)} style={{border:"none"}}><img src={play} alt="" className="imgPlay"></img></button>
          <span className="titoloVideo">{i18n.language === 'it'? filteredVideo[2].titolo : filteredVideo[2].titolo_en}</span>
          <span className="sottotitoloVideo">{i18n.language === 'it'? filteredVideo[2].sottotitolo : filteredVideo[2].sottotitolo_en}</span>
      </div>
      </Col>
    </Row>
    </>
   }
   {length === 5 &&
    <div>
    <Row className="marginBottom40 mobileVirtual">
      <Col xs={8} key={filteredVideo[0].sottotitolo} className="positionRelative">
      {filteredVideo[0].is_360 &&
          <img alt="" src={img360} className="img360"></img>
       }
      <img src={filteredVideo[0].img} alt="" className="imgVideo" ></img>
      
      <div className="container-cardvideo">
      <button onClick={e=>this.changeButton(0)} style={{border:"none"}}><img src={play} alt="" className="imgPlay"></img></button>
          <span className="titoloVideo">{i18n.language === 'it'? filteredVideo[0].titolo : filteredVideo[0].titolo_en}</span>
          <span className="sottotitoloVideo">{i18n.language === 'it'? filteredVideo[0].sottotitolo : filteredVideo[0].sottotitolo_en}</span>
      </div>
      </Col>
      <Col xs={4} key={filteredVideo[1].sottotitolo} className="positionRelative">
      {filteredVideo[1].is_360 &&
          <img alt="" src={img360} className="img360"></img>
       }
      <img src={filteredVideo[1].img} alt="" className="imgVideo"></img>
     
      <div className="container-cardvideo">
      <button onClick={e=>this.changeButton(1)} style={{border:"none"}}><img src={play} alt="" className="imgPlay"></img></button>
          <span className="titoloVideo">{i18n.language === 'it'? filteredVideo[1].titolo : filteredVideo[1].titolo_en}</span>
          <span className="sottotitoloVideo">{i18n.language === 'it'? filteredVideo[1].sottotitolo : filteredVideo[1].sottotitolo_en}</span>
      </div>
      </Col>
    </Row>
    <Row className="marginBottom40 mobileVirtual">
      <Col xs={4} key={filteredVideo[2].sottotitolo} className="positionRelative">
      {filteredVideo[2].is_360 &&
          <img alt="" src={img360} className="img360"></img>
       }
      <img src={filteredVideo[2].img}  alt="" className="imgVideo"></img>
     
      <div className="container-cardvideo">
      <button onClick={e=>this.changeButton(2)} style={{border:"none"}}><img src={play} alt="" className="imgPlay"></img></button>
          <span className="titoloVideo">{i18n.language === 'it'? filteredVideo[2].titolo : filteredVideo[2].titolo_en}</span>
          <span className="sottotitoloVideo">{i18n.language === 'it'? filteredVideo[2].sottotitolo : filteredVideo[2].sottotitolo_en}</span>
      </div>
      </Col>
      <Col xs={4} key={filteredVideo[3].sottotitolo} className="positionRelative">
      {filteredVideo[3].is_360 &&
          <img alt="" src={img360} className="img360"></img>
       }
      <img src={filteredVideo[3].img} alt="" className="imgVideo"></img>
     
      <div className="container-cardvideo">
      <button onClick={e=>this.changeButton(3)} style={{border:"none"}}> <img src={play} alt="" className="imgPlay"></img></button>
          <span className="titoloVideo">{i18n.language === 'it'? filteredVideo[3].titolo : filteredVideo[3].titolo_en}</span>
          <span className="sottotitoloVideo">{i18n.language === 'it'? filteredVideo[3].sottotitolo : filteredVideo[3].sottotitolo_en}</span>
      </div>
      </Col>
      <Col xs={4} key={filteredVideo[4].sottotitolo} className="positionRelative">
      {filteredVideo[4].is_360 &&
          <img alt="" src={img360} className="img360"></img>
       }
      <img src={filteredVideo[4].img} alt="" className="imgVideo"></img>
      
      <div className="container-cardvideo">
      <button onClick={e=>this.changeButton(4)} style={{border:"none"}}> <img src={play}  alt="" className="imgPlay"></img></button>
          <span className="titoloVideo">{i18n.language === 'it'? filteredVideo[4].titolo : filteredVideo[4].titolo_en}</span>
          <span className="sottotitoloVideo">{i18n.language === 'it'? filteredVideo[4].sottotitolo : filteredVideo[4].sottotitolo_en}</span>
      </div>
      </Col>
    </Row>
    </div>
   }
   </div>


   {show  &&
  <div className="videoPlayer">"
  
  
  
        <div className="videoContainer">
        <button  onClick={this.handleClose} className="buttonSlider buttonClose"><img className="prev-slick-arrow" src={close}></img></button>
        <VideoJS options={this.state.VIDEOJS_OPTIONS} is360={this.state.is360} /></div>
        </div>
   }
  
   
  
   <Footer></Footer>
 
</>

    )
}
}
export default withTranslation()(VirtualTour); 
