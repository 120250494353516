import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { UserContext } from "../context/UserContext.js";


class PrimoStep
 extends Component{
    static contextType = UserContext;
    constructor(props){
    super(props);
    this.state = {
        pref:"",
        numTel:"",
        citta:"",
        submitted:"",
    }
        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    
    componentDidMount() {
      
      this.setState({pref: this.props.user?.prefisso,numTel:this.props.user?.telephone,citta:this.props.user?.city});
      
    }
    

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name] : value });
       
    }


    submit (){
        this.setState({"submitted":true});
        if (this.state.pref !== '' && this.state.numTel !== '' && this.state.citta !== ''){
        var apiBaseUrl = Constanti.url_Backend+"aggiornaInfoAccessorie";
        var payload={
            "id": this.props.user.id,
            "prefisso": this.state.pref,
            "telefono": this.state.numTel,
            "citta": this.state.citta
          }

          console.log(payload);
         
          var self = this;
          axios.post(apiBaseUrl, payload).then(function (response) {
            var userLogin= response.data.data;
            if (userLogin !== 'KO'){
              const context = self.context;
              context.setUser(userLogin);
              sessionStorage.setItem('user',JSON.stringify(userLogin))
            }
            self.props.funzione();
          });
           
        }
    }

  render(){
  const {pref,numTel,citta,submitted} = this.state;
    const {t,i18n,user} = this.props;
    return (
        <>
         <div className="wizard">
        <div className="contenuto">
         <div className="titoloBoxPrenotazioni marginBottom40">
            {t("title.wizard.prenotazione")}
         </div>
         <Row>
         <Col xs={6}>
         <label className="labelCarouselPrenotazioni">{t("prefisso")}</label>
         <input onChange={this.handleChange} required={true} className="carousel-prenotazione-input" placeholder="+39" type="number" name="pref" value={this.state.pref}></input>
         {submitted && !pref &&
                            <div className="help-block">{t("required")}</div>
            }
        </Col>
        <Col xs={6}>
         <label className="labelCarouselPrenotazioni">{t("telefono")}</label>
         <input onChange={this.handleChange} required={true} className="carousel-prenotazione-input" placeholder={t("telefono")} name="numTel" type="number" value={this.state.numTel}></input>
         {submitted && !numTel &&
                            <div className="help-block">{t("required")}</div>
            }
         </Col>
        </Row>

        <Row>
        <Col xs={12}>
         <label className="labelCarouselPrenotazioni"> {t("city")}</label>
         <input onChange={this.handleChange} required={true} className="carousel-prenotazione-input" placeholder={t("city")} name="citta" value={this.state.citta}></input>
         {submitted && !citta &&
                            <div className="help-block">{t("required")}</div>
            }
         </Col>
         </Row>
        </div>
        </div>
        <div className="btn-component">
          <input type="button" value={'Continua'} className="buttonWizard" onClick={this.submit} />
        </div>
       
</>
    )
}
}
export default  withTranslation()(PrimoStep); 
