import { Component } from "react";
import { withTranslation } from "react-i18next";
import '../../style/profilo.css';
import { useLocation, useNavigate, useParams } from "react-router";
import Footer from "../molecule/footer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import profileIco from '../../images/profile-icon.png';
import datiPIcon from '../../images/dati-personali.png';
import profAccess from '../../images/profilo-accessibilita.png';
import imieiViaggi from '../../images/imieiviaggi.png';
import account from '../../images/account.png';
import DatiPersonali from "./datiPersonali";
import Accessibilita from "./accessibilita";
import viaggi from "./viaggi";
import Viaggi from "./viaggi";
import Account from "./account";


class Profilo extends Component{
  constructor(props){
  super(props);
  this.state = {
    tabSelected:'datiP',
    user: {},
    divStyle:{},
  }
  
  this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  handleChangeTab(tipo){
    this.setState({tabSelected:tipo,user: JSON.parse(sessionStorage.getItem('user'))});
   
    let elHeight = document.getElementById('tab_content').clientHeight
  }

  componentDidMount = () =>  {
   
    if (this.props.params.tab != null && this.props.params.tab ==='datipersonali'){
      this.setState({tabSelected: 'datiP'});
    }else
    if (this.props.params.tab != null && this.props.params.tab ==='accessibilita'){
      this.setState({tabSelected: 'accessibilita'});
    }else
    if (this.props.params.tab != null && this.props.params.tab ==='viaggi'){
      this.setState({tabSelected: 'viaggi'});
    }else
    if (this.props.params.tab != null && this.props.params.tab ==='account'){
      this.setState({tabSelected: 'account'});
    }
   
  }

  componentWillMount() {
    var u = JSON.parse(sessionStorage.getItem('user'));
    
  if (u != null){
    this.setState({user: u})
  }
  if (this.props.params.tab != null && this.props.params.tab ==='datipersonali'){
    this.setState({tabSelected: 'datiP'});
  }else
  if (this.props.params.tab != null && this.props.params.tab ==='accessibilita'){
    this.setState({tabSelected: 'accessibilita'});
  }else
  if (this.props.params.tab != null && this.props.params.tab ==='viaggi'){
    this.setState({tabSelected: 'viaggi'});
  }else
  if (this.props.params.tab != null && this.props.params.tab ==='account'){
    this.setState({tabSelected: 'account'});
  }
  
}

  render(){ 
    const {tabSelected} = this.state;
    const {t,i18n} = this.props;
   
  return (
      <>
   <div className="section wf-section">
    <div className="container">
      <div className="content_wrapper" >
        <div className="tabs-container">
          <div className="side-box">
            <div className="profilepic"><img src={profileIco} loading="lazy" width="48" alt="profile icon"/></div>
            <div className="text24 bold">Ciao!</div>
            <div className="p useremail">{this.state.user.email}</div>
            <div data-current="Account" data-easing="ease" data-duration-in="300" data-duration-out="100" className="tabs-wrapper w-tabs">
              <div className="tabs-menu w-tab-menu">
                <a data-w-tab="Dati personali" className={"tab-link w-inline-block w-tab-link "+ (tabSelected ==='datiP' ? "w--current":" ")} onClick={(e)=>this.handleChangeTab('datiP')}>
                  <div className="tab-icon"><img src={datiPIcon} loading="lazy" width="24" alt="dati personali icon"/></div>
                  <div>{t("datipersonali")}</div>
                </a>
                <a data-w-tab="Profilo accessibilità" className={"tab-link w-inline-block w-tab-link "+ (tabSelected ==='accessibilita' ? "w--current":" ")} onClick={(e)=>this.handleChangeTab('accessibilita')}>
                  <div className="tab-icon"><img src={profAccess} loading="lazy" width="24" alt=""/></div>
                  <div>{t("accessibilita.prodilo")}</div>
                </a>
                <a data-w-tab="I miei viaggi" className={"tab-link w-inline-block w-tab-link "+ (tabSelected ==='viaggi' ? "w--current":" ")} onClick={(e)=>this.handleChangeTab('viaggi')}>
                  <div className="tab-icon"><img src={imieiViaggi} loading="lazy" width="24.5" alt=""/></div>
                  <div>{t("viaggi")}</div>
                </a>
                <a data-w-tab="Account" className={"tab-link last w-inline-block w-tab-link "+ (tabSelected ==='account' ? "w--current":" ")}  onClick={(e)=>this.handleChangeTab('account')}>
                  <div className="tab-icon"><img src={account} loading="lazy" width="24.5" alt=""/></div>
                  <div>{t("account")}</div>
                </a>
              </div>
             
              </div>
              </div>
              <div id="tab_content" className="tabs-content w-tab-content">
              {tabSelected ==='datiP' &&
              <DatiPersonali user={this.state.user} key={this.state.user}></DatiPersonali>
              }
              {tabSelected ==='accessibilita' &&
               <Accessibilita></Accessibilita>
              }
              {tabSelected ==='viaggi' &&
               <Viaggi></Viaggi>
               
              }
              {tabSelected ==='account' &&
                <Account></Account>
              }
              </div>
              
             
           
         
        </div>
      </div>
    </div>
  </div>
        </>
  );
}
}
export default  withTranslation()((props) => (
  <Profilo
      {...props}
      params={useParams()}
      navigate={useNavigate()}
      location={useLocation()}
  />
)); 
