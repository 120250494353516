import React, { Component } from "react";
import '../../style/contatti.css';
import Footer from "../molecule/footer";
import { withTranslation } from "react-i18next";
import phone from "../../images/phone-icon.svg";
import emailImg from "../../images/email-icon.svg";
import fb from "../../images/icon-fb.svg";
import insta from "../../images/icon-insta.svg";
import twitter from "../../images/icon-twitter.svg";
import linkedin from "../../images/icon-linkedin.svg";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";



import ButtonMailto from "../molecule/buttonMailto.js";

class Contatti
 extends Component{
    constructor(props){
     super(props);

     this.state = {
        nome:"",
        cognome:"",
        email:"",
        oggetto:"",
        messaggio:"",
        submitted:false,
        submittedError:false,
        submittedOK:false,
     }
     this.handleChange = this.handleChange.bind(this);
     this.submit = this.submit.bind(this);
 
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name] : value });
       
    }

    submit(){
      this.setState({submitted:true}); 
      if (this.state.nome !== '' && this.state.cognome !== '' && this.state.messaggio !=='' && this.state.oggetto!=='' && this.state.email !== ''){
        var apiBaseUrl = Constanti.url_Backend+"sendContactEmail";
        var payload={
          "oggetto":this.state.oggetto,
          "cognome":this.state.cognome,
          "nome": this.state.nome,
          "email": this.state.email,
          "messaggio": this.state.messaggio
        }
       
        var self = this;
        axios.post(apiBaseUrl, payload).then(function (response) {
          var userLogin= response.data.data;
          if (userLogin === true){
            self.setState({submittedOK:true}); 
            
        }else{
          self.setState({submittedError:true}); 
        }
        }); 
    }
    }
  
  render(){
 const {t} = this.props;
 const {nome,cognome,messaggio,oggetto,submitted,email,submittedError,submittedOK} = this.state;
    return (
        <>
<div className="bodyContatti">
  <section className="hero_section contatti wf-section">
<div className="containerContatti">
  <div className="centered">
    <div className="label white  contattiLabel" style={{textAlign:"center"}}>{t("contatti.label")}</div>
    <h2 className="h2 white">{t("contatti.testo")}</h2>
    <p className="p white big">{t("contatti.testo2")}</p>
  </div>
</div>
</section>
<section className="sectionContatti nomarginbottom wf-section">
<div className="containerContatti">
  <div className="_2-col">
    <div className="flex-child-half _40">
      <div className="contattaci-wrapper">
        <h3 className="titolo">{t("contatti.contattaci")}</h3>
        <p className="textContact">{t("contatti.testo3")}</p>
        <div className="contact-wrapper margin">
          <div className="icon-wrapper margin"><img src={emailImg} loading="lazy" alt=""/></div>
          <ButtonMailto className="text-link" label="campaniaforall@gmail.com" mailto="mailto:campaniaforall@gmail.com" />
          
        </div>
        <div className="contact-wrapper">
          <div className="icon-wrapper margin"><img src={phone} loading="lazy" alt=""/></div>
          <a href="tel:0815561501" className="text-link">081 556 15 01</a>
        </div>
      </div>
      <div className="social-wrapper">
        <h3 className="h3">{t('seguici.social')}</h3>
        <div className="social-icons-wrapper">
          <a href="https://www.facebook.com/cosyfair/" className="social-link w-inline-block"><img src={fb} loading="lazy" alt=""/></a>
          <a href="https://www.instagram.com/cosyfair/" className="social-link w-inline-block"><img src={insta} loading="lazy" alt=""/></a>
          <a style={{display:'none'}} href="#" className="social-link w-inline-block"><img src={linkedin} loading="lazy" alt=""/></a>
          <a  style={{display:'none'}} href="#" className="social-link w-inline-block"><img src={twitter} loading="lazy" alt=""/></a>
        </div>
      </div>
    </div>
    <div className="flex-child-half _40 relative form">
      <div className="form-block w-form">
        <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form">
          <div className="input-field-wrapper half">
          <label  className="field-label">{t('nome')}</label>
          <input  onChange={this.handleChange}  value={nome} type="text" className="input-field w-input" maxLength="256" name="nome" data-name="nome" placeholder={t("scriviNome")} id="Nome" required=""></input>
          {submitted && !nome &&
            <label  className="field-label">{t('nomeRequired')}</label>
          }
          </div>
          <div className="input-field-wrapper half">
          <label  className="field-label">{t('cognome')}</label>
          <input onChange={this.handleChange}  value={cognome} type="text" className="input-field w-input" maxLength="256" name="cognome" data-name="Cognome" placeholder={t("scriviCongnome")} id="Cognome" required=""></input>
          {submitted && !cognome &&
            <label  className="field-label">{t('cognomeRequired')}</label>
          }
          </div>
          <div className="input-field-wrapper half">
          <label  className="field-label">{t('email')}</label>
          <input onChange={this.handleChange} type="email"  value={email}  className="input-field w-input" maxLength="256" name="email" data-name="Name" placeholder={t("scriviEmail")} id="name"></input>
          {submitted && !email &&
            <label  className="field-label">{t('emailRequired')}</label>
          }
          </div>
          <div className="input-field-wrapper half">
          <label  className="field-label">{t('oggetto')}</label>
          <input onChange={this.handleChange}  value={oggetto} type="text" className="input-field w-input" maxLength="256" name="oggetto" data-name="Oggetto" placeholder={t("scriviOggetto")} id="Oggetto" required=""></input></div>
          {submitted && !oggetto &&
            <label  className="field-label">{t('oggettoRequired')}</label>
          }
          <div className="input-field-wrapper">
          <label  className="field-label">{t('messaggio')}</label>
          <textarea  onChange={this.handleChange}  value={messaggio} placeholder={t("scriviMessaggio")} maxLength="5000" id="Messaggio" name="messaggio" data-name="field" className="input-field area w-input"></textarea></div>
          {submitted && !messaggio &&
            <label  className="field-label">{t('messaggioRequired')}</label>
          }
          <button type="button" className="button-primary w-button" onClick={this.submit} >{t("inviaMessaggio")}</button>
        </form>
        {submitted && submittedOK &&
        <div className="w-form-done">
          <div>{t('okContatcModule')}</div>
        </div>
        }
        {submitted && submittedError &&
        <div className="w-form-fail">
          <div>{t('errorContatcModule')}</div>
        </div>
        }
      </div>
    </div>
  </div>
</div>
</section>
</div>
<Footer></Footer>
    </>
    )
}
}
export default withTranslation()(Contatti); 