import { Component } from "react";
import { withTranslation } from "react-i18next";
import '../../style/profilo.css';
import { useLocation, useNavigate, useParams } from "react-router";
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import axios from "axios";
import * as Constanti from '../../Utils/constants.js';
import moment from "moment";
import { Link } from "react-router-dom";



class Viaggi extends Component{
  constructor(props){
  super(props);
  this.state = {
    viaggi:[],
    futuri:[],
    richiesti:[],
    passati:[],
    richiestaCanc:[],
    cancellati:[],

  }
  this.vediDettaglio = this.vediDettaglio.bind(this);
  }

componentDidMount = () =>{
  var u = JSON.parse(sessionStorage.getItem('user'));
  var apiBaseUrl = Constanti.url_Backend+"richiestaPrenotazioneByUser";
  var payload= {
      "idUser": u.id,
  }
  var self = this;
  axios.post(apiBaseUrl, payload).then(function (response) {
    var viaggi= response.data.data;
    self.setState({viaggi:viaggi});
    self.setState({futuri:viaggi.filter(data =>data.stato_prenotazione_id==1)});
    self.setState({richiesti:viaggi.filter(data =>data.stato_prenotazione_id==2)});
    self.setState({passati:viaggi.filter(data =>data.stato_prenotazione_id==3)});
    self.setState({richiestaCanc:viaggi.filter(data =>data.stato_prenotazione_id==4)});
    self.setState({cancellati:viaggi.filter(data =>data.stato_prenotazione_id==5)});

    console.log(viaggi);

    
  });
}


vediDettaglio = (viaggio) =>{
 
  this.props.navigate('/profilo/viaggi/dettaglio',{ state:
    {viaggio: viaggio}
  });

}

  render(){ 
    const {futuri,richiesti,passati,richiestaCanc,cancellati} = this.state;
    const {t,i18n} = this.props;
    
    return (
      <>

         <div data-w-tab="I miei viaggi" className="w-tab-pane">
                  <h2 className="h2 nomargintop">{t('viaggiTitle')}</h2>
                  <div  className="viaggi-tabs w-tabs">
                  <Tabs>
                    <div className="tabsmenusecondary w-tab-menu">
                   
                    <TabList>
                      <Tab className="tablinksecondary w-inline-block w-tab-link">{t('futuri')}</Tab>
                      <Tab className="tablinksecondary w-inline-block w-tab-link">{t('richiesti')}</Tab>
                      <Tab className="tablinksecondary w-inline-block w-tab-link">{t('passati')}</Tab>
                      <Tab className="tablinksecondary w-inline-block w-tab-link">{t('rich.cancellazione')}</Tab>
                      <Tab className="tablinksecondary w-inline-block w-tab-link">{t('cancellati')}</Tab>
                    </TabList>
                    </div>
                    <TabPanel>
                    <div className="viaggi-wrapper w-tab-content">
                      <div className="content-viaggi w-tab-pane">
                        {futuri?.map(el => (
                        <a  className="viaggio-wrap w-inline-block" key={el.id} onClick={(e)=>this.vediDettaglio(el)}>
                          <div className="viaggio-image" style={{ backgroundImage: `url(${el.gallery.length >0 ? el.gallery[0].url:''})` }}></div>
                          <div className="viaggio-details">
                            <h5 className="h5">{el.tour !== null ?  i18n.language === 'it'? el.tour?.titolo:el.tour?.titolo_en : i18n.language === 'it'? el.multy_days?.titolo : el.multy_days?.titolo_en}</h5>
                            <div className="viaggio-txt">{moment(el.start_date).format('DD MMM, YYYY')} {el.end_date ? " - "+moment(el.end_date).format('DD MMM, YYYY') :""}</div>
                            <div className="viaggio-txt">{el.n_adulti+el.numBambini03+el.numBambini410+el.numBambini1117} {t('viaggiatori')} {el.n_adulti_disabili>0 || el.n_bambini_disabili >0 ? '('+(el.n_adulti_disabili+el.n_bambini_disabili)+' '+t('viaggiatori.disabili')+')'  : ''}</div>
                          </div>
                        </a>
                        ))}
                      </div>
                      </div>
                    

                  </TabPanel>
                  <TabPanel>
                  <div className="viaggi-wrapper w-tab-content">
                      <div className="content-viaggi w-tab-pane">
                        {richiesti?.map(el => (
                          <a onClick={(e)=>this.vediDettaglio(el)} key={el.id} className="viaggio-wrap w-inline-block">
                          <div className="viaggio-image" style={{ backgroundImage: `url(${el.gallery.length >0 ? el.gallery[0].url:''})` }}></div>
                          <div className="viaggio-details">
                            <div className="status">in attesa di conferma</div>
                            <h5 className="h5">{el.tour !== null ?  i18n.language === 'it'? el.tour?.titolo:el.tour?.titolo_en : i18n.language === 'it'? el.multy_days?.titolo : el.multy_days?.titolo_en}</h5>
                            <div className="viaggio-txt">{moment(el.start_date).format('DD MMM, YYYY')} {el.end_date ? " - "+moment(el.end_date).format('DD MMM, YYYY') :""}</div>
                            <div className="viaggio-txt">{el.n_adulti+el.numBambini03+el.numBambini410+el.numBambini1117} {t('viaggiatori')} {el.n_adulti_disabili>0 || el.n_bambini_disabili >0 ? '('+(el.n_adulti_disabili+el.n_bambini_disabili)+' '+t('viaggiatori.disabili')+')'  : ''}</div>
                          </div>
                        </a>
                        ))}
                      </div>
                      </div>
                  </TabPanel>
                  <TabPanel>
                  <div className="viaggi-wrapper w-tab-content">
                      <div className="content-viaggi w-tab-pane">
                        {passati?.map(el => (
                      <a onClick={(e)=>this.vediDettaglio(el)} key={el.id} className="viaggio-wrap w-inline-block">
                          <div className="viaggio-image" style={{ backgroundImage: `url(${el.gallery.length >0 ? el.gallery[0].url:''})` }}></div>
                          <div className="viaggio-details">
                                                     <h5 className="h5">{el.tour !== null ?  i18n.language === 'it'? el.tour?.titolo:el.tour?.titolo_en : i18n.language === 'it'? el.multy_days?.titolo : el.multy_days?.titolo_en}</h5>
                            <div className="viaggio-txt">{moment(el.start_date).format('DD MMM, YYYY')} {el.end_date ? " - "+moment(el.end_date).format('DD MMM, YYYY') :""}</div>
                            <div className="viaggio-txt">{el.n_adulti+el.numBambini03+el.numBambini410+el.numBambini1117} {t('viaggiatori')} {el.n_adulti_disabili>0 || el.n_bambini_disabili >0 ? '('+(el.n_adulti_disabili+el.n_bambini_disabili)+' '+t('viaggiatori.disabili')+')'  : ''}</div>
                          </div>
                        </a>
                        ))}
                      </div>
                      </div>
                  </TabPanel>
                  <TabPanel>
                  <div className="viaggi-wrapper w-tab-content">
                      <div className="content-viaggi w-tab-pane">
                        {richiestaCanc?.map(el => (
                        <a  onClick={(e)=>this.vediDettaglio(el)} key={el.id} className="viaggio-wrap w-inline-block cancellazione-cancellati">
                          <div className="viaggio-image" style={{ backgroundImage: `url(${el.gallery.length >0 ? el.gallery[0].url:''})` }}></div>
                          <div className="viaggio-details">
                                                     <h5 className="h5">{el.tour !== null ?  i18n.language === 'it'? el.tour?.titolo:el.tour?.titolo_en : i18n.language === 'it'? el.multy_days?.titolo : el.multy_days?.titolo_en}</h5>
                            <div className="viaggio-txt">{moment(el.start_date).format('DD MMM, YYYY')} {el.end_date ? " - "+moment(el.end_date).format('DD MMM, YYYY') :""}</div>
                            <div className="viaggio-txt">{el.n_adulti+el.numBambini03+el.numBambini410+el.numBambini1117} {t('viaggiatori')} {el.n_adulti_disabili>0 || el.n_bambini_disabili >0 ? '('+(el.n_adulti_disabili+el.n_bambini_disabili)+' '+t('viaggiatori.disabili')+')'  : ''}</div>
                          </div>
                        </a>
                        ))}
                      </div>
                      </div>
                  </TabPanel>
                  <TabPanel>
                  <div className="viaggi-wrapper w-tab-content">
                      <div className="content-viaggi w-tab-pane">
                        {cancellati?.map(el => (
                        <a onClick={(e)=>this.vediDettaglio(el)} key={el.id} className="viaggio-wrap w-inline-block cancellazione-cancellati">
                          <div className="viaggio-image" style={{ backgroundImage: `url(${el.gallery.length >0 ? el.gallery[0].url:''})` }}></div>
                          <div className="viaggio-details">
                                                     <h5 className="h5">{el.tour !== null ?  i18n.language === 'it'? el.tour?.titolo:el.tour?.titolo_en : i18n.language === 'it'? el.multy_days?.titolo : el.multy_days?.titolo_en}</h5>
                            <div className="viaggio-txt">{moment(el.start_date).format('DD MMM, YYYY')} {el.end_date ? " - "+moment(el.end_date).format('DD MMM, YYYY') :""}</div>
                            <div className="viaggio-txt">{el.n_adulti+el.numBambini03+el.numBambini410+el.numBambini1117} {t('viaggiatori')} {el.n_adulti_disabili>0 || el.n_bambini_disabili >0 ? '('+(el.n_adulti_disabili+el.n_bambini_disabili)+' '+t('viaggiatori.disabili')+')'  : ''}</div>
                          </div>
                        </a>
                        ))}
                      </div>
                      </div>
                  </TabPanel>
                                       </Tabs>
                  </div>
                </div>
                
        </>
  );
}
}
export default  withTranslation()((props) => (
  <Viaggi
      {...props}
      params={useParams()}
      navigate={useNavigate()}
  />
)); 