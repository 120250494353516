import React, { Component } from "react";
import '../../style/modal.css';
import { Col, Modal, Row,Button } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import imgRegistrati from '../../images/registratiModal.png';
import close from '../../images/ximage.png';
import showPwdImg from '../../images/show-password.svg';
import hidePwdImg from '../../images/hide-password.svg';
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { Navigate } from "react-router";
import { UserContext } from "../context/UserContext";
import context from "react-bootstrap/esm/AccordionContext";
import imgLogin from '../../images/accedi-img.jpg';
import ReactMarkdown from "react-markdown";




class ModalChiSiamo
 extends Component{
  static contextType = UserContext;
    constructor(props){
    super(props);
    this.state = {
      show: this.props.show,
    }

  this.handleClose =this.handleClose.bind(this);
  
    }

  
    handleClose(){
     this.props.close();
    }

  render(){
    const {show} = this.state;
    const {t,i18n,membro} = this.props;
    return (
        <>
        <Modal show={show}  className="modalChiSiamo"
        onHide={this.handleClose}>
        <Modal.Body>
        <Row className="alighRight">
            <button  onClick={this.handleClose} className="buttonSlider text-right"><img className="prev-slick-arrow" src={close}></img></button>
        </Row>
        <div className="flex-content-row marginBottom50">
          <div>
          <img src={membro?.thumbnail} loading="lazy" alt="" className="imgMembro modalImg"></img>
          </div>
          <div className="marginLeft">
            <div className="titleCardMember marginMember">{membro?.denominazione}</div>
            <div className="roleCardMember marginMember">{i18n.language === 'it'? membro?.ruolo : membro?.ruolo_en}</div>
          </div>
        </div>
       
        <Row>
        <ReactMarkdown>{i18n.language === 'it'? membro?.descrizione : membro?.descrizione_en}</ReactMarkdown>
        </Row>
        </Modal.Body>
        </Modal>
        </>
    )
}
}
export default  withTranslation()(ModalChiSiamo); 
