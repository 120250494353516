import React, { Component } from "react";
import '../../style/home.css';
import { Col, Row } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import Slider from "react-slick";
import arrPrev from '../../images/backArrow.png';
import nextArrow from '../../images/nextArrow.png';
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { Link } from "react-router-dom";

class ToursHome extends Component{
  constructor(props){
    
    super(props);
    this.state = {
      tours: [],
      }
      this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
}

next() {
  this.slider.slickNext();
}
previous() {
  this.slider.slickPrev();
}


  componentDidMount() {

      var apiBaseUrl = Constanti.url_Backend+"getAllTours";
      var self = this;
      axios.get(apiBaseUrl).then(function (response) {
      if (response.data.data != null){
         var location = response.data.data;
         self.setState({ tours : location })
      }else{
        console.log("non ci sono tour per questo id");
      }
      self.setState({load:false});
    })
    .catch(function (error) {
    console.log(error);
    self.setState({load:false});
    });

  }

  render(){
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
    };
    const { tours } = this.state;
    const { t,i18n } = this.props;
    return (
      <div className="container-Tours">
      <div className="d-flex justify-content-between">
      <span className="titleTours">{t("offerte.label")}</span>
      <div className="divButton">
      <button className="buttonSlider" onClick={this.previous}>
          <img className="prev-slick-arrow" height={16}  width={16} src={arrPrev}/>
          </button>
          <button className="buttonSlider" onClick={this.next}>
          <img className="next-slick-arrow" src={nextArrow}  height={16}  width={16} />
          </button>
          </div>
      </div>
      
      <div className="container-home">
       
    <Slider ref={c => (this.slider = c)} {...settings}>
        
     
        {tours != null && tours?.map(el => (
          <div className="paddingRight5" key={el}>
          <Link to={'/tour/'+el.id}>
          
          <div className="card" style={{border: "none"}}>
            <img className="card-img-top img-tour-home" src={el.galleryImg[0].url} alt="Card image cap"/>
            <div className="card-body card-flex">
              <h5 className="card-title-tours" style={{backgroundColor:`${el.categoria_tour.colore}` }}><span>{i18n.language === 'it'? el.categoria_tour.categoriaTours : el.categoria_tour.categoriaTours_en}</span></h5>
              <p className="card-text-tours">{i18n.language === 'it'? el.titolo : el.titolo_en}</p>
              <hr className="solid"></hr>
              <p className="card-text-price"><span className="spanDa">{t("da")}</span><span className="spanCosto"> <b>{el.costo}€</b>  p.p.</span></p>
            </div>
          </div>
          </Link>
              </div>
          
        
          
          ))}
     
      </Slider>
   
      </div>
    </div>
    )
}
}
export default withTranslation()(ToursHome); 





