import React, { Component } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../style/tour.css';
import { useParams } from "react-router";
import { Col} from "react-bootstrap";
import { withTranslation } from "react-i18next";



class HeadTour
 extends Component{
    constructor(props){
     super(props);
    
     this.changeButton = this.changeButton.bind(this);
    }
    
    changeButton(){
     this.props.onShowLightbox();
    }
  

  render(){
    const {t,i18n} = this.props;
    return (
        <>
    <div className="tourHeadMobile">
    {this.props.listImg !== undefined && !this.props.listImg !== null &&
      <Col className="positionRelative">
              <img src={this.props.listImg[0].url} className="imgHeadTour"></img>
              <button className="buttonHeadTour" onClick={this.changeButton} >{t('allfoto')}</button>
      </Col>
    }
    </div>
    <div className="tourHead">
    {this.props.listImg !== undefined && !this.props.listImg !== null &&
          <div className="rowHeadTour">
            <div  className="positionRelative colTour">
              <img src={this.props.listImg[0].url} alt="" className="imgHeadTour"></img>
              <button className="buttonHeadTour" onClick={this.changeButton} >{t('allfoto')}</button>
            </div>
            <div className="colTour">
              <div className="row-2">
              <img src={this.props.listImg[1].url} alt="" className="imgHeadTour"></img>
              </div>
              <div className="row-3" style={{width:"100%"}}>
              <div className="colTour-1">
              <img src={this.props.listImg[2].url} alt="" className="imgHeadTour"></img>
              </div>
              <div className="colTour-1">
              <img src={this.props.listImg[3].url} alt="" className="imgHeadTour"></img>
              </div>
              </div>
            </div>
          
          </div>
        }
   
   </div>
 
</>

    )
}
}
export default withTranslation()((props) => (
  <HeadTour
      {...props}
      params={useParams()}
  />
)); 
