import React, { Component } from "react";
import '../../style/modal.css';
import {Modal, Row } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import imgRegistrati from '../../images/registratiModal.png';
import close from '../../images/ximage.png';
import showPwdImg from '../../images/show-password.png';
import hidePwdImg from '../../images/hide-password.png';
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router";
import { UserContext } from "../context/UserContext";
import context from "react-bootstrap/esm/AccordionContext";
import imgLogin from '../../images/accedi-img.jpg';
import alert from '../../images/alert.png';
import Pdf from '../../documents/condizioni.pdf';




class ModalLogin
 extends Component{
  static contextType = UserContext;
    constructor(props){
    super(props);

    this.state = {
        show: this.props.show,
        email:"",
        nome:"",
        cognome:"",
        password:"",
        accedi:false,
        resetPWD:false,
        isRevealPwd:false,
        submitted:false,
        submittedRegister:false,
        showPrenotazione:false,
        showProfilo:false,
        user:null,
        showErrorCredential:false,
        showErrorRegistrazione:false,
        feedbacOK:false,
        feedbacKO:false
    }
  this.handleClose =this.handleClose.bind(this);
  this.handleChange = this.handleChange.bind(this);
  this.showAccedi = this.showAccedi.bind(this);
  this.hideAccedi = this.hideAccedi.bind(this);
  this.setIsRevealPwd = this.setIsRevealPwd.bind(this);
  this.login = this.login.bind(this);
  this.registrazione =  this.registrazione.bind(this);
  this.pwdDimenticata = this.pwdDimenticata.bind(this);
  this.reset = this.reset.bind(this);
  
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name] : value });
       
    }
  
    handleClose(){
     this.setState({show:false});
     this.props.close();
    }

    showAccedi(){
      this.setState({accedi:true,submitted:false,submittedRegister:false});
     
     }

     hideAccedi(){
      this.setState({accedi:false,submitted:false,submittedRegister:false});
     
     }

     setIsRevealPwd(){
      this.setState({isRevealPwd: !this.state.isRevealPwd});
     }

     pwdDimenticata(){
      this.setState({resetPWD: true});
     }

     reset(){
      if (this.state.email !==''){
        var apiBaseUrl = Constanti.url_Backend+"sendRequestResetPwdWeb";
        var payload={
          "email":this.state.email
        }
        var self = this;
        axios.post(apiBaseUrl, payload).then(function (response) {
          var response= response.data.data;
          if (response=="KO"){
            self.setState({feedbacKO: false});
          }else{
            self.setState({feedbacOK: true});
           self.setState({resetPWD: false});
          }
        });
      }
     }


    login(){
    
      this.setState({submitted:true}); 
    if (this.state.accedi && this.state.email !=='' && this.state.password !=='' ){
     const { history } = this.props;
     this.setState({submitted:true}); 
      var apiBaseUrl = Constanti.url_Backend+"login";
      var payload={
        "password":this.state.password,
        "email":this.state.email
      }
     
      var self = this;
      axios.post(apiBaseUrl, payload).then(function (response) {
        var userLogin= response.data.data;
        if (userLogin !== 'KO'){
          self.setState({showErrorCredential:false}); 
          const context = self.context;
          context.setUser(userLogin);
          sessionStorage.setItem('user',JSON.stringify(userLogin))

        if (sessionStorage.getItem('obj') != null){
          self.props.navigate('/prenotazione',{ state:  {user: userLogin,
          tour: JSON.parse(sessionStorage.getItem('obj')),
          prenotazione: self.props.prenotazione}});
          self.setState({show:false});
          self.props.close();
          //self.setState({showPrenotazione:true,user:userLogin});
          
        }else{
          self.props.navigate('/profilo/datipersonali',{ state:{user: userLogin,
            tour: JSON.parse(sessionStorage.getItem('obj')),
            prenotazione: self.props.prenotazione}});
         
            self.setState({show:false});
     self.props.close();
          //self.setState({showProfilo:true,user:userLogin});
        }
      }else{
        self.setState({showErrorCredential:true}); 
      }
      });
    }

     }

     registrazione(){
    
      this.setState({submittedRegister:true}); 
      
    if (!this.state.accedi
      &&  this.state.email !=='' && this.state.password !=='' && this.state.nome !=='' && this.state.cognome !==''){
      var apiBaseUrl = Constanti.url_Backend+"registrazione";
      var payload={
        "name": this.state.nome,
        "surname":this.state.cognome,
        "password":this.state.password,
        "email":this.state.email
      }
     
      var self = this;
      axios.post(apiBaseUrl, payload).then(function (response) {
        var user = response.data.data;
        if (user !== 'esiste già un utente con questa email'){
        const context = self.context;
        context.setUser(user);
        sessionStorage.setItem('user',JSON.stringify(user))
        if (sessionStorage.getItem('obj') != null){
        self.props.navigate('/prenotazione',{ state:{user: user,
          tour:  JSON.parse(sessionStorage.getItem('obj')),
          prenotazione: self.props.prenotazione}});
        self.setState({show:false});
        self.props.close();
    //    self.setState({showPrenotazione:true,user:user});
        
       }else{
        self.props.navigate('/profilo/datipersonali',{ state:
          {user: user,
          tour: JSON.parse(sessionStorage.getItem('obj')),
          prenotazione: self.props.prenotazione}
        });
        self.setState({show:false});
        self.props.close();
        
       // self.setState({showProfilo:true,user:user});
       }
      }else{
        self.setState({showErrorRegistrazione:true})
      }
      });
    }
   
    }

    componentDidMount() {
      if (this.props.isLogin != undefined && this.props.isLogin != null && this.props.isLogin ==='login')
      this.setState({accedi: this.props.isLogin});
      
      console.log(this.props.prenotazione);
    }
  
    _initProfile() {
      const context = this.context;
      //Let's fill the context with some value! You can get it from db too.
      const profileData = {
        fullName: 'John Doe',
        username: 'johndoe',
        region: 'EU',
        age: 20
      }
      //Call our setProfile method that we declared in App Component.
      context.setUser(profileData);
    }
    
  render(){
    const {show,email,nome,password,cognome,accedi,isRevealPwd,submitted,submittedRegister,showErrorCredential,resetPWD,showErrorPWD,showErrorRegistrazione,feedbacOK,feedbacKO} = this.state;
    const {t,i18n } = this.props;
    return (
        <>
        <Modal  show={show} className="modalRegistrati"
        onHide={this.handleClose}>
        <Modal.Body>
        {!accedi  && !resetPWD && 
          <div className="flex-content-around paddingBottom10">
            <div className="img-content">
             <img src={imgRegistrati} alt="Registrazione" className="imgRegistrati"></img>
            </div>
            <div className="bloccoForm" >
            <Row className="alighRight">
            <button  onClick={this.handleClose} className="buttonSlider text-right"><img className="prev-slick-arrow" alt="Close" src={close}></img></button>
            </Row>
            <div className="marginTop10 marginBottom20 titoloModalAccedi flex-column-center"><span className="title-modal"> {t("registrati")}</span></div>
            <div className="inputModalDiv">
            <label className="labelModalRegistrazione">{t("email")}</label>
            <input onChange={this.handleChange} type="email" className="box-prenotazione-input input-modal" name="email" value={email} placeholder={t("scriviEmail")}></input>
            {submittedRegister && email==='' &&
                            <div className="help-block">{t("emailRequired")}</div>
            }
            <label className="labelModalRegistrazione">{t("nome")}</label>
            <input onChange={this.handleChange}  className="box-prenotazione-input input-modal" name="nome" value={nome} placeholder={t("scriviNome")}></input>
            {submittedRegister && nome==='' &&
                            <div className="help-block">{t("nomeRequired")}</div>
            }
            <label className="labelModalRegistrazione">{t("cognome")}</label>
            <input onChange={this.handleChange}  className="box-prenotazione-input input-modal" name="cognome" value={cognome} placeholder={t("scriviCongnome")}></input>
            {submittedRegister && cognome==='' &&
                            <div className="help-block">{t("cognomeRequired")}</div>
            }
            <div className="pwd-container">
            <label className="labelModalRegistrazione">Crea password</label>
            <input onChange={this.handleChange} type={isRevealPwd ? "text" : "password"}  className="box-prenotazione-input input-modal marginBottom0" name="password" value={password} placeholder={t("scriviPwd")}></input>
            <img title={isRevealPwd ? "Hide password" : "Show password"} alt={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? hidePwdImg : showPwdImg} onClick={this.setIsRevealPwd}  />
            {submittedRegister && password==='' &&
                            <div className="help-block">{t("passwordRequired")}</div>
            }
            {submittedRegister && password.length < 8 &&
              <div className="help-block">{t("lenghtPasswordRequired")}</div>
            }
            </div>
            <label className="labelModalRegistrazioneGrey marginBottom20">{t("8caratteri")}</label>
            {showErrorRegistrazione  && 
            <div className="help-block-login"><img src={alert} alt="" height={24} width={24}></img><span className="textError">{t("errorEmailExsisting")}</span></div>
            }
            <button className="btn-login" onClick={this.registrazione}> {t("registrati")}</button>
            <label className="privacy marginTop10">{t("termini")} <span className="linkTermini">  <a href = {Pdf} target = "_blank">{t("termini1")}</a></span> {t("termini2")} <span className="linkTermini">{t("termini3")}</span> </label>
            <div className="text-center marginTop30 marginBottom20"><label className="privacyLogin">{t("login1")}
            <button type="button" className="btn btn-link privacy AccRegi" onClick={this.showAccedi}>{t("login2")}</button>
            </label></div>
            
            </div>
            
            </div>

           </div>
        }
         { accedi && !resetPWD &&
          <div className="flex-content-around accedi">
            <div  className="img-content" >
             <img src={imgLogin} className="imgRegistrati" alt="Accedi"></img>
            </div>
            <div  className="bloccoForm marginAuto">
            <div className="titoloModalAccedi flex-column-center"><span className="title-modal">{t("login2")}</span></div>
            <div className="inputModalDiv">
            <label className="labelModalRegistrazione">{t("email")}</label>
            <input onChange={this.handleChange} type="email" className="box-prenotazione-input input-modal" name="email" value={email} placeholder={t("scriviEmail")}></input>
            {submitted && email==='' &&
                            <div className="help-block">{t("emailRequired")}</div>
            }
            <div className="pwd-container">
            <label className="labelModalRegistrazione">Password</label>
            <input onChange={this.handleChange} type={isRevealPwd ? "text" : "password"}  className="box-prenotazione-input input-modal marginBottom0" name="password" value={password} placeholder={t("scriviPwd")}></input>
            <img title={isRevealPwd ? "Hide password" : "Show password"} alt={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? hidePwdImg : showPwdImg} onClick={this.setIsRevealPwd}  />
            {submitted && password==='' &&
                            <div className="help-block">{t("passwordRequired")}</div>
            }
            </div>
            <button type="button" className="btn btn-link privacy pwdDimenticata" onClick={this.pwdDimenticata}>{t("pwdDimenticata")}</button>
            <button className="btn-login" onClick={this.login}> {t("login2")}</button>
            {showErrorCredential  && 
            <div className="help-block-login inputModalDiv marginTop16"><img src={alert} alt="" height={24} width={24}></img><span className="textError">{t("errorCredential")}</span></div>
            }
            <div className="text-center marginTop160 marginBottom20"><label className="privacyLogin">{t("login3")}
            <button type="button" className="btn btn-link privacy AccRegi" onClick={this.hideAccedi}>{t("registrati")}</button>
            </label></div>
            </div>
            </div>
            <Row className="alighRight">
            <button  onClick={this.handleClose} className="buttonSlider text-right"><img className="prev-slick-arrow" alt="close" src={close}></img></button>
            </Row>
          </div>
          }
          {resetPWD && 
            <div className="flex-content-around accedi">
            <div  className="img-content" >
             <img src={imgLogin} className="imgRegistrati" alt="Accedi"></img>
            </div>
            <div  className="bloccoForm marginAuto">
            <div className="titoloModalAccedi flex-column-center"><span className="title-modal">{t("resetPwd")}</span></div>
            {showErrorPWD  && 
            <div className="help-block-login"><img src={alert} alt="" height={24} width={24}></img><span className="textError">{t("errorCredential")}</span></div>
            }
            <div className="inputModalDiv">
            <label className="labelModalRegistrazione">{t("email")}</label>
            <input onChange={this.handleChange} type="email" className="box-prenotazione-input input-modal" name="email" value={email} placeholder={t("scriviEmail")}></input>
            {submitted && email==='' &&
                            <div className="help-block">{t("emailRequired")}</div>
            }
            <button className="btn-login" onClick={this.reset}> {t("inviaRichiesta")}</button>
            </div>
            </div>
            <Row className="alighRight">
            <button  onClick={this.handleClose} className="buttonSlider text-right"><img className="prev-slick-arrow" alt="close" src={close}></img></button>
            </Row>
            {feedbacOK && 
            <Row className="alighRight">
            <div>{t("resetPwdFeedbackOK")}</div>
            </Row>
            }
            {feedbacKO && 
            <Row className="alighRight">
            <div>{t("resetPwdFeedback")}</div>
            </Row>
            }
          </div>
          }
          <div style={{width:"100%",height:"80px"}}></div>
        </Modal.Body>
        </Modal>
        </>
    )
}
}
export default  withTranslation()((props) => (
  <ModalLogin
      {...props}
      params={useParams()}
      navigate={useNavigate()}
  />
)); 
