import React, { Component } from "react";
import '../../style/home.css';
import { Col, Row } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import tours from '../../images/tours-home.png';
import multidays from '../../images/multiDaysHome.png';

class CosaOffriamoHome extends Component{

  render(){
    const { t,i18n } = this.props;
    return (
        <>
         <div className="offertaHome">
        <div>
         <div className="titleOfferta">{t("offerta.label")}</div>
         <div className="subtitle-card-home text-center" style={{marginTop:"16px",maxWidth:'600px',marginLeft:"auto",marginRight:"auto"}}>{t("offerta.subtitle.label")} {t("offerta.subtitle2.label")}</div>
      </div>
         <div className="flexColum onlyCenter marginTop40">
         <div className="card cardCosaOffriamo" >
            <img className="card-img-top cosaOffriamoImg" src={tours} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title">{t("tours.label")}</h5>
              <p className="card-text">{t("toursTesto.label")}</p>
           
            </div>
          </div>
          <div className="card cardCosaOffriamo" >
            <img className="card-img-top cosaOffriamoImg" src={multidays} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title">{t("multy.label")}</h5>
              <p className="card-text">{t("multyTesto.label")}</p>
            </div>
          </div> 
         </div>
         </div>
</>
    )
}
}
export default withTranslation()(CosaOffriamoHome); 
