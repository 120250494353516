import { Component } from "react";
import { withTranslation } from "react-i18next";
import '../../style/profilo.css';
import { useLocation, useParams } from "react-router";
import 'react-tabs/style/react-tabs.css';
import Accordion from 'react-bootstrap/Accordion';
import ModalSingolaAccessibilita from "./modalSingolaAccessibilita";
import { WithContext as ReactTags } from 'react-tag-input';
import alert from '../../images/alert.png';
import { Link } from "react-router-dom";


class Accessibilita extends Component{
  constructor(props){
  super(props);
  this.state = {
    showModal:false,
    selectedOption:null,
    user:null,
    steps:[
      { id:1,key: 'primoStep',  isDone: true,domanda:"Che tipo di viaggiatore sei?",risposte:['Viaggiatore con disabilità','Accompagnatore di viaggiatore con disabilità'],rispostaUser:null },
      { id:2,key: 'secondoStep', isDone: false,domanda:"Che tipo di disabilità hai o ha il viaggatore che accompagni?",risposte:['Motoria','Sensoriale-Udito','Sensoriale - Vista','Psichico/Intellettiva'],rispostaUser:null},
      { id:3,key: 'terzoStep',  isDone: false,domanda:"Il viaggiatore disabile necessita di un supporto per muoversi? se si quale?",risposte:['Sedia a rotelle','Motorino elettrico','Bastoni','Altro','No'],rispostaUser:null },
      { id:4,key: 'quartoStep',  isDone: false,domanda:"E' di un aiuto aggiuntivo per accompagnare il viaggiatore disabile?",risposte:['Si','No'],rispostaUser:null },
      { id:5,key: 'quintoStep', isDone: false,domanda:"Tu o il viaggiatore che accompagni avete necessità alimentari particolari?",risposte:['Vegetariano/Vegano','Celiaco','Allergia','Diabete','No'],rispostaUser:null },
      { id:6,key: 'sestoStep',  isDone: false,domanda:"Hai necessità di supporti aggiuntivi?",risposte:['Supporti per il bagno','Altro (Animali che provvedano all’assistenza, Respiratore/bombola dell’ossigeno,Protesi, Sedia a rotelle, Sedia motorizzata, Scooter, Bretelle, Stampelle, Deambulatore)','Cintura da sedia a rotelle','Sollevatore','Nessun dispositivo di supporto'],rispostaUser:null }
    ],   
  }
  this.openModal = this.openModal.bind(this);
  
  }

  updateState = (val) => {
    this.setState({user: val });
  }

 openModal (val){
  this.setState({showModal:true,selectedOption:val});
 }

 closeModalChange = () => {
  this.setState({showModal: false });
}

recuperaAccessibilita(acc){
  var risposta = this.state.user?.accessibilita?.filter((accessibilita) => accessibilita.id == acc.id);
  if (risposta !== undefined)
  return risposta[0]?.testo_risposta;
  return "";
}

componentDidMount= ()=>{
 var u = JSON.parse(sessionStorage.getItem('user'));
 if (u.accessibilita == undefined){
  u.accessibilita = null
 }
this.setState({user:u})

}


  render(){ 
    const {showModal,selectedOption,user,steps} = this.state;
    const {t,i18n} = this.props;
    if (user?.accessibilita != null && user?.accessibilita.length >0){
    return (
      <>
                 <div data-w-tab="Profilo accessibilità" className="w-tab-pane">
                  <h2 className="h2 nomargintop">{t('accessibilita.prodilo')}</h2>
                  <p className="p description">{t('testo.accessibilita')}</p>
                  <>
                  <div className="argomento_domande">
                   <div className="accordion_wrapper">
                      <div className="accordion_item">
                      <Accordion>
                          <Accordion.Item eventKey="0">
                          <Accordion.Header><h5 className="h5">{t('titleWizardAcc')}</h5></Accordion.Header>
                          <Accordion.Body>
                        <div className="accordion_item_trigger">
                        <div className="icon_container faq"><img width="24" src="images/icon-down.png" alt="" className="down_arrow"/></div>
                        </div>
                        { steps.map((acc)=>
                        <div className="accordion_item_content" key={acc.id}>
                        <div className="content_wrapper flex">
                          <div className="domanda">{acc.domanda}</div>
                          <button onClick={()=>this.openModal(acc)} className="button-tertiary _20 w-button btn-link no-border">{t('modifica')}</button>
                        </div>
                            <p className="risposta">{this.recuperaAccessibilita(acc)}</p>
                        </div>
                      )
                      }
                      </Accordion.Body>
                      </Accordion.Item>
                      </Accordion>
                      </div>
                    </div>
                    </div>
                  <div className="argomento_domande">
                    <div className="accordion_wrapper">
                      <div className="accordion_item">
                      <Accordion>
                          <Accordion.Item eventKey="1">
                          <Accordion.Header> <h5 className="h5">{t('commenti.pef')}</h5></Accordion.Header>
                          <Accordion.Body>
                        <div className="accordion_item_trigger">
                         
                          <div className="icon_container faq"><img width="24" src="images/icon-down.png" alt="" className="down_arrow"/></div>
                        </div>
                        
                        <div className="accordion_item_content">
                        <div className="content_wrapper flex">
                          <div className="domanda">{t('destinazioni.future')}</div>
                          <button onClick={()=>this.openModal('destinazioni')} className="button-tertiary _20 w-button btn-link no-border">{t('modifica')}</button>
                       </div>
                            <p className="risposta" style={{textTransform: "capitalize"}}>{user?.tags}</p>
                       </div>
                        <div className="accordion_item_content">
                        <div className="content_wrapper flex">
                          <div className="domanda">{t('feedback')}</div>
                          <button onClick={()=>this.openModal('feedback')} className="button-tertiary _20 w-button btn-link no-border">{t('modifica')}</button>
                          </div>
                          <p className="risposta">{user?.commenti}</p>
                          
                        </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      </Accordion>
                      </div>
                    </div>
                  </div>
                  </>
                  
                  {showModal && 
                    <ModalSingolaAccessibilita show={showModal} domanda={selectedOption}  close={this.closeModalChange} user={user} update={this.updateState}></ModalSingolaAccessibilita>
                  }
                </div>
              
                
        </>
  );
}else{
  return (
    <>
               <div data-w-tab="Profilo accessibilità" className="w-tab-pane">
                <h2 className="h2 nomargintop">{t('accessibilita.prodilo')}</h2>
                <p className="p description">{t('testo.accessibilita')}</p>
                 <div className="accessibilitaVuota">
                 <div className="flex-row-bottom bottomSolid">
                  <img src={alert} alt="" height={24} width={24}></img><span className="alertMsg">Non hai ancora compilato il tuo profilo accessibiliità. Inizia ora.</span>
                  </div> 
                  <Link to="/wizardAccessibilita">
                  <button className="buttonWizard buttonWizardAccessibilita">Compila profilo accessibiliità</button>
                  </Link>
                 </div>
                {showModal && 
                  <ModalSingolaAccessibilita show={showModal} domanda={selectedOption}  close={this.closeModalChange} user={user} update={this.updateState}></ModalSingolaAccessibilita>
                }
              </div>
            
              
      </>
);
}
}
}
export default  withTranslation()(Accessibilita); 
