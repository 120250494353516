import React, { Component } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../style/home.css';
import '../../style/destination.css';
import Footer from "../molecule/footer";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import { useLocation, useParams } from "react-router";
import Headdestinazioni from "./headdestinazioni";
import CorpoDestinazioni from "./corpoDestinazioni";



class DestinazioneDetail
 extends Component{
    constructor(props){
    
        super(props);
        this.state = {
          location: null,
         }
  
    }
    componentDidMount() {

      if (this.props.params.id !== 'null'){
        this.setState({load:true});
        var apiBaseUrl = Constanti.url_Backend+"getDestinationDetail?id_destinazione="+this.props.params.id;
         var payload = {
          "id_destinazione": this.props.params.id
        }
        var self = this;
        axios.get(apiBaseUrl).then(function (response) {
        if (response.data.data != null){
           var location = response.data.data[0];
           self.setState({ location : location })
        }else{
          console.log("non ci sono location");
        }
        self.setState({load:false});
      })
      .catch(function (error) {
      console.log(error);
      self.setState({load:false});
      });
      }
    }

  render(){
    console.log(this.props);
    const { location} = this.state;
    return (
        <>
        <Headdestinazioni location={location} key="hed"></Headdestinazioni>
  
         {this.state.location !== undefined && !this.state.location !== null &&
          <div>
            
          <CorpoDestinazioni destinazione={location} key={location}></CorpoDestinazioni>  
          </div>
         }
  
            <Footer></Footer>
   
</>

    )
}
}
export default  (props) => (
  <DestinazioneDetail
      {...props}
      params={useParams()}
      location={useLocation}
  />
); 
