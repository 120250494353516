import React, { Component } from "react";
import '../../style/home.css';
import { Col, Row } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import homePlace from '../../images/homePlace.png';
import play from '../../images/play.png';

class PercheHome extends Component{

  render(){
    const { t,i18n } = this.props;
    return (
        <>
         <div className="componente-perch">
         
         <div className="contenitore-Perche">
         <img className="img-responsive center-block d-block mx-auto img-perche perchèHomeImg" src={homePlace} alt="Card image cap"/>
         <div className="titolo-perche">
              {t("titolo.perchè.label")}
            </div>
        <div className="flexColum marginTop30">
      <div className="blockPerche">
            <div className="titolo-interno-perche"> {t("titolo.paragrafo1.label")}</div>
            <div className="testo-perche">{t("testo.paragrafo1.label")}</div>
      </div>
      <div className="blockPerche"> 
            <div className="titolo-interno-perche"> {t("titolo.paragrafo2.label")}</div>
            <div className="testo-perche">{t("testo.paragrafo2.label")}</div>
        </div>
        <div className="blockPerche"> 
            <div className="titolo-interno-perche"> {t("titolo.paragrafo3.label")}</div>
            <div className="testo-perche">{t("testo.paragrafo3.label")}</div>
        </div>
         </div>
         </div>
        </div>
        
</>
    )
}
}
export default withTranslation()(PercheHome); 
