import React, { Component } from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import '../../style/header.css';
import logo from '../../images/logo.png';
import en from '../../images/en.png';
import it from '../../images/it.png';
import profile from '../../images/profile-icon.png';
import xicon from '../../images/close-menu.png';
import open from '../../images/burger-menu.png';
import "../../../node_modules/jquery/dist/jquery.min.js";
import "../../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../../../node_modules/bootstrap/js/src/collapse.js";

import { withTranslation } from 'react-i18next';
import { UserContext } from "../context/UserContext";
import ModalLogin from "../ModalLogin/modalLogin";
import {Col, Row } from "react-bootstrap";


class CustomHeader extends Component{
  static contextType = UserContext;
  constructor(props){
  super(props);
  this.state = {
    select: null,
    user:null,
    showModal:false,
    tipoModale:'',
    show:false,
    isNavCollapsed:false
};

this.showModalChange = this.showModalChange.bind(this);
this.showModalChangeRegistra = this.showModalChangeRegistra.bind(this);
this.handleClick = this.handleClick.bind(this);
this.showLink = this.showLink.bind(this);
this.wrapperRef = React.createRef();
this.handleClickOutside = this.handleClickOutside.bind(this);
}

handleClick = val => {
  this.handleNavCollapse();
  this.setState({show : false, select : val});
  if (val ==='logout'){
    this.props.navigate('/');
    this.props.navigate(0);
    sessionStorage.clear();
   this.context.setUser(null);
 
  }
};

showModalChange(){
    sessionStorage.removeItem('prenotazione');
    this.setState({showModal : true,tipoModale:'login' });
}
showModalChangeRegistra(){
  sessionStorage.removeItem('prenotazione');
  this.setState({showModal : true,tipoModale:'registra' });
}
closeModalChange = () => {
  this.setState({showModal : false });
}

showLink(){
  this.setState({show : !this.state.show });
}

handleNavCollapse = () => {

 this.setState({isNavCollapsed : !this.state.isNavCollapsed });
}

componentDidUpdate(prevProps){
  if (window.location.pathname !== ''){
    var select = 0;
    if (window.location.pathname ==='/destinazioni' || window.location.pathname.includes('/destinazioni')){
     select = 1;
    }else if (window.location.pathname ==='/virtualTour'){
      select = 2
    }else if (window.location.pathname ==='/chiSiamo'){
      select = 3
    }else if (window.location.pathname ==='/blog' || window.location.pathname.includes('/blog')){
      select = 4
    }else if (window.location.pathname ==='/contatti'){
      select = 5
    }
    if (this.state.select != select ){
      this.setState({select : select});
    }
  }
}

componentDidMount() {
  document.addEventListener("mousedown", this.handleClickOutside);
  var u = JSON.parse(sessionStorage.getItem('user'));
  if (u != null){
   this.context.setUser(u);
          
  }
 if (window.location.pathname !== ''){
   if (window.location.pathname ==='/destinazioni'){
    this.setState({select : 1});
   }else if (window.location.pathname ==='/virtualTour'){
    this.setState({select : 2});
   }else if (window.location.pathname ==='/chiSiamo'){
    this.setState({select : 3});
   }else if (window.location.pathname ==='/blog'){
    this.setState({select : 4});
   }else if (window.location.pathname ==='/contatti'){
    this.setState({select : 5});
   }

 }
}

handleClickOutside(event) {
  if (this.wrapperRef && this.wrapperRef.current != null && !this.wrapperRef.current.contains(event.target)) {
    this.setState({show: false});
  }
 
}
componentWillUnmount() {
  document.removeEventListener("mousedown", this.handleClickOutside);
}


 
  render(){
    const {isNavCollapsed } = this.state;
    const { t, i18n } = this.props;
    const changeLanguage = lng => {          
        i18n.changeLanguage(lng);
      };
    return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light navbar-cosy">
    <Link to="/"><img src={logo} alt='cosy' width="151" height="45" className="logoSidebar"/></Link>
   <div className="flex-container-end">
   <div className={(i18n.language === 'it' ? 'hide-language ' : '')}>
    <button type="button" className="languageMobileShow btn" onClick={() => changeLanguage('it')}>
          <span className="spanLanguage">IT</span> <img src={it} alt='it' width="20" height="20" className="language"/>
        </button>
    </div>
    <div className={(i18n.language === 'en' ? 'hide-language ' : '')}>
    <button type="button"  className="languageMobileShow btn" onClick={() => changeLanguage('en')}>
          <span className="spanLanguage">EN</span><img src={en} alt='en' width="20" height="20" className="language"/>
        </button>
    </div>
    <div className="vl-mobile"></div>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" onClick={this.handleNavCollapse}>
    {!isNavCollapsed &&
      <img src={open} alt="apri" height={24} width={24}></img>
    }
    {isNavCollapsed &&
    <img src={xicon} alt="chiudi" height={24} width={24}></img>
    }
  </button>
  </div>
  <div className="collapse navbar-collapse space">
  </div>
  <div  className={"navbar-collapse " + (isNavCollapsed? "": "collapse")} id="navbarNavDropdown">
 
  <ul className="navbar-nav">
  <hr className="solid100"></hr>
     <li className={"voceMenu "+ (this.state.select===1  ? 'selectecMenu' : '')}>
      <Link to="/destinazioni" onClick={() => this.handleClick(1)}>  {t("destinazioni.label")}
</Link>
    </li>
    <li className={"voceMenu "+ (this.state.select===2  ? 'selectecMenu' : '')}>
    <Link to="/virtualTour" onClick={() => this.handleClick(2)}>{t("virtualtour.label")}</Link>
    </li>
     <li className={"voceMenu "+ (this.state.select===3  ? 'selectecMenu' : '')}> 
     <Link to="/chiSiamo" onClick={() => this.handleClick(3)}>{t("chisiamo.label")}</Link>
    </li>
    <li className={"voceMenu "+ (this.state.select===4  ? 'selectecMenu' : '')}> 
     <Link to="/blog" onClick={() => this.handleClick(4)}>{t("blog.label")}</Link>
    </li>
    <li className={"voceMenu "+ (this.state.select===5  ? 'selectecMenu' : '')}> 
     <Link to="/contatti" onClick={() => this.handleClick(5)}>{t("contatti.label")}</Link>
    </li>
    <div className="vl"></div>
    <hr className="solid"></hr>
    <li className={(i18n.language === 'it'  ? 'hide-language' : '')+ " languageMobile" } > 
    <button type="button" className="btn btn-language" onClick={() => changeLanguage('it')}>
          <span className="spanLanguage">IT</span> <img src={it} alt='it' width="20" height="20" className="language"/>
        </button>
       
    </li>
    <li className={(i18n.language === 'en' ? 'hide-language' : '')+ " languageMobile"}> 
    <button type="button"  className="btn btn-language" onClick={() => changeLanguage('en')}>
          <span className="spanLanguage">EN</span><img src={en} alt='en' width="20" height="20" className="language"/>
        </button>
    </li>
    <li> 
    {this.context.user == null && this.context.user== undefined ? (
     <button type="button" className="btn btn-light btn-cosy-white" onClick={this.showModalChange}>{t('login2')}</button>
    ):(
      <span></span>
    )}
    </li>
    <li> 
    {this.context.user== null && this.context.user== undefined ? (
      <button type="button" className="btn btn-dark btn-cosy" onClick={this.showModalChangeRegistra}>{t('registrati')}</button>
    ):(
      <span></span>
    )}
    {this.context.user != null &&
      <img src={profile} alt='en' width="48" height="48" onClick={this.showLink}/>
    }
    </li>
    </ul>
    {this.state.show &&
        <div className="modallinkHeader" ref={this.wrapperRef}>
        <Row className="paddingBottom16">
          <Col xs={12}>
            <Link to="/profilo/datipersonali" className="headerProfileLink" onClick={() => this.handleClick(9)}>{t("datipersonali")}</Link>
           
            </Col>
        </Row>
        <Row className="paddingBottom16">
            <Col xs={12}>
            <Link to="/profilo/accessibilita" className="headerProfileLink" onClick={() => this.handleClick(9)}>{t("accessibilita.prodilo")}</Link>
           
            </Col>
            </Row>
        <Row  className="paddingBottom16">
            <Col xs={12}>
            <Link to="/profilo/viaggi" className="headerProfileLink" onClick={() => this.handleClick(9)}>{t("viaggi")}</Link>
           
            </Col>
            </Row>
        <Row className="paddingBottom16">
            <Col xs={12}>
            <Link to="/profilo/account" className="headerProfileLink" onClick={() => this.handleClick(9)}>{t("account")}</Link>
           
            </Col>
            
          </Row>
          <hr className="solid-menu"></hr>
          <Row className="paddingBottom16">
          <Link to="#"  className="headerProfileLink" onClick={() => this.handleClick('logout')}>{t("logout")}</Link>
        </Row>
        </div>
        }
       
  </div>
</nav>
{this.state.showModal &&
<ModalLogin show={this.state.showModal} key={this.state.showModal} close={this.closeModalChange} isLogin={this.state.tipoModale}></ModalLogin>  
}
</>
    )
}
}

export default withTranslation()((props) => (
  <CustomHeader
      {...props}
      params={useParams()}
      navigate={useNavigate()}
  />
)); 