import React, { Component } from "react";
import '../../style/destination.css';
import { Col} from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

class TourCard
 extends Component{
    constructor(props){
    super(props);
    }

   render(){
    let link;
    if (this.props.isTour){
      link = '/tour/'+this.props.tour.id; 
    }else{
      link = '/multy/'+this.props.tour.id; 
    }
    const {t,i18n } = this.props;
    return (
        <>
        <Link  to={link}>
      
        <div className="cardTour card" >
            <div className="card-img-top" style={{backgroundImage: `url(${this.props.tour.img_copertina})`,backgroundSize: "cover" }}></div>
            <div className="card-body card-flex" style={{paddingLeft:'0px'}}>
            {this.props.tour.categoria_tour !== undefined &&
              <h5 className="card-title-tours" style={{backgroundColor:`${this.props.tour.categoria_tour.colore}` }}><span>{i18n.language === 'it'? this.props.tour.categoria_tour.categoriaTours : this.props.tour.categoria_tour.categoriaTours_en}</span></h5>
            }
              <p className="card-text-tours">{i18n.language === 'it'? this.props.tour.titolo : this.props.tour.titolo_en}</p>
              <hr className="solid"></hr>
              <div className="d-flex justify-content-between bottomCard">
              <p className="card-text-price">{t("da")} <b>{this.props.tour.costo}€</b>  p.p.</p>
              <p className="card-text-price">{this.props.tour.durata} </p>
              </div>
            </div>
          </div>
          </Link>
       
</>
    )
}
}
export default  withTranslation()(TourCard); 
