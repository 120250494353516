import './App.css';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  
} from "react-router-dom";
import CustomHeader from './components/header/header';
import Home from './components/homePage/home';
import Destinazioni from './components/destinazioni/destinazioni';
import DestinazioneDetail from './components/destinazioni/destinazioneDetail';
import TourDetail from './components/tours/tourDetail';
import MultyDetail from './components/multyDays/multyDetail';
import VirtualTour from './components/virtualTour/virtualTour';
import ChiSiamo from './components/chisiamo/chiSiamo';
import Prenotazione from './components/prenotazione/prenotazione';
import Contatti from './components/contatti/contatti';

import { Component } from 'react';
import { UserContext } from './components/context/UserContext';
import Blog from './components/blog/blog';
import BlogCategory from './components/blog/blogCategory';
import Profilo from './components/profilo/profilo';
import Articolo from './components/blog/articolo';
import DettaglioViaggio from './components/profilo/dettaglioViaggio';
import WizardAccessibilita from './components/accessibilita/wizardAccessibilita';
import ResetPwd from './components/resetPwd/resetPwd';
import ScrollToTop from './hooks/ScrollToTop';
import TagManager from 'react-gtm-module';





export default class App extends Component {
   //This is the method to set the context data.
   setUser = (utente) => {
    this.setState({user: utente });
 /*   var tagManagerArgs = {
      gtmId: 'GTM-000000'
  }
    TagManager.initialize(tagManagerArgs)*/
  };

  state = {
    user: null,
    setUser: this.setUser,
  }
  

  render(){
   
    return(
    <div className="App">
    
          <Router >
          <ScrollToTop />
          <UserContext.Provider value={this.state}>
            <CustomHeader />
               <Routes>
               
               <Route path="/resetPassword"   element={<ResetPwd/>}/> 
                <Route path="/"   element={<Home/>}/> 
                <Route path="/Home"   element={<Home/>}/> 
               <Route path="/contatti"   element={<Contatti />}/>  
                <Route path="/destinazioni"   element={<Destinazioni/>}/> 
                <Route path="/destinazioni/:id"   element={<DestinazioneDetail />}/> 
                <Route path="/tour/:id"   element={<TourDetail />}/>
                <Route path="/multy/:id"   element={<MultyDetail />}/> 
                <Route path="/virtualTour"   element={<VirtualTour />}/> 
                <Route path="/chiSiamo"   element={<ChiSiamo />}/> 
                <Route path="/prenotazione"   element={<Prenotazione />}/>
                <Route path="/blog"   element={<Blog />}/>
                <Route path="/blog/category/:id"   element={<BlogCategory />}/>
                <Route path="/blog/articolo/:id"   element={<Articolo />}/>
                
                <Route path="/prenotazione"   element={<Prenotazione />}/>
                <Route path="/profilo/:tab"  exact element={<Profilo />}/>
                <Route path='/profilo/viaggi/dettaglio' element={<DettaglioViaggio />}/>
                <Route path='/wizardAccessibilita' element={<WizardAccessibilita/>}></Route>
                
                
                
                </Routes>
              </UserContext.Provider>
              
             </Router>
    </div>
      );
    }
  } ;
  
