import React, { Component } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../style/home.css';
import DestinationHome from "./destination-home";
import CosaOffriamoHome from "./cosa-offriamo-home";
import PercheHome from "./perche-home";
import ToursHome from "./tours-home";
import DiconoDiNoi from "./diconoDiNoi";
import NewsletterHome from "./newsletterHome";
import Footer from "../molecule/footer";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Partner from "./partner";
var Carousel = require('react-responsive-carousel').Carousel;

 

class Home extends Component{
  
 
  render(){
        const {t } = this.props;
    return (
        <>
     <Carousel showArrows={true} showThumbs={false} showStatus={false} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} >
                <div>
                <div className="container-home">
                        <div className="centered homeImg">
                        <div className="home-slide-container-title">
                        <div className="title-slider-home">{t('home.titolo1')}</div>
                        <div className="testo-slider-home">{t('home.subtitle1')}</div>
                        <Link to="/destinazioni">
                                <button className="button-slider-home">{t('home.prenota')}</button>
                         </Link>  
                        </div>
                        </div>
                        </div>
                </div>
                <div>
                <div className="container-home">
                        <div className="centered homeImg2">
                        <div className="home-slide-container-title">
                        <div className="title-slider-home"> {t('home.titolo2')}</div>
                        <div className="testo-slider-home"> {t('home.subtitle2')}</div>
                        <Link to="/destinazioni">
                      <button className="button-slider-home">{t('home.prenota')}</button>
                     </Link>  
                        </div>
                        </div>
                        </div>
                </div >
                <div >
                <div className="container-home">
                        <div className="centered homeImg3">
                        <div className="home-slide-container-title">
                        <div className="title-slider-home"> {t('home.titolo3')}</div>
                        <div className="testo-slider-home"> {t('home.subtitle3')}</div>
                      <Link to="/destinazioni">
                      <button className="button-slider-home">{t('home.prenota')}</button>
                     </Link>  
                        </div>
                        </div>
                        </div>
                </div>
            </Carousel>
        
            <DestinationHome></DestinationHome> 
            <CosaOffriamoHome></CosaOffriamoHome>  
            <PercheHome></PercheHome> 
            <ToursHome></ToursHome>
            <DiconoDiNoi></DiconoDiNoi>
            <Partner></Partner>
            <NewsletterHome></NewsletterHome>
            <Footer></Footer>
   
</>

    )
}
}

export default  withTranslation()( Home);