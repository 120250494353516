import React, { Component } from "react";
import '../../style/tour.css';
import { Col, Modal, Row,Button } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import hotel from '../../images/hotelIcon.png'
import arrow from '../../images/arrow.png'


class CardItinerario
 extends Component{
    constructor(props){
    super(props);
    }
    
    
  render(){
    let titolo;
    let testo;
    let hotelName;
    let immagine;
    if (this.props.numero == 1){
      immagine = this.props.tour.img_1;
      hotelName = this.props.tour.hotel_1;
      titolo = this.props.i18n.language === 'it'? this.props.tour.titolo_1 : this.props.tour.titolo_1_en;
      testo = this.props.i18n.language === 'it'? this.props.tour.testo_1 : this.props.tour.testo_1_en;
    }else if (this.props.numero == 2){
        immagine = this.props.tour.img_2;
        hotelName = this.props.tour.hotel_2;
        titolo = this.props.i18n.language === 'it'? this.props.tour.titolo_2 : this.props.tour.titolo_2_en;
        testo = this.props.i18n.language === 'it'? this.props.tour.testo_2 : this.props.tour.testo_2_en;
    }else if (this.props.numero == 3){
        immagine = this.props.tour.img_3;
        hotelName = this.props.tour.hotel_3;
        titolo = this.props.i18n.language === 'it'? this.props.tour.titolo_3 : this.props.tour.titolo_3_en;
        testo = this.props.i18n.language === 'it'? this.props.tour.testo_3 : this.props.tour.testo_3_en;
    }else if (this.props.numero == 4){
        immagine = this.props.tour.img_4;
        hotelName = this.props.tour.hotel_4;
        titolo = this.props.i18n.language === 'it'? this.props.tour.titolo_4 : this.props.tour.titolo_4_en;
        testo = this.props.i18n.language === 'it'? this.props.tour.testo_4 : this.props.tour.testo_4_en;
    }else if (this.props.numero == 5){
        immagine = this.props.tour.img_5;
        hotelName = this.props.tour.hotel_5;
        titolo = this.props.i18n.language === 'it'? this.props.tour.titolo_5 : this.props.tour.titolo_5_en;
        testo = this.props.i18n.language === 'it'? this.props.tour.testo_5 : this.props.tour.testo_5_en;
    }else if (this.props.numero == 6){
        immagine = this.props.tour.img_6;
        hotelName = this.props.tour.hotel_6;
        titolo = this.props.i18n.language === 'it'? this.props.tour.titolo_6 : this.props.tour.titolo_6_en;
        testo = this.props.i18n.language === 'it'? this.props.tour.testo_6 : this.props.tour.testo_6_en;
    }else if (this.props.numero == 7){
        immagine = this.props.tour.img_7;
        hotelName = this.props.tour.hotel_7;
        titolo = this.props.i18n.language === 'it'? this.props.tour.titolo_7 : this.props.tour.titolo_7_en;
        testo = this.props.i18n.language === 'it'? this.props.tour.testo_7 : this.props.tour.testo_7_en;
    }
    
    const {t,i18n } = this.props;
    return (
        <>
        <div className="cardItinerario">
                <Row>
                    <Col xs={4}><img src={immagine} className="imgItinerario"></img></Col>
                    <Col xs={8} className="itinerarioMulty">
                    <div>
                    <div className="titleItinerario" style={{marginTop:"10px !important"}}>{titolo}</div>
                    <div className="subtitleAccessibilit">{testo}</div>
                    </div>
                    <div className="imgHotel"><img src={hotel} width={32} height={32} ></img><span className="hotelName">{t('pernottamento')}</span></div>
                  
                    </Col>
                    </Row>
                </div>
                <hr className="solid"></hr>
        </>
    )
}
}
export default  withTranslation()(CardItinerario); 
