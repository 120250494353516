import React, { Component, useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import '../../style/prenotazione.css';
import { UserContext } from "../context/UserContext";
import Primostep from "./primostep";
import SecondoStep from "./secondoStep";
import TerzoStep from "./terzoStep";

const FirstComponent = () => {
  var u = JSON.parse(sessionStorage.getItem('user'));
  return <Primostep user={u}  ></Primostep>
}
const secondComponent = () => {
  return <SecondoStep></SecondoStep>
}
const thirdComponent = () => {
  return <TerzoStep></TerzoStep>
}



class Prenotazione
 extends Component{
  constructor(props){
    super(props);
    
  this.state = {
    steps:[
      { key: 'firstStep', label: 'Inizia prenotazione', isDone: true, component: FirstComponent() },
      { key: 'secondStep', label: 'Riepilogo', isDone: false, component: secondComponent() },
      { key: 'thirdStep', label: 'Conferma', isDone: false, component: thirdComponent() },
    ],
    activeStep:{}
}

  }



   handleNext = () => {
    if (this.state.steps[this.state.steps.length - 1].key === this.state.activeStep.key) {
      alert('You have completed all steps.');
      return;
    }

    const index = this.state.steps.findIndex(x => x.key === this.state.activeStep.key);
    let items = [...this.state.steps];
    items.map((x,j) => {
      if (x.key === this.state.activeStep.key) x.isDone = true;
      return x;
    });
    this.setState({activeStep: this.state.steps[index + 1]});
  }

   handleStep = (i) =>{
    console.log(i);
    const index = this.state.steps.findIndex(x => x.key === this.state.activeStep.key);
    if (index > i){
    let items = [...this.state.steps];
    items.map((x,j) => {
      if (j <= i ){
        x.isDone = true;
      }else{
        x.isDone = false;
      }
       return x;
     })
     this.setState({steps:items});
     this.setState({activeStep: this.state.steps[i]});
    }
  }
  componentDidMount = ()=>{
    var u = JSON.parse(sessionStorage.getItem('user'));
    var prenotazione = JSON.parse(sessionStorage.getItem('prenotazione'));
    if (prenotazione == null || u == null || u == undefined){
      this.props.navigate('/');
      this.props.navigate(0)
    }
   }


  componentWillMount = ()=>{
    this.setState({activeStep: this.state.steps[0]});
  }

 
  
  render(){
    var u = JSON.parse(sessionStorage.getItem('user'));
  return (
    <div className="wizardPrenotazione prenot marginBottom160">
      <div className="box-carousel">
        <div className="steps">
          <ul className="nav">
            {this.state.steps.map((step, i) => {
              return <Col key={i} xs ={4}>
              <li  >
                <div><span className="wizardLabel" onClick={()=>this.handleStep(i)}>{step.label}</span></div>
                <div className={'vsolid ' + (step.isDone ? 'done ' : '') + (this.state.activeStep.key === step.key  ? 'done ' : '')} ></div>
               
              </li>
              </Col>
            })}
          </ul>
        </div>
        <div className="step-component">
          {this.state.activeStep.key == 'firstStep' &&
           <Primostep user={u}  funzione={this.handleNext}></Primostep>
          }
          {this.state.activeStep.key == 'secondStep' &&
           <SecondoStep user={u} funzione={this.handleNext}></SecondoStep>
          }
          {this.state.activeStep.key == 'thirdStep' &&
           <TerzoStep user={u} ></TerzoStep>
          }
        </div>
      </div>
    </div>
  );
}
}

export default  withTranslation()((props) => (
  <Prenotazione
      {...props}
      navigate={useNavigate()}
      location={useLocation()}
  />
)); 