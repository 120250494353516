import React, { Component } from "react";
import * as Constanti from '../../Utils/constants.js';
import '../../style/virtual.css';
import { Col, Row } from "react-bootstrap";
import Footer from "../molecule/footer";
import { withTranslation } from "react-i18next";
import chiSiamoImg from "../../images/chiSiamo1.png";
import chiSiamoImg2 from "../../images/chisiamo2.jpg";
import chiSiamoImg3 from "../../images/chisiamo3.jpg";
import chiSiamoImg4 from "../../images/chisiamo4.jpg";
import chiSiamoImg5 from "../../images/chisiamo5.jpg";
import chiSiamoImg6 from "../../images/chisiamo6.jpg";
import chiSiamoImg7 from "../../images/chisiamo7.jpg";
import shapeImg from "../../images/shape-orange.svg";
import shapePImg from "../../images/shape-purple.svg";
import inclusivita from "../../images/inclusivita.png";
import accessibilita from "../../images/accessibilita.png";
import sicurezza from "../../images/sicurezza.png";
import affidabilita from "../../images/affidabilità.png";
import passione from "../../images/passione.png";
import collaborazione from "../../images/collaborazione.png";

import ReactMarkdown from "react-markdown";
import axios from "axios";
import ModalChiSiamo from "./modalChiSiamo.js";




class ChiSiamo
 extends Component{
    constructor(props){
     super(props);

     this.state = {
      member: null,
      showModalChiSiamo:false,
      memberSelected:null,

     }

     this.showModalChiSiamo = this.showModalChiSiamo.bind(this);
    }

    showModalChiSiamo(member){
        this.setState({memberSelected: member, showModalChiSiamo:true});
    }

    closeModalChisiamo = () => {
      this.setState({showModalChiSiamo : false });
    }

    componentDidMount() {

        var apiBaseUrl = Constanti.url_Backend+"getMemberTeams";
        var self = this;
        axios.get(apiBaseUrl).then(function (response) {
        if (response.data.data != null){
           var member = response.data.data;
           self.setState({ member : member })
        }else{
          console.log("non ci sono membri del team");
        }

      })
      .catch(function (error) {
      console.log(error);
      });

    }
  
  render(){
 const {t,i18n} = this.props;
 const {member} = this.state;
   
   
    return (
        <>
    <div className="chiSiamoHead">
    <p className="text-center titlePage">{t('chisiamo.label')}</p>
    <p className="text-center titleBig"><span className="chiSiamoText" >{t('chisiamoTitolo')}</span></p>
    <p className="text-center subtitleBig"><span className="chiSiamoText" >{t('chisiamoSubTitle')}</span></p>
    </div>
    <div className="imgChisiamo">
    <img src={chiSiamoImg} className="imgClass"></img>
    <div className="flex-content-between marginTop50 marginBottom50 gap">
        <div className="content-box-chiSiamo" >
        <div className="titoloChiSiamoBox">{t('titolo1chiSiamo')}</div>
        <div className="subtitoloChiSiamoBox">{t('subtitolo1chiSiamo')}</div>
        </div>
        <div className="content-box-chiSiamo">
        <div className="titoloChiSiamoBox">{t('titolo2chiSiamo')}</div>
        <div className="subtitoloChiSiamoBox">{t('subtitolo2chiSiamo')}</div>
        </div>
        <div className="content-box-chiSiamo">
        <div className="titoloChiSiamoBox">{t('titolo3chiSiamo')}</div>
        <div className="subtitoloChiSiamoBox">{t('subtitolo3chiSiamo')}</div>
        </div>
    </div>
    <hr className="solid"></hr>
    </div>
    <div className="section section-chiSiamo" style={{paddingBottom:"130px"}}>
    <p className="titleSection marginTop50">{t('valori')}</p>
    <p className="titleSectionBig"><span className="chiSiamoTextBig" >{t('paragrafo6sezioni')}</span></p> 
    </div>
  <div className="section-blue">
    <div className="container-cardChiSiamo">
      <div className="grid">
        <div className="box">
          <img src={inclusivita} height={35}></img>
          <div className="marginTop20 title">{t('Inclusività')}</div>
          <div className="subtitle marginTop20">{t('inclusivita.testo')}</div>
        </div>
        <div  className="box"> <img src={accessibilita} height={35}></img>
          <div className="marginTop20 title">{t('Accessibilità')}</div>
          <div className="subtitle marginTop20">{t('accessibilita.testo')}</div>
        </div>
        <div className="box"> <img src={affidabilita} height={35}></img>
          <div className="marginTop20 title">{t('Affidabilità')}</div>
          <div className="subtitle marginTop20">{t('affidabilita.testo')}</div>
        </div>
        <div className="box"> <img src={passione} height={35}></img>
          <div className="marginTop20 title">{t('Passione')}</div>
          <div className="subtitle marginTop20">{t('passione.testo')}</div>
        </div>
      </div>
    </div>
  </div>
  <div className="section2 marginTopBottom">
    <div className="container-cardChiSiamo">
      <div className="content_wrapper flex">
        <div className="flex-child-half">
          <div className="label">{t('mission')}</div>
          <h3 className="h3">{t('turismopertutti')}</h3>
          <div className="p w-richtext">
            <p><ReactMarkdown>{t('paragrafo1')}</ReactMarkdown></p>
            <p><ReactMarkdown>{t('paragrafo2')}</ReactMarkdown></p>
            <p><ReactMarkdown>{t('paragrafo3')}</ReactMarkdown></p>
          </div>
        </div>
        <div className="flex-child-half right">
          <div className="half-column">
           <img src={chiSiamoImg2} loading="lazy" alt="" className="img-block"></img>
           <img src={chiSiamoImg4} loading="lazy" alt="" className="img-block _3"></img>
          </div>
          <div className="half-column right">
          <img src={chiSiamoImg3} loading="lazy" alt="" className="img-block _3"></img>
          </div><img src={shapeImg} loading="lazy" alt="" className="shape-orange"></img>
        </div>
      </div>
    </div>
  </div>
  <hr className="solid marginTopBottomAuto"></hr>
  <div className="section sectionChiSiamo2">
  <p className="text-center titleBig frase" >{t('chisiamofrase1')}<span style={{color:"#FFAA4D",fontStyle: "italic"}}>{t('cosy')}</span>{t('chisiamofrase2')}</p>
    <p className="text-center titleBig"></p>
    <p className="text-center subtitleBig">Ileana - CEO di CosyforYou</p>
  </div>
  <hr className="solid marginTopBottomAuto"></hr>
  <div className="section3">
    <div className="container-cardChiSiamo">
      <div className="content_wrapper flex">
        <div className="flex-child-half right">
          <div className="half-column right">
           <img src={chiSiamoImg6} loading="lazy" alt="" className="img-block _3"></img>
          </div>
          <div className="half-column">
          <img src={chiSiamoImg5} loading="lazy" alt="" className="img-block _3"></img>
          <img src={chiSiamoImg7} loading="lazy" alt="" className="img-block _3"></img>
          </div><img src={shapePImg} loading="lazy" alt="" className="shape-orange purple"></img>
        </div>
        <div className="flex-child-half">
          <div className="label">{t('storia')}</div>
          <h3 className="h3">{t('nascitaCosy')}</h3>
          <div className="p w-richtext">
            <p><ReactMarkdown>{t('storia.paragrafo')}</ReactMarkdown></p>
            <p><ReactMarkdown>{t('storia.paragrafo2')}</ReactMarkdown></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="memberTeams">
  <div className="chiSiamoHead">
    <p className="text-center titlePage">{t('il.nostro.team')}</p>
    <p className="text-center titleBig"><span className="chiSiamoText" >{t('team.frase')}</span></p>
    <p className="text-center titleBig"><span className="chiSiamoText" >{t('team.frase1')}</span></p>
 </div>
 <div className="container-cardChiSiamo">
 <div className="flex-content-between">
    {this.state.member?.map((membro) => 
      <div className="content-box-chiSiamo" >
        <div className="cardMember" style={{padding:'15px'}}>
        <div className="text-center marginMember">
          <img src={membro.thumbnail} loading="lazy" alt="" className="imgMembro"></img>
        </div>
        <div className="titleCardMember marginMember">{membro.denominazione}</div>
        <div className="roleCardMember text-center marginMember">{i18n.language === 'it'? membro.ruolo : membro.ruolo_en }</div>
        <div className="text-center marginMember">
        <button className="buttonCardMember" onClick={() => this.showModalChiSiamo(membro)}>{t("scopri.label")}</button>
        </div>
        
        </div>
      </div>      
          )}
    </div>
    {this.state.showModalChiSiamo &&
        <ModalChiSiamo show={this.state.showModalChiSiamo} key={this.state.memberSelected} membro={this.state.memberSelected} close={this.closeModalChisiamo}></ModalChiSiamo>  
        }
    </div>
  </div>
   <Footer></Footer>
    </>
    )
}
}
export default withTranslation()(ChiSiamo); 
