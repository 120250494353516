import { Component } from "react";
import { withTranslation } from "react-i18next";
import '../../style/profilo.css';
import { useLocation, useParams } from "react-router";
import 'react-tabs/style/react-tabs.css';
import { Col, Row } from "react-bootstrap";
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";
import subok from "../../images/Vectorsubmissionok.png";




class Accessibilita extends Component{
  constructor(props){
  super(props);
  this.state={
    nome:'',
    cognome:'',
    citta:'',
    numTel:'',
    pref:'',
    required:false,
    isReadOnly:true,
    submitted:false,
    haveError:false,
  }
  this.handleModify = this.handleModify.bind(this);
  this.hanldeSave = this.hanldeSave.bind(this);
  this.handleChange = this.handleChange.bind(this);
  }
 
  handleModify(){
    this.setState({isReadOnly: false});
  }

  hanldeSave(){
    if (this.state.nome !=='' && this.state.cognome !=='' && this.state.citta !=='' &&  this.state.pref !=='' && this.state.numTel !=='' 
    && this.state.nome !== undefined && this.state.cognome !== undefined && this.state.citta !== undefined && this.state.pref !== undefined && this.state.numTel !== undefined){
      this.setState({submitted:true,required: false});

      var apiBaseUrl = Constanti.url_Backend+"modificaDatiPersonali";
      var payload= {
          "id": this.props.user.id,
          "name" : this.state.nome,
          "surname": this.state.cognome,
          "telephone" : this.state.numTel,
          "city": this.state.citta,
          "pref" : this.state.pref
      }
      console.log(payload);
      var self = this;
      axios.post(apiBaseUrl, payload).then(function (response) {
       
        var userLogin= response.data.data;
        if (userLogin !== 'KO'){
        sessionStorage.setItem('user',JSON.stringify(userLogin));
        self.setState({isReadOnly: true,haveError: false});
        }else{
          self.setState({haveError: true});
          
        }
      });
   
  }else{
    this.setState({required: true});

  }
}

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name] : value });
   
}

  componentDidMount = () =>  {
   
   if (this.props.user != null){
    this.setState({
      nome:this.props.user.name,
    cognome:this.props.user.surname,
    citta:this.props.user.city,
    numTel:this.props.user.telephone,
    pref:this.props.user.pref,
    })
   }
  }

  render(){ 
    const {nome,cognome,citta,numTel,isReadOnly,submitted,haveError,pref,required} = this.state;
    const {t,i18n} = this.props;
    return (
      <>
                <div data-w-tab="Dati personali" className="w-tab-pane">
                  <h2 className="h2 nomargintop">Dati Personali</h2>
                  {isReadOnly &&
                  <a href="#" className="button-tertiary w-button" onClick={this.handleModify}>{t('modifica')}</a>
                  }
                  <div className="form-area-personale w-form">

                   
                      {!isReadOnly &&
                   <>
                      <div className="input-field-wrapper"><label className="field-label">{t('nome')}</label><input type="text" className="inputfield-plain w-input modificaInput" name="nome" placeholder={nome} id="name" value={nome} onChange={this.handleChange}/></div>
                      <div className="input-field-wrapper"><label className="field-label">{t('cognome')}</label><input type="text" className="inputfield-plain w-input modificaInput" name="cognome" value={cognome} placeholder={cognome} id="Cognome" onChange={this.handleChange}/></div>
                      <div className="input-field-wrapper"><label className="field-label">{t('city')}</label><input type="text" className="inputfield-plain w-input modificaInput" name="citta" value={citta}  placeholder={citta!= null && citta != "" ? citta : "-" } id="Citt" onChange={this.handleChange}/></div>
                      <Row>
                     <Col xs={6}>
                      <label className="labelCarouselPrenotazioni padding0">{t("prefisso")}</label>
                      <input type="number" onChange={this.handleChange} required="true" className="inputfield-plain w-input modificaInput" placeholder="+39"  name="pref" value={pref}></input>
                      </Col>
                      <Col xs={6}>
                      <label className="labelCarouselPrenotazioni padding0">{t("telefono")}</label>
                      <input type="number" onChange={this.handleChange}  required="true" className="inputfield-plain w-input modificaInput" placeholder={t("telefono")} pattern="[0-9]" name="numTel" value={numTel}></input>
                      </Col>
                      </Row>
                      </>
                      }
                      {isReadOnly &&
                      <>
                      <div className="input-field-wrapper"><label className="field-label">{t('nome')}</label><input type="text" className="inputfield-plain w-input input-read-only" name="nome" placeholder={nome} id="name" value={nome} readOnly={true} onChange={this.handleChange}/></div>
                      <div className="input-field-wrapper"><label className="field-label">{t('cognome')}</label><input type="text" className="inputfield-plain w-input input-read-only" name="cognome" value={cognome} placeholder={cognome} readOnly={true} id="Cognome" onChange={this.handleChange}/></div>
                      <div className="input-field-wrapper"><label className="field-label">{t('city')}</label><input type="text" className="inputfield-plain w-input input-read-only" name="citta" value={citta}  placeholder={citta!= null && citta != "" ? citta : "-"}  readOnly={true} id="Citt" onChange={this.handleChange}/></div>
                      <div className="input-field-wrapper"><label className="field-label">{t('telefono')}</label><input type="tel" className="inputfield-plain w-input input-read-only" name="numTel" placeholder={numTel!= null && numTel != "" ? numTel : "-"} id="telefono" readOnly={true} onChange={this.handleChange}/></div>
                     </>
                      }
                    {!isReadOnly &&
                    <button type="button" className="buttonProfile" onClick={this.hanldeSave}>Salva</button>
                    }
                    {required &&
                      <div className="w-form-done">
                      <div>{t('required.submit')}</div>
                    </div>
                    }
                    {submitted && !haveError &&
                    <div className="w-form-done">
                      <div className="submissionOK">
                      <span>{t('submission.ok')}</span>
                      <img src={subok}></img>
                    </div>
                    </div>
                    }
                    {submitted && haveError &&
                    <div className="w-form-fail">
                      <div>{t('submission.ko')}</div>
                    </div>
                    }
                  </div>
                </div>
        </>
  );
}
}
export default  withTranslation()(Accessibilita); 
