import React, { Component } from "react";
import '../../style/home.css';
import { withTranslation } from 'react-i18next';
import Slider from "react-slick";
import arrPrev from '../../images/backArrow.png';
import nextArrow from '../../images/nextArrow.png';
import apici from '../../images/apici.png';

class DiconoDiNoi extends Component{
  constructor(props){
    
    super(props);
    this.state = {
      tours: [],
      }
      this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
}

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render(){
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      variableWidth: true, 
    };
    const { t,i18n } = this.props;
    return (
      <div className="container-Tours marginTop">
       <div className="d-flex justify-content-between">
       <span className="titleTours">{t("dicono.label")}</span>
      <div className="divButton">
      <button className="buttonSlider" onClick={this.previous}>
          <img className="prev-slick-arrow" alt="" height={16}  width={16} src={arrPrev}/>
          </button>
          <button className="buttonSlider" onClick={this.next}>
          <img className="next-slick-arrow" alt="" src={nextArrow}  height={16}  width={16} />
          </button>
          </div>
      </div>
    
      <div className="container-home">
       
    <Slider ref={c => (this.slider = c)} {...settings}>
       <div className="paddingRight5" >
       <div className="card dicono">
            <div className="card-body">
            <img className="card-img-top-dicono" alt="" src={apici}/>
              <h5 className="dicono-title">{t("dicono.titolo.1")}</h5>
              <p className="card-text-dicono">{t("dicono.testo.1")}</p>
              <p className="card-text-nome">Lisa</p>
            </div>
          </div>
        </div>
        <div className="paddingRight5" >
        <div className="card dicono">
            <div className="card-body">
            <img className="card-img-top-dicono" alt="" src={apici}/>
              <h5 className="dicono-title">{t("dicono.titolo.2")}</h5>
              <p className="card-text-dicono">{t("dicono.testo.2")}</p>
              <p className="card-text-nome">Orietta Yz Ianiri</p>
            </div>
          </div>
        </div>
        <div className="paddingRight5" >
        <div className="card dicono">
            <div className="card-body">
            <img className="card-img-top-dicono" alt="" src={apici}/>
            <h5 className="dicono-title">{t("dicono.titolo.3")}</h5>
              <p className="card-text-dicono">{t("dicono.testo.3")}</p>
              <p className="card-text-nome">Lydia Grabowski</p>
            </div>
          </div>
        </div>
        <div className="paddingRight5" >
        <div className="card dicono">
            <div className="card-body">
            <img className="card-img-top-dicono" alt="" src={apici}/>
            <h5 className="dicono-title">{t("dicono.titolo.4")}</h5>
              <p className="card-text-dicono">{t("dicono.testo.4")}</p>
              <p className="card-text-nome">Germano e Ornella Bosato</p>
            </div>
          </div>
        </div>
        <div className="paddingRight5" >
        <div className="card dicono">
            <div className="card-body">
            <img className="card-img-top-dicono" alt="" src={apici}/>
            <h5 className="dicono-title">{t("dicono.titolo.5")}</h5>
              <p className="card-text-dicono">{t("dicono.testo.5")}</p>
              <p className="card-text-nome">Emanuela Bristot</p>
            </div>
          </div>
        </div>
        <div className="paddingRight5" >
        <div className="card dicono">
            <div className="card-body">
            <img className="card-img-top-dicono" alt="" src={apici}/>
            <h5 className="dicono-title">{t("dicono.titolo.6")}</h5>
              <p className="card-text-dicono">{t("dicono.testo.6")}</p>
              <p className="card-text-nome">Sien L.</p>
            </div>
          </div>
        </div>
      </Slider>
      </div>
    </div>
    )
}
}
export default withTranslation()(DiconoDiNoi); 





