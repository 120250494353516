import React, { Component } from "react";
import '../../style/home.css';
import { Col, Row } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import * as Constanti from '../../Utils/constants.js';
import axios from "axios";


class NewsletterHome extends Component{
  constructor(props){
    super(props);
  
    this.state = {
      email:"",
      showError:false,
      showOK:false
      
};
    this.handleChange = this.handleChange.bind(this);
    this.sendNewsletter = this.sendNewsletter.bind(this);
    
    
  };

  sendNewsletter(){
    if (this.state.email !== ""){
      var apiBaseUrl = Constanti.url_Backend+"newsLetterSite";
      var payload= {
          "email": this.state.email
      }
      var self = this;
      axios.post(apiBaseUrl, payload).then(function (response) {
        var result= response.data.data;
        if (result === 'KO'){
          self.setState({showError:true});
          self.setState({showOK:false});
          }else{
            self.setState({showOK:true});
            self.setState({showError:false});
          }
       
       });
    }
  }

   
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name] : value });
   
}


  render(){
    const { t} = this.props;
    const {email,showError,showOK} = this.state;
    return (
        <>
        
         <div className="percheHome">
         <Row>
         <Col className="col-lg-12">
         <div className="container-home">
         <div className="centered img-newsletter">
          <div className="text-newsletter">{t('newsletterTitle')}</div>
          <div className="subtitle-newsletter">{t('newsletterSubTitle')}</div>
          <div className="inputWrapp">
          <input className="form-control-detail" style={{width:'320px',height:'48px'}} placeholder={t('scriviEmail')} type="text" name="email" value={email}  onChange={this.handleChange}/>
          <button type="submit" className="btn-block btn-newsletter" onClick = {this.sendNewsletter}>{t('iscriviti')}</button>
         </div>
          {showError &&
            <div className="subtitle-newsletter">{t('newsletterErrorSend')}</div>
          }
          {showOK  &&
            <div className="subtitle-newsletter">{t('newsletterOKSend')}</div>
          }
         </div>
         </div>
         </Col>
        </Row>
        </div>
       
        
</>
    )
}
}
export default withTranslation()(NewsletterHome); 
